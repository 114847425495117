import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    estimateData:[],
    isEstimating:false,
    status:null,
}

const token = userObj?.token

export const estimatedPrice = createAsyncThunk(
    'order/estimatedPrice',
    async (data, {rejectWithValue}) => {
        console.log(data)
        try{
            const response = await axios.post("Order/estimatePrice",data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const estimatedPriceSlice = createSlice({
    name: 'estimatedPrice',
    initialState,
    reducers: {},
    extraReducers:{
        [estimatedPrice.pending]: (state, action) => {
            state.status='pending';
            state.isEstimating=true;
        },
        [estimatedPrice.fulfilled]: (state, action) => {
            state.status='success';
            state.isEstimating=false;
            state.estimateData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [estimatedPrice.rejected]: (state, action) => {
            state.status='failed';
            state.isEstimating=false;
        }
    }
})

export default estimatedPriceSlice.reducer;