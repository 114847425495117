import React, { useDebugValue } from "react";
import { AccountBalanceOutlined, Edit, InfoOutlined, Password } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ProfileLinks, ProfileLinksList } from "./profilesidebar.style";
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from "react-redux";
import { logOut } from "../../app/features/logoutSlice";
import { userObj } from "../../helper/AuthRoutes";

const ProfileSideBar = ({activeStyle}) => {

  
  const dispatch = useDispatch();
const userRole = userObj?.role[0]
    const navigate = useNavigate();

    const removeToken =()=>{
      dispatch(logOut())
    }

    const handleLogout = () => { 
      removeToken()
      window.location.reload()
      // {
      //   !userObj?.token && navigate('/login')
      // }
    }
    return (
        <ProfileLinks>
            <ProfileLinksList>
              <li className={activeStyle}>
                <Edit style={{ color: "#b23934" }} />{" "}
                <Link to="/dashboard/edit-profile">Edit Profile</Link>
              </li>
              {
                userRole.toLowerCase() === 'partner' &&
                <li className={activeStyle}>
                <AccountBalanceOutlined style={{ color: "#b23934" }} />{" "}
                <Link to="/dashboard/edit-bank-information">Change Bank Information</Link>
              </li>
              }
             
              <li className={activeStyle}>
                <Password style={{ color: "#b23934" }} />{" "}
                <Link to="/dashboard/change-password">Change Password</Link>
              </li>
              {
                userRole.toLowerCase() === 'customer' &&
              <li className={activeStyle}>
                <InfoOutlined style={{ color: "#b23934" }} />{" "}
                <Link to="/dashboard/user-api-keys">API Keys</Link>
              </li>
              }
              <li>
                <LogoutIcon style={{ color: "#b23934" }} />{" "}
                <Button onClick={handleLogout} color="error">Logout</Button>
              </li>
            </ProfileLinksList>
          </ProfileLinks>

    )
}

export default ProfileSideBar;