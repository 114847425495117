import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';

const initialState = {
    data:[],
    isEditing:null,
    status:null,
    error:null
}

const token = userObj?.token


// headers: {
//     Authorization: 'Bearer ' + varToken
//   }

export const editVehicle = createAsyncThunk(
    'partner/editVehicle',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.put(`${baseUrl}/Partner/updatevehicle`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const editVehicleSlice = createSlice({
    name: 'editVehicle',
    initialState,
    reducers: {},
    extraReducers:{
        [editVehicle.pending]: (state, action) => {
            state.status='pending';
            state.isEditing=true;
        },
        [editVehicle.fulfilled]: (state, action) => {
            state.status='success';
            state.isEditing=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [editVehicle.rejected]: (state, action) => {
            state.status='failed';
            state.isEditing=false;
            state.error = action.error
        }
    }
})

export default editVehicleSlice.reducer;