import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';

const initialState = {
    data:[],
    isAddingRider:null,
    status:null,
    error:null
}

const token = userObj?.token


// headers: {
//     Authorization: 'Bearer ' + varToken
//   }

export const addRider = createAsyncThunk(
    'partner/addRider',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.post(`${baseUrl}/Account/addRider`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            // console.log(err.response.data.title)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const addRiderSlice = createSlice({
    name: 'addRider',
    initialState,
    reducers: {},
    extraReducers:{
        [addRider.pending]: (state, action) => {
            state.status='pending';
            state.isAddingRider=true;
        },
        [addRider.fulfilled]: (state, action) => {
            state.status='success';
            state.isAddingRider=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [addRider.rejected]: (state, action) => {
            state.status='failed';
            state.isAddingRider=false;
            state.error = action.error
        }
    }
})

export default addRiderSlice.reducer;