import React from "react";
import Chart from "react-apexcharts";

class ListOfOrdersChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Confirmed Orders",
          data: [30, 40, 35, 50, 49, 60, 70],
          
        },
        {
            name: "Unconfirmed Orders",
            data: [10, 20, 13, 2, 10, 5, 7],
          },
      ],
    };
  }


  render() {
    return (
      <div id="chart">
        <Chart
          options={{
            chart: {
              id: "List of daily orders",
            },
            colors: ["#000000", "grey"],
            xaxis: {
              categories: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
              },
            },
          }}
          series={this.state.series}
          type="bar"
          height={270}
        />
      </div>
    );
  }
}

export default ListOfOrdersChart;
