import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../app/features/Customer/Order/getOrderSlice";
import logo from "../../../assets/images/rse-logo.png";
import { Waybill } from "./waybill.styled";
import Barcode from "react-barcode";

const ScheduledWaybill = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [data, setData] = React.useState({});

  useEffect(() => {
    dispatch(getOrder(id)).then((res) => {
      const { error, payload } = res;
      if (!error) {
        setData(payload);
      }
    });
  }, []);

  const paymentDefinition = (type) => {
    switch (type) {
      case 0:
        return "Prepaid";
      case 1:
        return "POD";
      default:
        return "Prepaid";
    }
  };

  return (
    <>
      <Waybill className="container">
        <div className="head mb-3">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center py-0 px-2">
              <img
                src={logo}
                alt="RSE-Logo"
                style={{ width: "350px", height: "65px" }}
              />
            </div>
            <div className="col-md-6 py-0 px-2">
              <div className="float-end">
                {data && (
                  <Barcode
                    height={65}
                    value={data.orderNo}
                    options={{ format: "CODEE39", width: 1, height: 2 }}
                    renderer="img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sub-head text-center">
          <h5 className="" style={{ fontSize: "20px", fontWeight: 700 }}>
            DOMESTIC AIRWAY BILL
          </h5>
          <h6 className="" style={{ fontSize: "18px", fontWeight: 700 }}>
            NON-NEGOTIABLE
          </h6>
        </div>
        {data && (
          <div className="body">
            <div className="border border-2 m-2 py-3 px-3">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <fieldset>
                    <legend style={{ fontSize: "18px", fontWeight: 700 }}>
                      Sender Details
                    </legend>
                    <div className="mx-5">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Sender Name:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.customer?.firstName} {data.customer?.lastName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Address:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.customer?.address}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Phone Number:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.customer?.phoneNumber}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Pickup:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.pickup}
                        </span>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-6">
                  <fieldset>
                    <legend style={{ fontSize: "18px", fontWeight: 700 }}>
                      Recipient Details
                    </legend>
                    <div className="mx-5">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Recipient Name:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.recipient.recipientName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Address:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.recipient.streetAddress}
                          {", "}
                          {data.shipment?.recipient.state}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Phone Number:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.recipient.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-12 mt-4">
                  <legend style={{ fontSize: "18px", fontWeight: 700 }}>
                    Shipment Details
                  </legend>
                  <div className="row">
                    <div className="col-lg-3 col-sm-6">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Content:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.contentDescription}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Quantity:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.unit}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Weight:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {data.shipment?.weight + "kg"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          Payment Type:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {paymentDefinition(data.paymentType)}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6">
                      <div className="d-flex align-items-center mb-2">
                        <span
                          className=""
                          style={{ fontSize: "15px", fontWeight: 600 }}
                        >
                          C.O.D:
                        </span>
                        <span
                          className="mx-3"
                          style={{ fontSize: "14px", fontWeight: 500 }}
                        >
                          {"₦"}
                          {dollarUSLocale.format(data.shipment?.shipmentPayment?.cashonDelivery.toFixed(2))}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <div className="col-8 col-md-4 col-lg-3">
                  <div className="d-flex align-items-center mb-2">
                    <span
                      className="col-4 text-end"
                      style={{ fontSize: "18px", fontWeight: 600 }}
                    >
                      Amount:
                    </span>
                    {data.orderPrice && (
                      <span
                        className="mx-3 col-8"
                        style={{ fontSize: "17px", fontWeight: 500 }}
                      >
                        {"₦"}
                        {dollarUSLocale.format(
                          data.orderPrice?.maxValue.toFixed(2)
                        )}
                      </span>
                    )}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <span
                      className="col-4 text-end"
                      style={{ fontSize: "18px", fontWeight: 600 }}
                    >
                      VAT:
                    </span>
                    {data.orderPrice && (
                      <span
                        className="mx-3 col-8"
                        style={{ fontSize: "17px", fontWeight: 500 }}
                      >
                        {"₦"}
                        {dollarUSLocale.format(data.orderPrice?.tax.toFixed(2))}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Waybill>
    </>
  );
};

export default ScheduledWaybill;
