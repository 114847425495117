import axios from "../../../../../api/axios"
import { userObj } from "../../../../../helper/protectedRoutes";
import {
  vehicleConstants
} from "../../../constants"

const token = userObj?.token

export const vehicleDelete = (id) => {
    return async (dispatch) => {
        dispatch({ type: vehicleConstants.DELETE_VEHICLE_REQUEST });
        const res = await axios.delete(
            `/Partner/deleteVehicle/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
        );
        console.log("res", res);

        if (res.status === 200) {
            // const { data } = res;
            dispatch({
                type: vehicleConstants.DELETE_VEHICLE_SUCCESS,
            });
        }
    };
};