import React from "react";
import Chart from "react-apexcharts";

export default class DriverTotalEarnedChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
       
      },
      series: [
        {
          name: "Number of Trips",
          data: [15, 20, 27, 30, 40, 42, 21],
          
        },
      ],
    };
  }
 
  render() {
    return (
      <div id="chart">
        <Chart
          options={
            {
              chart: {
                id: "List of drivers trip numbers",
              },
              colors: ["#000000", "grey"],
              xaxis: {
                categories: [
                    ["John", "Doe"],
                    
                    ["Jake", "Williams"],
                    "Amber",
                    ["Peter", "Brown"],
                    ["Mary", "Evans"],
                    ["David", "Wilson"],
                    ["Lily", "Roberts"],
                  ],
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  columnWidth: "45%",
                  borderRadius: 4,
                },
              },
            }
          }
          series={this.state.series}
          type="bar"
          height={270}
        />
      </div>
    );
  }
}
