import React from "react";
import Chart from "react-apexcharts";

export default class BalanceChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        options:{
            chartOptions: {
                // labels: ['Paid Balance', 'Outstanding Balance']
              },
              plotOptions: {
                pie: {
                 dataLabels:{
                  labels: ['Paid Balance', 'Outstanding Balance']
                 }
                }
              }
        },
        series: [250000, 504580],
  
      
    };
  }


  render() {
    return (
      <div id="chart">
        <Chart
          options={
            {
              labels: ['Paid Balance', 'Outstanding Balance'],
              colors:['#000000','grey'],
            }
            
          }
          series={ [250000, 504580]}
          type="pie"
          height={350}
          
        />
      </div>
    );
  }
}
