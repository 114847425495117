import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from "../../../../helper/api";
import axios from "../../../../api/axios";

const initialState = {
  rcsDetail: [],
  isLoading: null,
  status: null,
};

const token = userObj?.token;

export const getRcsDetail = createAsyncThunk(
  "order/getRcsDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/Order/rcs/detail/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response.data.title);
    }
  }
);

const getRcsDetailSlice = createSlice({
  name: "getRcsDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [getRcsDetail.pending]: (state, action) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getRcsDetail.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.rcsDetail = action.payload;
      // localStorage.setItem('user', JSON.stringify(action.payload))
    },

    [getRcsDetail.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    },
  },
});

export default getRcsDetailSlice.reducer;
