import React, { useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { generateSMSToken } from "../../app/features/Customer/generateSMSToken";

const ConfirmPhone = () => {
    const [validateError, setValidateError] = useState({
        display:'none',
        message:null,
    })

    const [ searchParams ] = useSearchParams();
    const phone = searchParams.get("phone");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        try{
            dispatch(generateSMSToken(phone)).then((res)=>{
            let {payload, error} = res;
            {
                error ?
                setValidateError({
                    ...validateError,
                    display:'block',
                    message:payload
                })
                :
                navigate(`/verify-phone?phone=${phone}`)
            }
            })
        } catch(err){
            return err
        }

    }

    const {isLoading, status} = useSelector((state) => state.customerGenerateSMSToken)

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Confirm your mobile number</h2>
                    <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    We will send you a confirmation code
                    </p>
                    <form action="" method="get" onSubmit={handleSubmit}>
                        <InputContainer>
                        
                        <Input type="text" placeholder="Enter phone number" value={phone} name=""  />
                        </InputContainer>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Send Code</Button>
                        }         
                        </InputContainer>
                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default ConfirmPhone;