import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from "../../../../helper/api";
import axios from "../../../../api/axios";

const initialState = {
  rcsDetail: [],
  isConfirming: null,
  status: null,
};

const token = userObj?.token;

export const confirmRcs = createAsyncThunk(
  "order/confirmRcs",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/Order/confirmRCS?rcsId=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response.data.title);
    }
  }
);

const confirmRcsSlice = createSlice({
  name: "confirmRcs",
  initialState,
  reducers: {},
  extraReducers: {
    [confirmRcs.pending]: (state, action) => {
      state.status = "pending";
      state.isConfirming = true;
    },
    [confirmRcs.fulfilled]: (state, action) => {
      state.status = "success";
      state.isConfirming = false;
      state.data = action.payload;
      // localStorage.setItem('user', JSON.stringify(action.payload))
    },

    [confirmRcs.rejected]: (state, action) => {
      state.status = "failed";
      state.isConfirming = false;
    },
  },
});

export default confirmRcsSlice.reducer;
