import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DropDownArrowSpan,
  BrandLogo,
  ProfileOption,
  SideBarContainer,
  SideBarText,
  CloseSideBarDiv,
} from "./sidebar.styled";
import "./sidebar.css";
import DropDownArrow from "@mui/icons-material/ArrowDropDown";
import {
  Dashboard,
  DirectionsCar,
  Accessible,
  Savings,
  Inventory,
  ManageAccounts,
  LocalShipping
} from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import BrandLogoImg from "../../assets/images/Redspeed-black.png";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

const SideBar = () => {
  const ShowProfileOption = () => {
    document
      .getElementById("rotate-arrow-svg")
      .classList.toggle("rotate-arrow-svg");
    document.getElementById("profile-options").classList.toggle("hide");
  };

  const ToggleSideBar = () => {
    document.getElementById("sidebar").classList.toggle("hide-side-bar");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role[0].toLowerCase();
  return (
    <>
      <SideBarContainer id="sidebar" className="hide-side-bar">
        <SideBarText>
          <BrandLogo>
            <img src={BrandLogoImg} alt="" />
          </BrandLogo>
          <CloseSideBarDiv>
            <Close style={{ color: "#b23934" }} onClick={ToggleSideBar} />
          </CloseSideBarDiv>
        </SideBarText>
        <ul>
          {userRole === "partner" ? (
            <li>
              <Dashboard style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/partner">Dashboard</Link>
            </li>
          ) : (
            <li>
              <Dashboard style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/customer">Dashboard</Link>
            </li>
          )}
          {userRole === "customer" && (
            <li>
              <Inventory style={{ color: "#b23934" }} />{" "}
              <Button
                style={{ textTransform: "capitalize" }}
                variant="text"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Orders
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate("/dashboard/customer/orders")}>Orders</MenuItem>
                <MenuItem onClick={() => navigate("/dashboard/customer/cashondelivery")}>COD Report</MenuItem>
              </Menu>
            </li>
          )}

          {userRole === "customer" && (
            <li>
              <LocalShipping style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/customer/daily-shipments">Shipments</Link>
            </li>
          )}

          {userRole === "customer" && (
            <li>
              <Accessible style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/customer/rcs">RCS</Link>
            </li>
          )}

          {userRole === "partner" ? (
            <li>
              <DirectionsCar style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/partner/vehicles">Vehicles</Link>
            </li>
          ) : (
            <li>
              <DirectionsCar style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/customer/vehicles-types">Vehicles</Link>
            </li>
          )}
          {userRole === "partner" && (
            <li>
              <Accessible style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/partner/drivers">Drivers</Link>
            </li>
          )}
          {userRole === "partner" && (
            <li>
              <Savings style={{ color: "#b23934" }} />{" "}
              <Link to="/dashboard/partner/finance">Finance</Link>
            </li>
          )}
          <li>
            <ManageAccounts style={{ color: "#b23934" }} />{" "}
            <Link to="/dashboard/edit-profile">Profile</Link>
          </li>
        </ul>
      </SideBarContainer>
    </>
  );
};

export default SideBar;
