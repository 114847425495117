import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../../helper/protectedRoutes";
import baseUrl from '../../../../../helper/api';

const initialState = {
    vehicleTypeData:[],
    vTypeIsLoading:false,
    status:null
}

const token = userObj?.token

export const vehicleTypes = createAsyncThunk(
    'partner/vehicleTypes',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Settings/vehicleTypes`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const vehicleTypesSlice = createSlice({
    name: 'vehicleTypes',
    initialState,
    reducers: {},
    extraReducers:{
        [vehicleTypes.pending]: (state, action) => {
            state.status='pending';
            state.vTypeIsLoading=true;
        },
        [vehicleTypes.fulfilled]: (state, action) => {
            state.status='success';
            state.vTypeIsLoading=false;
            state.vehicleTypeData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [vehicleTypes.rejected]: (state, action) => {
            state.status='failed';
            state.vTypeIsLoading=false;
        }
    }
})

export default vehicleTypesSlice.reducer;