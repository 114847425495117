import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isDeleting:null,
    status:null,
}

const token = userObj?.token

export const deleteRiders = createAsyncThunk(
    'partner/deleteRiders',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.delete(`Partner/deleteRider/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const deleteRidersSlice = createSlice({
    name: 'deleteRiders',
    initialState,
    reducers: {},
    extraReducers:{
        [deleteRiders.pending]: (state, action) => {
            state.status='pending';
            state.isDeleting=true;
        },
        [deleteRiders.fulfilled]: (state, action) => {
            state.status='success';
            state.isDeleting=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [deleteRiders.rejected]: (state, action) => {
            state.status='failed';
            state.isDeleting=false;
        }
    }
})

export default deleteRidersSlice.reducer;