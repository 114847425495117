import React, { useEffect, useState } from "react";
import "./auth.css";
import AuthBg from "../../assets/images/auth-bg.jpg";
import RedSpeedLogo from "../../assets/images/Redspeed-black.png";
import {
  Remember,
  Section,
  ImgContainer,
  BgImage,
  ContentContainer,
  AuthFormContainer,
  InputContainer,
  Input,
  Label,
  CheckBox,
  Button,
  Brand,
  BrandImage,
} from "./auth.styled";
import { Link, useNavigate } from "react-router-dom";
import { Flex } from "../../components/Table/table.styled";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalInfo } from "../../app/features/Partner/registerSlice";
import { Alert } from "@mui/material";

const initialValue = {
  "companyName": null,
  "rcNumber": null,
  "address":null,
  "email":null,
  "contactName": null,
  "telephone": null,
  "password": null,
  "confirmPassword": null,
}

const RegisterPartner = () => {
  const [personalInfo, setPersonalInfo] = useState(initialValue)
  const [validateError, setValidateError] = useState({
    display: localStorage.getItem('error-display') ? JSON.parse(localStorage.getItem('error-display')): 'none',
    message: localStorage.getItem('error-message') ? JSON.parse(localStorage.getItem('error-message')): null,
});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, status} = useSelector((state) => state.customerRegister)

  useEffect(()=>{
    document.title = "RedSpeed - Partner Sign up"
},[])

  const handleSubmit = (e)=> {
    e.preventDefault()
    localStorage.setItem('personalInfo', JSON.stringify(personalInfo))
    navigate('/bank-information')
  }

  const handleInputChange= (e)=>{
    const {name, value} = e.target
    setPersonalInfo({
      ...personalInfo,
      [name]: value
    })
  }

  return (
    <>
      <Section>
        <ImgContainer>
          <BgImage src={AuthBg} alt="" />
        </ImgContainer>
        <ContentContainer>
          <AuthFormContainer>
            <Brand>
              <BrandImage src={RedSpeedLogo} />
            </Brand>

            <hr />
            <br />
            <h2>Partner Sign Up</h2>
            <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>
            {/* <div style={{marginBottom:'20px', display:`${registerError.display}`}}>
                    <Alert severity="error">{registerError.message}</Alert>
                    </div> */}
            <p className="margin-top-minus-5 margin-bottom-20">
              Enter the details below to begin your Sign Up
            </p>
            <form action="" onSubmit={(e)=> handleSubmit(e)}>
              <Flex>
                <InputContainer>
                  <Input type="text" placeholder="Company name" name="companyName" value={personalInfo.companyName} onChange={(e)=>handleInputChange(e)}  />
                </InputContainer>

                <InputContainer>
                  <Input type="text" placeholder="RC Number" name="rcNumber" value={personalInfo.rcNumber} onChange={(e)=>handleInputChange(e)}  />
                </InputContainer>
              </Flex>
<Flex>
              <InputContainer>
                <Input type="text" placeholder="Company's Address" name="address" value={personalInfo.address} onChange={(e)=>handleInputChange(e)}  />
              </InputContainer>

              <InputContainer>
                <Input type="text" placeholder="Company's Email" name="email" onChange={(e)=>handleInputChange(e)}  />
              </InputContainer>
              </Flex>

              <Flex>
              <InputContainer>
                <Input type="text" placeholder="Contact Name" name="contactName" value={personalInfo.contactName} onChange={(e)=>handleInputChange(e)}  />
              </InputContainer>

              <InputContainer>
                <Input type="text" placeholder="Contact Phone Number" name="telephone" onChange={(e)=>handleInputChange(e)}  />
              </InputContainer>
              </Flex>

              <Flex>
              <InputContainer>
                <Input type="password" placeholder="Password" name="password" required onChange={(e)=>handleInputChange(e)} />
              </InputContainer>

              <InputContainer>
                <Input type="password" placeholder="Confirm Password" name="confirmPassword" required onChange={(e)=>handleInputChange(e)} />
              </InputContainer>
              </Flex>
              <Remember>
                <Label>
                  <CheckBox type="checkbox" name="" /> By continuing, you're
                  agreeing to our customer terms of service, privacy policy and
                  cookie policy
                </Label>
              </Remember>

              <InputContainer>
                <Button type="submit">Complete</Button>
              </InputContainer>

              <InputContainer>
                <p style={{ textAlign: "center" }}>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </InputContainer>
            </form>
          </AuthFormContainer>
        </ContentContainer>
      </Section>
    </>
  );
};

export default RegisterPartner;
