import React, { useEffect, useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { customerLogin } from "../../app/features/Customer/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";



const Login = () => {
    const [loginDetails, setLoginDetails] = useState({
        email:'',
        password:''
    })
    const [validateError, setValidateError] = useState({
        display:'none',
        message:null,
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    

    const {isLoading, status} = useSelector((state) => state.customerLogin)

    useEffect(()=>{
        document.title = "RedSpeed - Login"
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        try{
            dispatch(customerLogin(loginDetails)).then((res)=> {
                let {payload, error} = res;
                {
                    if(error){
                        setValidateError({
                            ...validateError,
                            display:'block',
                            message:payload
                        })
                    }else{
                        if(payload?.role[0] === 'Customer' || payload?.role[0] === 'Partner'){
                            toast.success('Login Successful...')
                            window.location.reload()
                        //     navigate("/dashboard/customer");
                        // }else if(payload?.role[0] === 'Partner'){
                        //     navigate("/dashboard/partner");
                        }else{
                            localStorage.removeItem('user')
                            setValidateError({
                                ...validateError,
                                display:'block',
                                message:'Sorry you cannot login to this portal'
                            })
                        }
                    }    
                }
            })
        }catch(err){
            return err
        }

    }

    const handleInputChange = (e)=> {
        const {name, value} = e.target
        setLoginDetails({
            ...loginDetails,
            [name]: value
        })
    }


    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Login</h2>
                    <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>
                    <form action="" method="post"onSubmit={(e)=>handleSubmit(e)}>
                        <InputContainer>
                        <span>Username</span>
                        <Input type="text" name="email" onChange={(e)=> handleInputChange(e)}  />
                        </InputContainer>

                        <InputContainer>
                        <span>Password</span>
                        <Input type="password" name="password" onChange={(e)=> handleInputChange(e)}  />
                        </InputContainer>

                        <p style={{textAlign:'right'}} className="margin-top-minus-5 margin-bottom-20">
                            <Link style={{color:'black'}} to='/forgot-password'>
                    Forgot password?
                    </Link>
                    </p>

                        <Remember>
                            <Label><CheckBox type='checkbox' name=""/> Remember me</Label>
                        </Remember>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Sign in</Button>
                        }         
                        </InputContainer>


                        <InputContainer>
                        <p>Don't have an account? <Link to='/register/customer'>Sign up as an Individual</Link> or <Link to='/register/partner'>Sign up as a Partner</Link> </p>
                        </InputContainer>
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default Login;