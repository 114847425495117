import { Outlet, Navigate } from "react-router-dom";

export const userObj = JSON.parse(localStorage.getItem('user'));
const AuthRoutes = () => {
    const userRole = userObj?.role[0];
    var isAuthenticated;
    if(userObj?.token){
        isAuthenticated = true;
    }else{
        isAuthenticated = false;
    }
    return(
        isAuthenticated ? <Navigate to={`/dashboard/${userRole.toLowerCase()}`}/> : <Outlet/>
    )
}

export default AuthRoutes;