import styled from "styled-components";

export const Section = styled.section`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
`

export const Brand = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
`

export const BrandImage = styled.img`
    height: 90%;
`

export const ImgContainer = styled.div`
    position: relative;
    width: 60%;
    height: 100%;

    &:before{
        content:'';
        position:absolute;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        // background: linear-gradient(225deg, #b23934, black);
        background: black;
        z-index:1;
        mix-blend-mode: screen;
    }

    @media (max-width: 768px){
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
    }
`

export const BgImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;
`

export const ContentContainer = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px){
        display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index:1;
    }
`

export const AuthFormContainer = styled.div`
    width: 85%;
    max-height:90vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0;  
        background: transparent;  
    }
    
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    h2{
        color:#607d8b;
        font-weight: bold;
        font-size: 1.5em;
        text-transform: uppercase;
        margin-bottom:20px;
        border-bottom:3px solid rgb(34, 32, 32);
        display: inline-block;
        letter-spacing:1px;
    }

    @media (max-width: 768px){
       
    width: 100%;
    max-height:90vh;
    padding: 30px;
    background:rgb(255 255 255 / 0.9);
    margin:20px;
    }
`

export const InputContainer = styled.div`
width:100%;
    margin-bottom: 20px;
    padding: 2px 5px 2px 2px;
    box-sizing:border-box;
    span{
        font-size:16px;
        margin-bottom:5px;
        display: inline-block;
        color: #607d8b;
        font-weight: 300;
        letter-spacing:1px
    }
    p{
        color: #607d8b;
    }
    a{
        color: #000000;
    }
`

export const Input = styled.input`
    width: 100%;
    padding:5px 10px;
    outline: none;
    font-weight: 400;
    border: 1px solid #607d8b;
    font-size: 14px;
    letter-spacing:1px;
    color:#607b8b;
    background: transparent;
    box-sizing: border-box;
`

export const Button = styled.button`
    background-color: rgb(34, 32, 32);
    outline: none;
    width: 100%;
    padding:10px 10px;
    outline: none;
    font-weight: 400;
    border: none;
    font-size: 15x;
    letter-spacing:1px;
    color:#fff;
    cursor: pointer;
    border-radius: 10px;
    

    :hover{
        background: grey;
    }
`

export const Remember = styled.div`
    margin-bottom: 10px;
    color: #607d8b;
    font-weight:400;
    font-size:15px;
`

export const Label = styled.label`

`
export const CheckBox = styled.input`

`