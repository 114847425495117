import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../../helper/protectedRoutes";
import baseUrl from '../../../../../helper/api';

const initialState = {
    vehicleCatData:[],
    categoryIsLoading:false,
    status:null,
}

const token = userObj?.token

export const vehicleCategories = createAsyncThunk(
    'partner/vehicleCategories',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Settings/VehicleCategories`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const vehicleCategoriesSlice = createSlice({
    name: 'vehicleCategories',
    initialState,
    reducers: {},
    extraReducers:{
        [vehicleCategories.pending]: (state, action) => {
            state.status='pending';
            state.categoryIsLoading=true;
        },
        [vehicleCategories.fulfilled]: (state, action) => {
            state.status='success';
            state.categoryIsLoading=false;
            state.vehicleCatData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [vehicleCategories.rejected]: (state, action) => {
            state.status='failed';
            state.categoryIsLoading=false;
        }
    }
})

export default vehicleCategoriesSlice.reducer;