import React from "react";
import '../auth.css'
import AuthBg from '../../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "../auth.styled";
import { Link } from "react-router-dom";

const ResetPassword = () => {
    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Reset Password</h2>
                    <form action="" method="post">
                    <InputContainer>
                        <span>New password</span>
                        <Input type="password" name=""  />
                        </InputContainer>

                        <InputContainer>
                        <span>Confirm new password</span>
                        <Input type="password" name=""  />
                        </InputContainer>


                        <InputContainer>
                        <Button type='submit'>Submit</Button>
                        </InputContainer>

                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default ResetPassword;