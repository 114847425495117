import { createSlice } from "@reduxjs/toolkit";

export const createOrderModalSlice = createSlice({
    name: 'adViewCounter',
    initialState: {
        isCreateOrderModalOpened: false,
    }, 
    reducers: {
        openOrder: (state) => {
            state.isCreateOrderModalOpened = true
        },
        closeOrder: (state) => {
            state.isCreateOrderModalOpened = false
        },
    },
})

export const {openOrder, closeOrder} = createOrderModalSlice.actions;
export default createOrderModalSlice.reducer