import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

export const Nav = styled.nav`
    background: rgb(34, 32, 32);
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem calc((100vw - 1000px)/2);
    z-index: 10;
    position: fixed;
    top:0;
    width: 100%;
    box-sizing: border-box;
`

export const NavLink = styled(Link)`
    color: white;
    display flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    width: 80px
    cursor: pointer;

    &.active{
        color: blue;
    }
`

export const Bars = styled(MenuIcon)`
    display: none !important;
    color: #fff;

    @media screen and (max-width: 768px){
        display: block !important;
        position absolute;
        top 0;
        right:0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;

@media screen and (max-width:768px){
    display: none;
}
`

export const NavBtnLink = styled(Link)`
background: #256ce1;
padding: 10px 22px;
color: #fff;
border: none;
outline: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration:none;
&.hover{
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010666;
}
`

export const SmallScreenNavDropDown = styled(Link)`
background: #b23934;
height: 500px;
width: 100%;
z-index:3;
`