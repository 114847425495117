import { Outlet, Navigate } from "react-router-dom";

export const userObj = JSON.parse(localStorage.getItem('user'));
const PartnerRoutes = () => {
    const userRole = userObj?.role[0]
    return(
        userRole.toLowerCase() !== 'customer' ? <Outlet/> : <Navigate to={`/dashboard/customer`} />
    )
}

export default PartnerRoutes;