import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../../helper/api';

const initialState = {
    data:[],
    isLoading:false,
    status:null,
    error:null
}

export const customerRegister = createAsyncThunk(
    'customer/register',
    async (data, {rejectWithValue}) => {
        try{
            const response = await axios.post(`${baseUrl}/Account/registerCustomer`, data)
            return response?.data
        } catch (err) {
            console.log(err.response.data)
            return rejectWithValue(err.response.data)
        }
        
    }
)

const customerRegisterSlice = createSlice({
    name:"customer",
    initialState,
    reducers:{},
    extraReducers:{
        [customerRegister.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [customerRegister.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
        },
        [customerRegister.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            // state.error = action.error
        }
    }
})

export default customerRegisterSlice.reducer;