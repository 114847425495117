import React from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PartnerAccCreationCongrats = () => {
    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>
<div style={{textAlign:'center'}}>
                    <hr />
                    <br />
                    
                    <h2>Congratulations</h2>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Your account has been successfully created, you will be contacted via email or phone when approved.
                    </p>
<CheckCircleOutlineIcon style={{fontSize:'200px', color:'#748a74'}}/>
                    <InputContainer>
                        <p><Link to='/login'> Back to login</Link> </p>
                        </InputContainer>
                    </div>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default PartnerAccCreationCongrats;