import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    cards:[],
    isLoadingCards:null,
    status:null,
}

const token = userObj?.token

export const customerCards = createAsyncThunk(
    'customer/customerCards',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("Wallets/customercards",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const customerCardsSlice = createSlice({
    name: 'customerCards',
    initialState,
    reducers: {},
    extraReducers:{
        [customerCards.pending]: (state, action) => {
            state.status='pending';
            state.isLoadingCards=true;
        },
        [customerCards.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoadingCards=false;
            state.cards = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [customerCards.rejected]: (state, action) => {
            state.status='failed';
            state.isLoadingCards=false;
        }
    }
})

export default customerCardsSlice.reducer;