import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../../helper/api';

const initialState = {
    data:[],
    isLoading:false,
    status:null,
    error:null
}

export const customerLogin = createAsyncThunk(
    'customer/login',
    async (data, {rejectWithValue}) => {
        try{
            const response = await axios.post(`${baseUrl}/Account/token`, data)
            return response?.data
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response.data)
        }
        
    }
)

const customerLoginSlice = createSlice({
    name:"customer",
    initialState,
    reducers:{},
    extraReducers:{
        [customerLogin.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [customerLogin.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload))
        },
        [customerLogin.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            state.error = action.error
        }
    }
})

export default customerLoginSlice.reducer;