import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
  data: [],
  isLoading: null,
  status: null
}

const token = userObj?.token

export const getOrderPayments = createAsyncThunk(
  'partner/getOrderPayments',
  async (id, w, {rejectWithVlaue}) => {
    const {startDate, endDate} = w
    try {
      const response = await axios.get(`Partner/orders/payment/${id}?start=${startDate}&end=${endDate}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response?.data
    } catch (err) {
        return rejectWithVlaue(err.response.data.title)
    }
  }
)

const getOrderPaymentsSlice = createSlice({
  name: 'getOrderPayments',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderPayments.pending]: (state) => {
      state.status = 'pending';
      state.isLoading = true;
    },

    [getOrderPayments.fulfilled]: (state, action) => {
      state.status = 'success';
      state.isLoading = false;
      state.data = action.payload;
    },

    [getOrderPayments.rejected]: (state) => {
      state.status = 'failed';
      state.isLoading = false
    }
  }
})

export default getOrderPaymentsSlice.reducer;