import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../helper/protectedRoutes";
import axios from "../../../api/axios";

const initialState = {
    data: [],
    isLoading: null,
    status: null,
}

const token = userObj?.token;

export const getTowns = createAsyncThunk(
    "customer/getTowns",
    async(id, {rejectWithValue}) => {
        try {
            const response = await axios.get(`Settings/Towns/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response?.data
        }
        catch (err) {
            return rejectWithValue(err.response.data.title)
        }
    }
)

const getTownsSlice = createSlice({
    name: 'getTowns',
    initialState,
    reducers: {},
    extraReducers:{
        [getTowns.pending]: (state, action) => {
            state.status = 'pending';
            state.isLoading = true;
        },
        [getTowns.fulfilled]: (state, action) => {
            state.status = 'success';
            state.isLoading = false;
            state.data = action.payload;
        },
    
        [getTowns.rejected]: (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
        }
    }
})

export default getTownsSlice.reducer