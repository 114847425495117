import React, { useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../app/features/forgotPasswordSlice";
import { Alert, CircularProgress } from "@mui/material";

const ForgotPassword = () => {

    const [email, setEmail] = useState({
        email:''
    });
    const [validateError, setValidateError] = useState({
        display:'none',
        message:null,
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isLoading, status} = useSelector((state) => state.forgotPassword)

    const handleSubmit = (e) => {
        e.preventDefault()

        try{
            dispatch(forgotPassword(email)).then((res)=> {
                let {payload, error} = res
                console.log(res)
                {
                    error?
                    setValidateError({
                        ...validateError,
                        display:'block',
                        message:payload
                    })
                    :
                    navigate(`/forgot-password/success?email=${email?.email}`)
                }
            })
        }catch(err){

        }
    }

    console.log(email?.email)

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Forgot Password</h2>
                    <div style={{marginBottom:'20px', display:`${validateError?.display}`}}>
                    <Alert severity="error">{validateError?.message}</Alert>
                    </div>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Please enter the Email Address associated with your account
                    </p>
                    <form action="" method="post" onSubmit={(e)=> handleSubmit(e)}>
                        <InputContainer>
                        
                        <Input type="text" placeholder="Enter email address" name="" required onChange={(e)=> setEmail({email:e.target.value})} />
                        </InputContainer>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Submit</Button>
                        }         
                        </InputContainer>

                        
                        <InputContainer>
                        <p><Link to='/login'> Back to login</Link> </p>
                        </InputContainer>
                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default ForgotPassword;