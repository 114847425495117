import React from "react";
import NavBar from "../../../components/navbar/navbar.component"
import {
  AddNew,
  Flex,
  SearchInput,
  Table,
  TableWrapper,
  VehiclesTableContainer,
} from "../Vehicle/vehicles.styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import Alert_ from "../../../helper/alert/alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userObj } from "../../../helper/protectedRoutes";
import { getAllOrders } from "../../../app/features/Partner/order/allOrdersSlice"
import { getOrderAssign } from "../../../app/features/Partner/order/orderAssignRiderSlice"
import { getOrdersFinance } from "../../../app/features/Partner/order/orderFinanceSlice";
import { getOrderPayments } from "../../../app/features/Partner/order/ordersPaymentSlice";
import { getAllRiders } from "../../../app/features/Partner/rider/getAllRiders";
import { getDrivers } from "../../../app/features/Partner/rider/getDriversSlice"
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const initialValue = {}

const PartnerOrderPage = () => {
  const dispatch = useDispatch();
  const id = userObj?.extra.id

  const [data, setData] = React.useState([]);
  const [openAssignRider, setOpenAssignRider] = React.useState(false);
  const [openViewOrder, setOpenViewOrder] = React.useState(false)
  const [riderId, setRiderId] = React.useState()
  const [orderId, setOrderId] = React.useState('')
  const [approvedRiders, setApprovedRiders] = React.useState([])
  const [orderDetails, setOrderDetails] = React.useState([])
  const [assignRiderProperty, setAssignRiderProperty] = React.useState({
    orderId: orderId,
    riderId: ''
  })
  const [alertToggle, setAlertToggle] = React.useState({
    isVisible: false,
    color: "success",
    message: ""
  })
  const [query, setQuery] = React.useState("")

  useEffect(() => {
    document.title = "Orders - RedSpeed";
    dispatch(getAllOrders()).then((res) => {
      console.log("orders :>>", res)
      const { payload, error } = res;
      !error && setData(payload);
    });
    dispatch(getDrivers(id)).then((res)=> {
      const {error, payload} = res
      console.log(res)
      !error && setApprovedRiders(payload)
    })
  }, []);

  const { isLoading } = useSelector((state) => state.getAllOrders);
//   const { isLoading2 } = useSelector((state) => state.getOrderAssign);

  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  }

  const handleAssignRiderChange = (event) => {
    const { name, value } = event.target;
    setAssignRiderProperty({
      ...assignRiderProperty,
      [name]: value
    })
  }

  const AssignRider = () => {
    dispatch(getOrderAssign(assignRiderProperty)).then((res) => {
      const { payload, error } = res
      console.log(riderId)
      error ? console.log(payload) && alert(error)
      :
      alert("rider assigned successfully")
      setOpenAssignRider(false)
      setAssignRiderProperty({
        orderId: orderId,
        riderId: ''
      })
    })
  }

  const handleOpenViewOrder = (row_param) => {
    setOrderDetails(row_param);
    // setOrderId(row_param.id)
    setOrderId(row_param.id)
    // console.log("orderId >>", orderId)
    setOpenViewOrder(true)
  }
  const handleOpenAssignRider = () => {
    setOpenAssignRider(true)
  }

  const handleCloseViewOrder = () => {
    setOpenViewOrder(false)
  }
  const handleCloseAssignRider = () => {
    setOpenAssignRider(false)
  }
  const navigate = useNavigate()

  return (
    <>
      <NavBar />

      <div className="main-content main-content-margin-top">
        <div name="View Order Modal">
          <Dialog
          open={openViewOrder}
          onClose={handleCloseViewOrder}
          fullWidth={true}
          maxWidth="sm"
          >
            <DialogTitle>
              <p className="mb-0">
              { "Order - " + orderDetails.orderNo }
              </p>
              <hr className="mb-2"/>
            </DialogTitle>
            <DialogContent>
                <div
                className=""
                >
                  <div className="media align-items-start">
                    <div className="media-body ml-2">
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 mt-1">
                        </p>
                        <span className="btn-group btn-group-toggle btn-group-edges">
                          <button onClick={() => {handleOpenAssignRider(); assignRiderProperty.orderId = orderDetails.id}} className="btn btn-sm bg-success text-white">
                            Assign Rider
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">
                        Pickup Location
                      </p>
                      <span className="fw-bold d-block">
                        { orderDetails.pickup }
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">
                        Order Date
                      </p>
                      <span className="fw-bold d-block">
                        { new Date(orderDetails.orderDate).toDateString() }
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4">
                    <div className="media-body">
                      <p className="text mb-0">
                        Estimated Distance
                      </p>
                      <span className="fw-bold d-block">
                        { orderDetails.estimatedDistance ? (orderDetails.estimatedDistance / 1000).toFixed(2) + "km" : null }
                      </span>
                    </div>
                  </div>
                  <div className="media align-items-start mt-4 mb-4">
                    <div className="media-body">
                      <p className="text mb-0">
                        Estimated Time
                      </p>
                      <span className="fw-bold d-block">
                        { orderDetails.estimatedTime ? Math.ceil(orderDetails.estimatedTime / 60) + "mins" : null }
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="">
                      <p className="fs-5">Destination</p>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <td className="className">S/N</td>
                              <td className="">Destination</td>
                              <td className="">Description</td>
                              <td className="">Recipient Name</td>
                              <td className="">Recipient Phone</td>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails?.destinationPoints?.map((d, index) => {
                              return (
                                <tr key={index}>
                                  <td className="">
                                    { index + 1 }
                                  </td>
                                  <td className="">
                                    { d.destination }
                                  </td>
                                  <td className="">
                                    { d.description }
                                  </td>
                                  <td className="">
                                    { d.recipientName }
                                  </td>
                                  <td className="">
                                    { d.recipientPhone }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="primary"
                onClick={() => handleCloseViewOrder()}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Assign Rider Modal">
          <Dialog
          open={openAssignRider}
          onClose={handleCloseAssignRider}
          >
            <DialogTitle>
              Assign Rider
            </DialogTitle>
            <DialogContent>
            <div>
              <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Rider
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignRiderProperty.riderId}
                  name='riderId'
                  label="Select rider"
                  onChange={handleAssignRiderChange}
                >
                  {
                    approvedRiders?.map((driver, Key)=> <MenuItem key={Key} value={driver?.id}>{`${driver?.rider?.firstName} ${driver?.rider?.lastName}` }</MenuItem>)
                  }
                </Select>
                <FormHelperText>select a driver to add</FormHelperText>
              </FormControl>
            </div>
            </DialogContent>
            <DialogActions>
            <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseAssignRider}>Cancel</Button>
            {/* {isLoading2 === true ? (
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                ) : ( */}
            <Button onClick={() => AssignRider()} type="submit" style={{textTransform:'capitalize', fontWeight:'700'}} variant="contained" color="primary" >Assign</Button>
                {/* )} */}
            
          </DialogActions>
          </Dialog>
        </div>

        <VehiclesTableContainer>
          <p style={{ fontSize: "1.8em", marginLeft: "10px" }}> <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i> New Orders</p>
          <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <td className="">Order Number</td>
                  <td className="">Pickup Location</td>
                  <td className="">OrderDate</td>
                  {/* <td className="">Price</td> */}
                  <td className=""></td>
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {data
                    .filter(
                      (order) =>
                        order.orderNo
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((row_, index) => {
                      return (
                        <tr key={index}>
                          <td className="">
                            { row_.orderNo}
                          </td>
                          <td className="">
                            { row_.pickup }
                          </td>
                          <td className="">
                          { new Date(row_.orderDate).toDateString() }
                          </td>
                          {/* <td className="">
                            { "₦" + row_.orderPrice.minValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " ~ ₦" + row_.orderPrice.maxValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          </td> */}
                          <td className="">
                            <Flex>
                              {""}
                              <div
                              className="blue"
                              onClick={() => handleOpenViewOrder(row_)}
                              >
                                <VisibilityIcon />
                              </div>
                            </Flex>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              )}
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </VehiclesTableContainer>
      </div>
    </>
  )
}

export default PartnerOrderPage;