import styled from "styled-components";

export const WidgetFlex = styled.div`
display: flex;
margin: 0 auto;
    flex-direction:row;
    justify-content: space-between;

    a{
        text-decoration: none;
        color: black;
    }

    @media screen and (max-width: 768px){
        flex-direction:column;
    }
`

export const QuickActionFlex = styled.div`
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
background-color: #fff;
margin-bottom: 1.5rem;
-webkit-box-shadow: 0 1px 2px #ccc;
box-shadow: 0 1px 2px #ccc;
// padding: 10px!important;
margin: 8px;
box-sizing: border-box;
`

export const QuickActionItemContainer = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
width: 16.6%;
// padding-top: 7px !important;
margin: 8px 0;
box-sizing: border-box;
text-align:center;
height: 64px;
font-size: 0.9em;
font-weight:500;
cursor: pointer;

@media screen and (max-width: 768px){
    width: 120px;
    margin-bottom: 5px;
    font-size: 0.7em;
}
`