import React from "react";
import { WidgetContainer } from "./widget.styled";


const Widget = (props) => {
  
  return (
    <>
    <WidgetContainer bottomColor={props.headcolor}>
    <p style={{fontSize:'1.35rem', color:`${props.headcolor}`, fontWeight:'bold'}}>{props.currency}{props.head}</p>
        <p>{props.bottom}</p>
    </WidgetContainer>
    </>
  );
};

export default Widget;
