import React from "react";
import NavBar from "../../../components/navbar/navbar.component";
import {
  AddNew,
  Flex,
  SearchInput,
  Table,
  TableWrapper,
  VehiclesTableContainer,
} from "./vehicles.styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Alert_ from "../../../helper/alert/alert";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../../../app/features/Partner/vehicles/getAllVehicles";
import { vehicleBrands } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleBrands";
import { vehicleCategories } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleCategories";
import { vehicleModels } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleModels";
import { vehicleSizes } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleSizes";
import { vehicleTypes } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleTypes";
import { userObj } from "../../../helper/protectedRoutes";
import { InputContainer } from "../../Auth/auth.styled";
import { addVehicle } from "../../../app/features/Partner/vehicles/addVehicle";
import deleteVehicle from "../../../app/features/Partner/vehicles/deleteVehicleSlice";
import { editVehicle } from "../../../app/features/Partner/vehicles/editVehicleSlice";
import {vehicleDelete} from "../../../app/features/Partner/vehicles/actions"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const initialValue = {
  PartnerId: null,
  TypeId: null,
  BrandId: null,
  ModelId: null,
  SizeId: null,
  Year: null,
  RegNo: null,
  Capacity: null,
  Status: null,
  VehicleDocuments: null,
};

const PartnerVehiclePage = () => {
  const id = userObj?.extra.id;

  const [data, setData] = React.useState([]);
  const [vehicleData, setVehicleData] = React.useState({
    ...initialValue,
    'PartnerId':id,
    'Status':0,
  });
  const [openEditVehicle, setOpenEditVehicle] = React.useState(false);
  const [openAddVehicle, setOpenAddVehicle] = React.useState(false);
  const [openAddVehicleDocs, setOpenAddVehicleDocs] = React.useState(false);
  const [openEditVehicleDocs, setOpenEditVehicleDocs] = React.useState(false);
  const [openDeleteVehicle, setOpenDeleteVehicle] = React.useState(false);
  const [openViewVehicle, setOpenViewVehicle] = React.useState(false);
  const [vehicleId, setVehicleId] = React.useState();
  const [vehicleDetails, setVehicleDetails] = React.useState([]);
  const [vType, setVType] = React.useState("");
  const [vehicleDocsList, setVehicleDocsList] = React.useState([]);
  const [alertToggle, setAlertToggle] = React.useState({
    isVisible: false,
    color: "success",
    message: "",
  });

  console.log(vehicleData);
  const [query, setQuery] = React.useState("");

  const dispatch = useDispatch();

  const handleVType = (e) => {
    setVType(e.target.value);
  };



  const handleAddVehicleChange = (e) => {
    const { name, value } = e.target;
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };
  
  
  const handleAddVehicleDocs = (e) => {
    const { files } = e.target;
    // vehicleDocsList.push(files[0])
    const {name, lastModified, lastModifiedDate, webkitRelativePath, size, type} = files[0]
    const fileObjs = {name, lastModified, lastModifiedDate, webkitRelativePath, size, type}
    vehicleDocsList.push(fileObjs)
    setVehicleData({
      ...vehicleData,
      'VehicleDocuments': (vehicleDocsList)
    })
  };


  // const {isLoading, status} = useSelector((state) => state.getAllVehicles)
  
  // const a = dispatch(getAllVehicles())
  // console.log(a)

  useEffect(() => {
    document.title = "Vehicles - RedSpeed";
    dispatch(getAllVehicles(id)).then((res)=>{
      console.log('vehicles >>', res)
      const {error, payload} = res;
      {
        !error && setData(payload)
      }
    });
  }, []);

  // Search Function
  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  // Alert Functions
  const handleAlertOpen = (color_param, message_param) => {
    setAlertToggle({
      ...alertToggle,
      isVisible: true,
      color: color_param,
      message: message_param,
    });
  };

  const handleAlertClose = () => {
    setAlertToggle({
      ...alertToggle,
      isVisible: false,
    });
  };

  // Modal Functions
  const handleOpenViewVehicle = (row_param) => {
    setVehicleDetails(row_param);
    setOpenViewVehicle(true);
  };

  const handleCloseViewVehicle = () => {
    setOpenViewVehicle(false);
  };

  // Edit Vehicle  Modal
  const handleOpenEditVehicle = (row_param) => {
    setVehicleDetails(row_param);
    setOpenEditVehicle(true);
  };

  const { vehicleTypeData, vTypeIsLoading } = useSelector(
    (state) => state.vehicleTypes
  );
  const { vehicleSizeData, vehicleSizeIsLoading } = useSelector(
    (state) => state.vehicleSizes
  );
  const { vehicleModelData, modelIsLoading } = useSelector(
    (state) => state.vehicleModels
  );
  const { vehicleCatData, categoryIsLoading } = useSelector(
    (state) => state.vehicleCategories
  );
  const { vehicleBrandData, brandIsLoading } = useSelector(
    (state) => state.vehicleBrands
  );
  const { isAdding } = useSelector((state) => state.addVehicle);

  const { isLoading } = useSelector((state) => state.getAllVehicles);
  
  // const { isDeleting } = useSelector((state) => state.deleteVehicle);

  const { isDeleting } = useSelector((state) => state.vehicleDelete);

  // let r = vehicleSizeData.filter((type)=> type?.typeId === 7)
  // console.log(r[0].typeId)

  const handleOpenAddVehicle = () => {
    dispatch(vehicleBrands());
    dispatch(vehicleCategories());
    dispatch(vehicleModels());
    dispatch(vehicleSizes());
    dispatch(vehicleTypes());
    // setOpenAddVehicle(true);
    setOpenAddVehicle(true);
  };

  const navigate = useNavigate()

  const handleAddVehicleType = (e) => {
    const { name, value } = e.target;
    let vSize = vehicleSizeData.filter((type) => type?.typeId === value);
    let vType = vehicleTypeData.filter((type) => type?.id === value);
    setVehicleData({
      ...vehicleData,
      [name]: value,
      "SizeId": vSize[0]?.id,
    });
  };

  const handleAddVehicleModel = (e) => {
    const { name, value } = e.target;
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };

  const handleAddVehicleBrand = (e) => {
    const { name, value } = e.target;
    let vBrand = vehicleBrandData.filter((brand) => brand?.id === value);
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };
  

  const handleSubmitAddVehicle = () => {
   console.log("vehicleData", vehicleData) 
    dispatch(addVehicle(vehicleData)).then((res) => {
      const { payload, error } = res;
      if (error) {
        console.log(res);
        alert(error)
      } else {
        console.log(res);
        console.log(payload);
        alert('vehicle added successfully')
        window.location.reload()
        dispatch(getAllVehicles(userObj?.extra.id))
        setOpenAddVehicle(false);
        setOpenAddVehicleDocs(false);
      }
    });
  };

  const handleAddVehicleNext = () => {
    setOpenAddVehicle(false)
    setOpenAddVehicleDocs(true);
  };

  const handleCloseAddVehicleDocs = () => {
    setOpenAddVehicleDocs(false);
  };

  const handleCloseAddVehicle = () => {
    setOpenAddVehicle(false);
  };

  const handleCloseEditVehicle = () => {
    setOpenEditVehicle(false);
    setEditViewOne(false);
    setEditViewTwo(false)
  };

  const [editViewOne, setEditViewOne] = useState(false)
  const [editViewTwo, setEditViewTwo] = useState(false) 
  
  const [vehicleEditDocs, setVehicleEditDocs] = useState([])
  console.log("vehicleDocs >>", vehicleEditDocs)
  const docs =  vehicleEditDocs.map((editDoc) => {
    return {
      name: editDoc.name,
      lastModified: editDoc.lastModified,
      lastModifiedDate: editDoc.lastModifiedDate,
      webkitRelativePath: editDoc.webkitRelativePath,
      size: editDoc.size,
      type: editDoc.type
    }
  })
  const pushedDocs = [docs]
  // pushedDocs.push(docs)
  console.log("pushedDocs >>", docs)
  // const [selectedVehicleEdit, setSelectedVehicleEdit] = useState({})
  const [vehicleEdit, setVehicleEdit] = useState({})
  const [editFormValues, setEditFormValues] = useState({})
  const selectVehicleEdit = (row_) => {
    const newRow = {
      ...row_
    };
    setVehicleEdit(newRow);
    console.log('selectEdit >>', newRow)
    const editValues = {
      Id: row_?.id,
      CategoryId: row_?.categoryId,
      Type: row_?.vehicleType?.name,
      Brand: row_?.vehicleBrand?.name,
      Model: row_?.model?.name,
      "Year": row_?.year,
      RegNo: row_?.regNo,
      Capacity: row_?.capacity,
      Picture1Url: row_?.picture1Url,
      Picture2Url: row_?.picture2Url,
      Picture3Url: row_?.picture3Url,
      Picture4Url: row_?.picture4Url,
      "Category.Id": 0,
      "Category.CategoryName": "",
      "Category.BaseFare": 0,
      "Category.BookingFare": 0,
      VehicleDocuments: vehicleEditDocs
    }
    setEditFormValues(editValues)
    console.log('selectEditValues >>', editValues)
    setOpenEditVehicle(true);
    setEditViewOne(true)
  }

  const handleEditDocsList = () => {
    setEditViewOne(false)
    setEditViewTwo(true)
  }

  const handleVehicleEditInputChange = (e) => {
    const { name, value } = e.target
    setEditFormValues({
      ...editFormValues,
      [name]: value
    })
    // setEditFormValues(nextEditState)
  }

  const handleEditVehicleDocs = (e) => {
    const { files } = e.target;
    const {name, lastModified, lastModifiedDate, webkitRelativePath, size, type} = files[0]
    const fileObjs = {name, lastModified, lastModifiedDate, webkitRelativePath, size, type}
    vehicleEditDocs.push(fileObjs)
    console.log("docs >>", vehicleEditDocs)
    const nextEditState = {
      ...editFormValues,
      // 'VehicleDocument': JSON.stringify(vehicleEditDocs)
    }
    setVehicleEdit(nextEditState)
  }
  // const handleEditVehicleInput = (e) => {
  //   const { name, value } = e.target;
  //   setVehicleDetails({
  //     ...vehicleDetails,
  //     [name]: value
  //   })
  // }

  const [selectedForDelete, setSelectedForDelete] = useState({})
  const selectForDelete = (row_) => {
    setSelectedForDelete(row_);
    console.log('vehicle-to-delete >>', row_)
    setOpenDeleteVehicle(true)
  }

  const handleSubmitDeleteVehicle = () => {
    dispatch(deleteVehicle(vehicleId)).then((res) => {
      const { error } = res;
        error ? alert(error) : handleCloseDeleteVehicle()
        alert("Vehicle Successfully deleted")
        dispatch(getAllVehicles(userObj?.extra.id)).then((res) => {
          const { payload, error } = res;
          !error && setData(payload)
        })
    });
    setOpenDeleteVehicle(false)
  }
  const handleSubmitVehicleDelete = (id) => {
    dispatch(vehicleDelete(id)).then(() => {
      alert("Vehicle successfully deleted")
      dispatch(getAllVehicles(userObj?.extra.id)).then((res) => {
        const { payload, error } = res;
        !error && setData(payload)
      })
    });
    setOpenDeleteVehicle(false)
  }

  const handleSubmitEditVehicle = () => {
    // e.preventDefault()
    dispatch(editVehicle(editFormValues)).then(() =>{
      console.log("vehicleEdit >>", editFormValues)
      setVehicleEditDocs([])
      dispatch(getAllVehicles(userObj?.extra.id)).then((res) => {
        const { payload, error } = res;
        !error && setData(payload)
      })
    })
    // handleAlertOpen("warning", "Successully edited vehicle");
    setOpenEditVehicle(false);
    setEditViewOne(false);
    setEditViewTwo(false)
  };

  const handleOpenDeleteVehicle = (vehicle_id) => {
    setVehicleId(vehicle_id)
    setOpenDeleteVehicle(true);
  };

  const handleCloseDeleteVehicle = () => {
    setOpenDeleteVehicle(false);
  };

  function setStatus (status) {
    switch (status) {
      case 0:
        return <p className="text-danger">Unverified</p>
      case 1:
        return <p className="text-success">Approved</p>
      case 2:
        return <p className="text-warning">Verified</p>
      default:
        return <p className="text-danger">Unverified</p>
    }
  }

  return (
    <>
      <NavBar />
      <Alert_
        status={alertToggle.isVisible}
        onClose={handleAlertClose}
        color={alertToggle.color}
      />

      {/* ------------------- Alert --------------------
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar> */}

<div name="Delete parent node">
          <Dialog
            open={openDeleteVehicle} 
            onClose={() => handleCloseDeleteVehicle()}
          >
            <form action="" method="post">
              <DialogTitle>Delete vehicle</DialogTitle>

              <DialogContent style={{}}>
                <div>
                  <p>Are you sure you want to delete this vehicle?</p>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCloseDeleteVehicle()}
                >
                  Close
                </Button>

                {isDeleting ? (
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                ) :  (
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="error"
                    onClick={() => handleSubmitVehicleDelete(
                      selectedForDelete.id
                    )}
                  >
                    Delete
                  </Button>
                )}
              </DialogActions>
            </form>
          </Dialog>
        </div>

      <div className="main-content main-content-margin-top">
        {/* Vehicle Modal */}
        <div name="View Vehicle Modal Parent Node">
          <Dialog
            open={openViewVehicle}
            onClose={() => handleCloseViewVehicle()}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>Vehicle Details</DialogTitle>
            <DialogContent>
              <div>
                <p className="mb-3 fs-6">
                  <b>Vehicle Name:</b> {vehicleDetails?.vehicleBrand?.name + " " + vehicleDetails?.model?.name}
                </p>
                <p className="mb-3 fs-6">
                  <b>Vehicle Number:</b> {vehicleDetails?.regNo}
                </p>
                <p className="mb-3 fs-6">
                  <b>Vehicle Type:</b> {vehicleDetails?.vehicleType?.name}
                </p>
                <p className="mb-3 fs-6">
                  <b>Vehicle Size:</b> {vehicleDetails?.vehicleSize?.name}
                </p>
                <p className="mb-3 fs-6">
                  <b>Status</b> {setStatus(vehicleDetails?.status)}
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleCloseViewVehicle}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Add Vehicle Modal Parent Node">
          <Dialog open={openAddVehicle} onClose={() => handleCloseAddVehicle()}>
            <DialogTitle>Add Vehicle</DialogTitle>
            {vTypeIsLoading &&
            vehicleSizeIsLoading &&
            modelIsLoading &&
            categoryIsLoading &&
            brandIsLoading ? (
              <div style={{ margin: "20px auto" }}>
                <CircularProgress />
              </div>
            ) : (
              <DialogContent style={{}}>
                <div>
                  <FormControl
                    style={{ marginTop: "10px" }}
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small">Vehicle Type</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Vehicle Type"
                      name="TypeId"
                      value={vehicleData.TypeId}
                      onChange={(e) => handleAddVehicleType(e)}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}

                      {vehicleTypeData?.map((vtype) => (
                        <MenuItem value={vtype?.id}>{vtype?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    style={{ marginTop: "10px" }}
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small">Brand</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Brand"
                      name="BrandId"
                      value={vehicleData.BrandId}
                      onChange={(e) => handleAddVehicleBrand(e)}
                    >
                      {vehicleBrandData?.map((brand) => (
                        <MenuItem value={brand?.id}>{brand?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    style={{ marginTop: "10px" }}
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small">Model</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Model"
                      name="ModelId"
                      value={vehicleData.ModelId}
                      onChange={(e) => handleAddVehicleModel(e)}
                    >
                      {/* .map((model) => <MenuItem value={model?.id}>{model?.name}</MenuItem> )} */}
                      {vehicleModelData
                        ?.filter(
                          (model_filter) =>
                            model_filter.brandId === vehicleData.BrandId
                        )
                        .map((model) => (
                          <MenuItem value={model?.id}>{model?.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    style={{ marginTop: "10px" }}
                    sx={{ width: "100%" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small"></InputLabel>
                    
                    <TextField
                      fullWidth
                      labelId="demo-select-small"
                      label="Vehicle Year"
                      id="demo-select-small"
                      name="Year"
                      size="small"
                      type="number"
                      value={vehicleData?.Year}
                      onChange={(e) => handleAddVehicleChange(e)}
                    />
                    {/* <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Vehicle Year"
                      name="Year"
                      value={vehicleData.Year}
                      onChange={(e) => handleAddVehicleChange(e)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={2010}>2010</MenuItem>
                      <MenuItem value={2011}>2011</MenuItem>
                      <MenuItem value={2012}>2012</MenuItem>
                    </Select> */}
                  </FormControl>

                  <Box
                    style={{ marginTop: "20px" }}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Vehicle Registration Number"
                      id="fullWidth"
                      name="RegNo"
                      size="small"
                      value={vehicleData?.RegNo}
                      onChange={(e) => handleAddVehicleChange(e)}
                    />
                  </Box>

                  <Box
                    style={{ marginTop: "20px" }}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Vehicle Capacity"
                      id="fullWidth"
                      name="Capacity"
                      size="small"
                      value={vehicleData?.Capacity}
                      onChange={(e) => handleAddVehicleChange(e)}
                    />
                  </Box>

                  
                </div>
              </DialogContent>
            )}

            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={() => handleCloseAddVehicle()}
              >
                Close
              </Button>

              {isAdding ? (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700", border:'none' }}
                  variant="outlined"
                  
                  color="primary"
                  
                >
                  <CircularProgress/>
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddVehicleNext()}
                >
                  Next
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Add vehicle Docs parent node">
        <Dialog open={openAddVehicleDocs} onClose={() => handleCloseAddVehicle()}>
            <DialogTitle>Upload Vehicle Documents</DialogTitle>
            
              
              <DialogContent style={{}}>
                <div>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Certificate of road worthiness</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleAddVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Vehicle registration</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleAddVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Vehicle Insurance</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleAddVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Hackney permit</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleAddVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Change of ownership</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleAddVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>
                </div>
              </DialogContent>
            

            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={() => handleCloseAddVehicleDocs()}
              >
                Close
              </Button>

              {isAdding ? (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700", border:'none' }}
                  variant="outlined"
                  
                  color="primary"
                  
                >
                  <CircularProgress/>
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmitAddVehicle()}
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Edit Vehicle Modal Parent Node">
          <Dialog
            open={openEditVehicle}
            onClose={() => handleCloseEditVehicle()}
          >
            <DialogTitle>Edit Vehicle Details</DialogTitle>
            <DialogContent>
              {editViewOne === true
              ? <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Vehicle Brand"
                    id="fullWidth"
                    name="Brand"
                    defaultValue={vehicleEdit?.vehicleBrand?.name}
                    onChange={handleVehicleEditInputChange}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Vehicle Model"
                    id="fullWidth"
                    name="Model"
                    defaultValue={vehicleEdit?.model?.name}
                    onChange={handleVehicleEditInputChange}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Registration Number"
                    id="fullWidth"
                    name="RegNo"
                    defaultValue={vehicleEdit?.regNo}
                    onChange={handleVehicleEditInputChange}
                  />
                </Box>
                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Vehicle Type"
                    id="fullWidth"
                    name="Type"
                    value={vehicleEdit?.vehicleType?.name}
                    onChange={handleVehicleEditInputChange}
                  />
                </Box>
                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Year"
                    id="fullWidth"
                    name="Year"
                    defaultValue={vehicleEdit?.year}
                    onChange={handleVehicleEditInputChange}
                  />
                </Box>
                <DialogActions>
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="outlined"
                    color="error"
                    onClick={handleCloseEditVehicle}
                  >
                    Close
                  </Button>

                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleEditDocsList()}
                    // onClick={() => handleSubmitEditVehicle()}
                  >
                    Next
                  </Button>
                </DialogActions>
              </div>
              : null}
              {editViewTwo === true
              ? <div>

              <InputContainer style={{ marginTop: "20px" }}>
                <p> Certificate of road worthiness</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  {/* onChange={(e)=> handleImageInput(e)} */}
                  <input
                    accept="image/*"
                    type="file"
                    name="Picture1Url"
                    onChange={(e) => handleEditVehicleDocs(e)}
                  />
                </IconButton>
              </InputContainer>

              <InputContainer style={{ marginTop: "20px" }}>
                <p> Vehicle registration</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    accept="image/*"
                    type="file"
                    name="Picture2Url"
                    onChange={(e) => handleEditVehicleDocs(e)}
                  />
                </IconButton>
              </InputContainer>

              <InputContainer style={{ marginTop: "20px" }}>
                <p> Vehicle Insurance</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    accept="image/*"
                    type="file"
                    name="Picture3Url"
                    onChange={(e) => handleEditVehicleDocs(e)}
                  />
                </IconButton>
              </InputContainer>

              <InputContainer style={{ marginTop: "20px" }}>
                <p> Hackney permit</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  {/* onChange={(e)=> handleImageInput(e)} */}
                  <input
                    accept="image/*"
                    type="file"
                    name="Picture4Url"
                    onChange={(e) => handleEditVehicleDocs(e)}
                  />
                </IconButton>
              </InputContainer>

              <InputContainer style={{ marginTop: "20px" }}>
                <p> Change of ownership</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  {/* onChange={(e)=> handleImageInput(e)} */}
                  <input
                    accept="image/*"
                    type="file"
                    name="VehicleDocuments"
                    onChange={(e) => handleEditVehicleDocs(e)}
                  />
                </IconButton>
              </InputContainer>
              <DialogActions>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={() => handleCloseEditVehicle()}
                >
                  Close
                </Button>

                {isAdding ? (
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700", border:'none' }}
                    variant="outlined"
                    
                    color="primary"
                    
                  >
                    <CircularProgress/>
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitEditVehicle()}
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
              </div>
              : null}
            </DialogContent>
          </Dialog>
          
        </div>
        <div name="Edit vehicle Docs parent node">
        <Dialog open={openAddVehicleDocs} onClose={() => handleCloseAddVehicle()}>
            <DialogTitle>Upload Vehicle Documents</DialogTitle>
            
              
              <DialogContent style={{}}>
                <div>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Certificate of road worthiness</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="Picture1Url"
                        onChange={(e) => handleEditVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Vehicle registration</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="Picture2Url"
                        onChange={(e) => handleEditVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Vehicle Insurance</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="Picture3Url"
                        onChange={(e) => handleEditVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Hackney permit</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="Picture4Url"
                        onChange={(e) => handleEditVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Change of ownership</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="VehicleDocuments"
                        onChange={(e) => handleEditVehicleDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>
                </div>
              </DialogContent>
            

            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={() => handleCloseAddVehicleDocs()}
              >
                Close
              </Button>

              {isAdding ? (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700", border:'none' }}
                  variant="outlined"
                  
                  color="primary"
                  
                >
                  <CircularProgress/>
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmitAddVehicle()}
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        {/* Vehicle modal ends */}

        <VehiclesTableContainer>
          <p style={{ fontSize: "1.8em", marginLeft: "10px" }}>
          <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i> Vehicles</p>

          <AddNew>
            {/* {
              vTypeIsLoading && vehicleSizeIsLoading && modelIsLoading && categoryIsLoading && brandIsLoading 
              ?
              <Button variant="outlined">
                <CircularProgress color="inherit" />
              </Button>
              :
              <Button variant="outlined" startIcon={<AddIcon />} onClick={()=> handleOpenAddVehicle()}>
              Add Vehicle
            </Button>
            } */}

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleOpenAddVehicle()}
            >
              Add Vehicle
            </Button>
          </AddNew>
          <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Vehicle Make</th>
                  <th>Vehicle Model</th>
                  <th>Registration Number</th>
                  <th>Vehicle Type</th>
                  
                </tr>
              </thead>
              {
                !isLoading &&
              
              <tbody>
                {data?.filter(
                    (vehicle) =>
                      vehicle?.vehicleBrand?.name
                        ?.toLowerCase()
                        .includes(query.toLowerCase()) ||
                      vehicle?.regNo
                        ?.toLowerCase()
                        .includes(query.toLowerCase()) ||
                      vehicle?.vehicleType?.name
                        ?.toLowerCase()
                        .includes(query.toLowerCase())
                  )
                  ?.map((row_, Key) => {
                    return (
                      <tr>
                        <td>{Key + 1}</td>
                        <td>{row_?.vehicleBrand.name}</td>
                        <td>{row_?.model.name}</td>
                        <td>{row_?.regNo}</td>
                        <td>{row_?.vehicleType.name}</td>
                        
                        <td>
                          <Flex>
                            {" "}
                            <div
                              // onClick={() => handleOpenEditVehicle(row_)}
                              onClick={() => selectVehicleEdit(row_)}
                              className="green"
                            >
                              <EditIcon />
                            </div>
                            <div onClick={()=> selectForDelete(row_)} className="red">
                              <DeleteIcon />
                            </div>
                            <div
                              onClick={() => handleOpenViewVehicle(row_)}
                              className="blue"
                            >
                              <VisibilityIcon />
                            </div>
                          </Flex>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
}
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </VehiclesTableContainer>
      </div>
    </>
  );
};
{
  /* <td><button onClick={()=> alert(row_.vehicleNum)}>view</button></td> */
}
export default PartnerVehiclePage;
