import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    priceData: [],
    isCalculating: false,
    status: null
}

const token = userObj?.token

export const calculatePrice = createAsyncThunk(
    'order/calculatePrice',
    async (data, {rejectWithValue}) => {
        console.log(data)
        try {
            const response = await axios.post("Order/calculatePayment", data, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            })
            return response?.data
        }
        catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
    }
)

const calculatePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {},
    extraReducers: {
        [calculatePrice.pending]: (state, action) => {
            state.status = "pending";
            state.isCalculating = true;
        },
        [calculatePrice.fulfilled]: (state, action) => {
            state.status = "success";
            state.isCalculating = false;
            state.priceData = action.payload;
        },
        [calculatePrice.rejected]: (state, action) => {
            state.status = "failed";
            state.isCalculating = false;
        }
    }
})

export default calculatePriceSlice.reducer;
