import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import {
  Table,
  TableWrapper,
  TableContainer,
} from "../../../components/Table/table.styled";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import NavBar from "../../../components/navbar/navbar.component";
import { Heading } from "../../../components/Tags/tags.styled";
import { userObj } from "../../../helper/protectedRoutes";
import { getRcs } from "../../../app/features/Customer/Dss/getAllRcsSlice";

const RCSPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const [shipments, setShipments] = React.useState();
  const { isLoading } = useSelector((state) => state.getAllRcs);

  useEffect(() => {
    document.title = "Daily shipments - Customer";
    dispatch(getRcs(user.id)).then((res) => {
      const { payload } = res;
      setShipments(payload);
      console.log("shipments", payload);
    });
  }, []);

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <TableContainer>
          <Heading size="1.8em">RCS</Heading>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>RSC No.</th>
                  <th>Generated Date</th>
                  {/* <th>Est. Distance</th> */}
                  {/* <th>Discharge Time</th> */}

                  {/* <th>Est. Delivery Date</th> */}
                  <th>Driver</th>
                  <th>Driver Discharged</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {shipments
                    // ?.filter((s) =>
                    //   s.no?.toLowerCase().includes(query?.toLowerCase())
                    // )
                    ?.map((row_, Key) => {
                      return (
                        <tr>
                          <td style={{ width: "10px" }}>{row_.no}</td>
                          <td>{new Date(row_.date).toLocaleDateString()}</td>

                          {/* <td>
                            {row_.dischargedConfirmedByDriver
                              ? new Date(row_.dischargeTime).toLocaleTimeString()
                              : "N/A"}
                          </td> */}
                          <td>
                            {row_.driver?.firstName +
                              " " +
                              row_.driver?.lastName}
                          </td>
                          <td>
                            <Checkbox
                              disabled
                              size="small"
                              checked={row_.dischargedConfirmedByDriver}
                            />
                          </td>

                          <td>
                            {row_?.status === 0 && (
                              <span style={{ color: "#f9c851" }}>pending</span>
                            )}
                            {row_?.status === 1 && (
                              <span style={{ color: "#188ae2" }}>accepted</span>
                            )}
                            {row_?.status === 2 && (
                              <span style={{ color: "#ff6666" }}>
                                awaiting pickup
                              </span>
                            )}
                            {row_?.status === 3 && (
                              <span style={{ color: "#666699" }}>arrived</span>
                            )}
                            {row_?.status === 4 && (
                              <span style={{ color: "#f9c851" }}>
                                awaiting rider code
                              </span>
                            )}
                            {row_?.status === 5 && (
                              <span style={{ color: "#bf4080" }}>
                                In Transit
                              </span>
                            )}
                            {row_?.status === 10 && (
                              <span style={{ color: "#10c469" }}>
                                Completed
                              </span>
                            )}
                            {row_?.status === 11 && (
                              <span style={{ color: "#ff5b5b" }}>
                                cancelled
                              </span>
                            )}
                          </td>
                          <td>
                            <Link to={`/dashboard/customer/rcs/${row_.id}`}>
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {isLoading && (
              <Button style={{ width: "100%", marginTop: "20px" }}>
                <CircularProgress />
              </Button>
            )}
          </TableWrapper>
        </TableContainer>
      </div>
    </>
  );
};

export default RCSPage;
