import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
  data: [],
  isLoading2: null,
  status: null,
  error: null
}

const token = userObj?.token

export const getOrderAssign = createAsyncThunk(
  'partner/getOrderAssign',
  async (o, {rejectWithValue}) => {
    const { orderId, riderId } = o
    try {
      const response = await axios.get(`Partner/orders/${orderId}/assign/${riderId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response?.data
    } catch (err) {
        return rejectWithValue(err.response.data.title)
    }
  }
)

const getOrderAssignSlice = createSlice({
  name: 'getOrderAssign',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderAssign.pending]: (state) => {
      state.status = 'pending';
      state.isLoading2 = true;
    },

    [getOrderAssign.fulfilled]: (state, action) => {
      state.status = 'success';
      state.isLoading2 = false;
      state.data = action.payload;
    },

    [getOrderAssign.rejected]: (state, action) => {
      state.status = 'failed';
      state.isLoading2 = false;
      state.error = action.error
    }
  }
})

export default getOrderAssignSlice.reducer;