import styled from "styled-components";

export const Waybill = styled.div`
  width: 100%;
  max-width: 1500px;
  background-color: #FFFFFF;
  border: 2px solid rgba(0,0,0,0.4);
  margin: 10px auto;
  padding: 20px;
`

export const Dt = styled.dt`
  width: 40%;
  text-align: right;
  float:left;
  font-size: 16px;
`

export const Dd = styled.dd`
  padding-left: 20px;
  width: 60%;
  float: right;
  font-size: 16px;
  margin-bottom: 0 !important;
`