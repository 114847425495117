import styled from "styled-components";
import { ChartWidgetContainer } from "../../../components/widgets/chart-widget/chart_widget.styled";


export const CustomerActionsDivFlex = styled(ChartWidgetContainer)`
    display:flex;
    flex-direction:column;
    height: 100%;
    width: 100%;
    background:none;
    height: 450px;
    box-shadow:none;
    padding:0;

    @media screen and (max-width: 768px){
        width: 95%
    }
`

export const CustomerActionsDiv = styled.div`
    height: auto;
    width: 100%;
    background: red;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 2px #ccc;
    box-shadow: 0 1px 2px #ccc;
    padding:10px;
    border-radius: 10px;
`


export const WalletBalances = styled.div`
    margin-bottom:10px;
`

export const FlexEnd = styled.div`
    display:flex;
    justify-content:flex-end;
`
export const FlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
`