import React from "react";
import { Heading } from "../../Tags/tags.styled";
import { ChartWidgetContainer } from "./chart_widget.styled";


const ChartWidget = (props) => {
  
  return (
    <>
    <ChartWidgetContainer>
      <Heading>{props.heading}</Heading>
    {/* <p style={{fontSize:'1.8em', marginLeft:'10px'}}></p> */}
    <hr/>
    {props.content}
    </ChartWidgetContainer>
    </>
  );
};

export default ChartWidget;

