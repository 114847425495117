import React, { useEffect } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate } from "react-router-dom";
import { Flex } from "../../components/Table/table.styled";
import {useDispatch, useSelector} from 'react-redux';
import { customerRegister } from "../../app/features/Customer/registerSlice";
import { useState } from "react";
import Loader from "../../components/Loader/loader";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';


const initialValue = {
    "firstName": "",
  "lastName": "",
  "companyName":"",
  "address":"",
  "phoneNumber": "",
  "email": "",
  "password": "",
  "confirmPassword": "",
}

const RegisterCustomer = () => {
    const [registerData, setRegisterData] = useState(initialValue)
    const [registerError, setRegisterError] = useState({
        display:'none',
        message:''
    })

    

    const {data, isLoading, status} = useSelector((state) => state.customerRegister)
    

    useEffect(()=>{
        document.title = "RedSpeed - Customer Sign up"
    },[])

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleSubmit = (event)=> {
        event.preventDefault()
        try{
            dispatch(customerRegister(registerData)).then((res)=>{
                let {payload, error} = res
                {
                    error ?
                    setRegisterError({
                        ...registerError,
                        display:'block',
                        message:payload
                    })
                    :
                    localStorage.setItem('register-data', JSON.stringify(registerData))
                    navigate(`/verify-email?email=${registerData.email}&phone=${registerData.phoneNumber}`)

                }
               
            })
        } catch(err){
            
        }

    }

    const handleInputChange = (e)=> {
        const {name, value} = e.target
        setRegisterData({
            ...registerData,
            [name]:value
        })
    }

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Customer Sign Up</h2>
                    <div style={{marginBottom:'20px', display:`${registerError.display}`}}>
                    <Alert severity="error">{registerError.message}</Alert>
                    </div>
                    <p className="margin-top-minus-5 margin-bottom-20">Fill in the details below to setup your account</p>
                    <form action="" method="post" onSubmit={handleSubmit}>
                        <Flex>
                        <InputContainer>
                        
                        <Input type="text" onChange={(e)=> handleInputChange(e)} placeholder="First Name" name="firstName" />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="text" onChange={(e)=> handleInputChange(e)} placeholder="Last Name" name="lastName" />
                        </InputContainer>
                        </Flex>

                        <Flex>
                        <InputContainer>
                        
                        <Input type="text" onChange={(e)=> handleInputChange(e)} placeholder="Company name" name="companyName" />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="text" onChange={(e)=> handleInputChange(e)} placeholder="Address" name="address" />
                        </InputContainer>
                        </Flex>

                        <Flex>
                        <InputContainer>
                        
                        <Input type="text" onChange={(e)=> handleInputChange(e)} placeholder="Phone Number" name="phoneNumber"  />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="email" onChange={(e)=> handleInputChange(e)} placeholder="Email address" name="email"  />
                        </InputContainer>
                        </Flex>

                        <Flex>
                        <InputContainer>
                        

                        <Input type="password" onChange={(e)=> handleInputChange(e)} placeholder="Password" name="password"  />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="password" onChange={(e)=> handleInputChange(e)} placeholder="Confirm password" name="confirmPassword"  />
                        </InputContainer>
                        </Flex>

                        <Remember>
                            <Label><CheckBox type='checkbox' name="" required='true'/> By continuing, you're agreeing to our customer terms of service, privacy policy and cookie policy</Label>
                        </Remember>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button type='submit' style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Next </Button>
                        }
                        
                        
                        </InputContainer>


                        <InputContainer>
                        <p style={{textAlign:'center'}}>Already have an account? <Link to='/login'>Login</Link></p>
                        </InputContainer>
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default RegisterCustomer;