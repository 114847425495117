import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isAssigningRider:false,
    status:null,
    error:null
}

const token = userObj?.token


// headers: {
//     Authorization: 'Bearer ' + varToken
//   }

export const assignRider = createAsyncThunk(
    'partner/assignRider',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.post("Partner/assignRider", data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            // console.log(err.response.data.title)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const assignRiderSlice = createSlice({
    name: 'assignRider',
    initialState,
    reducers: {},
    extraReducers:{
        [assignRider.pending]: (state, action) => {
            state.status='pending';
            state.isAssigningRider=true;
        },
        [assignRider.fulfilled]: (state, action) => {
            state.status='success';
            state.isAssigningRider=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [assignRider.rejected]: (state, action) => {
            state.status='failed';
            state.isAssigningRider=false;
            state.error = action.error
        }
    }
})

export default assignRiderSlice.reducer;