import React, { useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate } from "react-router-dom";

const BankInformation = () => {
    const [bankInfo, setBankInfo] = useState()
    

    const navigate = useNavigate()

    const handleChange = (e) => {
        const {name, value} = e.target
        setBankInfo({
          ...bankInfo,
          [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        localStorage.setItem('bankInfo', JSON.stringify(bankInfo))
        navigate('/document-upload')
    }

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Bank Information</h2>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Enter your bank details below to complete Sign up
                    </p>
                    <form action="" onSubmit={(e)=> handleSubmit(e)}>
                        <InputContainer>
                        
                        <Input type="text" placeholder="Bank name" name="BankAccount.BankName"  onChange={(e)=> handleChange(e)} />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="text" placeholder="Account Number" name="BankAccount.AccountNumber" onChange={(e)=> handleChange(e)} />
                        </InputContainer>

                        <InputContainer>
                        
                        <Input type="text" placeholder="Bank Account Name" name="BankAccount.AccountName" onChange={(e)=> handleChange(e)} />
                        </InputContainer>

                        <InputContainer>
                        <Button type='submit'>Next</Button>
                        </InputContainer>
                        <InputContainer>
                        <p><Link to='/login'> Back to login</Link> </p>
                        </InputContainer>
                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default BankInformation;