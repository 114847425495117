import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import Widget from "../../../components/widgets/widget.component";
import { WidgetFlex } from "../../Partner-Pages/Dashboard/dashboard.styled";
import { Avatar, Box, CircularProgress, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Flex,
  Table,
  TableWrapper,
  VehiclesTableContainer,
} from "../../Partner-Pages/Vehicle/vehicles.styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ChartWidgetContainer } from "../../../components/widgets/chart-widget/chart_widget.styled";
import {
  CustomerActionsDiv,
  CustomerActionsDivFlex,
  FlexEnd,
  WalletBalances,
} from "./dashboard.styled";
import { Input, InputContainer, Button as Btn } from "../../Auth/auth.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import GoogMap from "../../../helper/googlemap/map.google";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../app/features/Customer/Order/allOrdersSlice";
import convertDate from "../../../helper/dateCoverter";
import {
  closeOrder,
  openOrder,
} from "../../../app/features/createOrderModal/createordermodalSlice";
import CreateOrderModal from "../../../components/createOrder/createOrderModal.component";
import { getWallet } from "../../../app/features/Customer/wallet/getWalletSlice";
import { toast } from "react-toastify";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    background: "transparent !important",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    padding: "5px",
  },
});

const DashboardCustomer = () => {
  const dollarUSLocale = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [orderdata, setOrderData] = React.useState([]);
  const [openAssignDriver, setOpenAssignDriver] = React.useState(false);
  const [openTrackOrderModal, setOpenTrackOrderModal] = React.useState(false);
  const [alertToggle, setAlertToggle] = React.useState(false);
  const [assignDriverProperty, setAssignDriverProperty] = React.useState({
    driver: "",
    vehicle: "",
  });
  const [userData, setUserData] = React.useState();
  const [userLocation, setUserLocation] = React.useState(null);
  const [trackNo, setTrackNo] = React.useState("");

  useEffect(() => {
    document.title = "Dashboard - customer";
    dispatch(getAllOrders()).then((res) => {
      const { payload, error } = res;
      {
        if (!error) {
          setOrderData(payload?.slice(0, 4));
        }
      }
    });
    dispatch(getWallet());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("position", position);
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  const userObj = JSON.parse(localStorage.getItem("user"));
  const userWalletObj = userObj?.extra.wallet;

  const { isLoading } = useSelector((state) => state.getAllOrders);
  const { wallet } = useSelector((state) => state.getWallet);

  const { isCreateOrderModalOpened } = useSelector(
    (state) => state.createordermodal
  );

  const handleAssignDriverChange = (event) => {
    const { name, value } = event.target;
    setAssignDriverProperty({
      ...assignDriverProperty,
      [name]: value,
    });
  };
  const classes = useStyles();

  //   Book Ride Modal
  // Set Order Location Modal
  const handleOpenOrderLocation = () => {
    dispatch(openOrder());
    handleCloseOrderType();
  };

  const [openOrderType, setOpenOrderType] = React.useState(false);

  const handleOpenOrderType = () => {
    setOpenOrderType(true);
  };
  const handleCloseOrderType = () => {
    setOpenOrderType(false);
  };

  // const handleCloseOrderLocation = () => {
  //   setOrderLocationDetails(false);
  // };

  //  Book ride ends

  const handleTrackOrderModalOpen = () => {
    setOpenTrackOrderModal(true);
  };

  const handleTrackOrderModalClose = () => {
    setOpenTrackOrderModal(false);
  };

  const handleClickOpen = () => {
    setOpenAssignDriver(true);
  };

  const handleClose = () => {
    setOpenAssignDriver(false);
  };

  // Assign Driver
  const AssignDriver = () => {
    handleAlertOpen();
    setOpenAssignDriver(false);
  };

  // Alert Settings
  const handleAlertOpen = () => {
    setAlertToggle(true);
  };

  const handleAlertClose = () => {
    setAlertToggle(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTrackNo(value);
  };

  const handleTrackOrder = (e) => {
    if (trackNo !== "") {
      navigate(`/dashboard/customer/order-tracking/${trackNo}`);
    } else {
      toast.warning("Please input the order number to track");
    }
  };

  // console.log(convertDate('2022-09-21T12:56:53.3739294'))

  return (
    <>
      <NavBar />

      {/*------------------- Alert --------------------*/}
      <Snackbar
        open={alertToggle}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar>

      {/*  Action Modal */}

      <div name="Assign Driver Modal Parent Node">
        <Dialog open={openAssignDriver} onClose={handleClose}>
          <DialogTitle>Assign Driver</DialogTitle>
          <DialogContent>
            <div>
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select driver
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.driver}
                  name="driver"
                  label="Select driver"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"John Doe"}>John Doe</MenuItem>
                  <MenuItem value={"Hidora Kai"}>Hidora Kai</MenuItem>
                  <MenuItem value={"Machala"}>Machala</MenuItem>
                </Select>
                <FormHelperText>select a driver to add</FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Vehicle
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.vehicle}
                  name="vehicle"
                  label="Select Vehicle"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Lamborghini"}>Lamborghini</MenuItem>
                  <MenuItem value={"Napep"}>Napep</MenuItem>
                  <MenuItem value={"Truck"}>Mercedez Truck</MenuItem>
                </Select>
                <FormHelperText>
                  choose one vehicle from the list of your vehicle list
                </FormHelperText>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize" }}
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={() => AssignDriver()}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div name="Add Vehicle Modal Parent Node">
        <Dialog open={openTrackOrderModal} onClose={handleTrackOrderModalClose}>
          <DialogTitle>Track Order</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={handleTrackOrderModalClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div name="Order Type">
        <Dialog open={openOrderType} onClose={handleCloseOrderType}>
          <Box
            style={{ marginTop: "10px" }}
            sx={{
              maxWidth: "auto",
              display: "flex",
            }}
          >
            <Button style={{}} onClick={handleCloseOrderType} color="error">
              <KeyboardBackspaceIcon />
            </Button>
            <DialogTitle style={{ paddingLeft: "0" }}>
              Select Order Type
            </DialogTitle>
          </Box>
          <DialogContent>
            <Box>
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
                variant="outlined"
                color="error"
                sx={{ width: "100%" }}
                onClick={() => handleOpenOrderLocation()}
              >
                Express (Same day delivery)
              </Button>
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
                variant="outlined"
                color="error"
                sx={{ width: "100%" }}
                onClick={() =>
                  navigate("/dashboard/customer/orders/package-schedule")
                }
              >
                Scheduled Delivery
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>

      <CreateOrderModal />

      {/* Quick Acion Modal Ends */}

      <div className="main-content main-content-margin-top">
        {/* Chart Section */}
        <WidgetFlex>
          <ChartWidgetContainer>
            <GoogMap userLocation={userLocation} zoom={80} />
          </ChartWidgetContainer>

          <CustomerActionsDivFlex>
            <CustomerActionsDiv style={{ height: "auto", marginTop: 5 }}>
              <InputContainer>
                <span>Track a delivery </span>
                <Input
                  type="text"
                  name="OrderNo"
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Input waybill number"
                  // style={{textTransform:'uppercase'}}
                />
              </InputContainer>
              <FlexEnd>
                <Button
                  onClick={handleTrackOrder}
                  style={{ textTransform: "capitalize", fontSize: "small" }}
                  variant="outlined"
                >
                  TRACK
                </Button>
              </FlexEnd>
            </CustomerActionsDiv>

            <CustomerActionsDiv style={{ height: "auto", marginTop: 5 }}>
              <WalletBalances>
                <p>Current Balanace</p>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  &#8358;{dollarUSLocale.format(wallet?.currentBalance)}
                </p>
              </WalletBalances>
              <WalletBalances>
                <p>Ledger Balanace</p>
                <p style={{ fontWeight: "bold", fontSize: "17px" }}>
                  &#8358;{dollarUSLocale.format(wallet?.ledgerBalance)}
                </p>
              </WalletBalances>
              <FlexEnd>
                <Link to="/wallet">
                  <Button variant="outlined">Top up card</Button>
                </Link>
              </FlexEnd>
            </CustomerActionsDiv>

            <CustomerActionsDiv style={{ height: "auto" }}>
              <Flex style={{ justifyContent: "space-evenly" }}>
                <Button onClick={handleOpenOrderType}>Create new order</Button>
                <Button
                  onClick={() => navigate("/dashboard/customer/vehicles-types")}
                >
                  Vehicles
                </Button>
              </Flex>
            </CustomerActionsDiv>
          </CustomerActionsDivFlex>
        </WidgetFlex>

        <VehiclesTableContainer>
          <Heading>Recent Orders</Heading>

          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order Number</th>
                  <th>Pick-up</th>
                  <th>Order Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {orderdata?.map((row_, Key) => {
                    return (
                      <tr>
                        <td>{Key + 1}</td>
                        <td>{row_.orderNo}</td>
                        <td style={{ width: "300px" }}>{row_.pickup}</td>
                        <td style={{ width: "200px" }}>
                          {row_.orderPrice === null
                            ? "N/A"
                            : row_?.status !== 10
                            ?
                              "₦" +
                              dollarUSLocale.format(row_?.orderPrice?.maxValue)
                            : "₦" +
                              dollarUSLocale.format(
                                row_?.orderPrice?.actualValue
                              )}
                        </td>
                        <td style={{ width: "180px" }}>
                          {row_?.status === 0 && (
                            <span style={{ color: "#f9c851" }}>pending</span>
                          )}
                          {row_?.status === 1 && (
                            <span style={{ color: "#188ae2" }}>accepted</span>
                          )}
                          {row_?.status === 2 && (
                            <span style={{ color: "#ff6666" }}>
                              awaiting pickup
                            </span>
                          )}
                          {row_?.status === 3 && (
                            <span style={{ color: "#666699" }}>arrived</span>
                          )}
                          {row_?.status === 4 && (
                            <span style={{ color: "#f9c851" }}>
                              awaiting rider code
                            </span>
                          )}
                          {row_?.status === 5 && (
                            <span style={{ color: "#bf4080" }}>In Transit</span>
                          )}
                          {row_?.status === 10 && (
                            <span style={{ color: "#10c469" }}>Completed</span>
                          )}
                          {row_?.status === 11 && (
                            <span style={{ color: "#ff5b5b" }}>cancelled</span>
                          )}
                        </td>

                        <td>
                          {row_.destinationPoints.length > 0 ? (
                            <Link
                              className="unstyle-href"
                              to={`/track-order/${row_?.pickup}/${row_?.id}`}
                            >
                              <Button
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "small",
                                }}
                                variant="outlined"
                              >
                                Track Order
                              </Button>
                            </Link>
                          ) : (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/dashboard/customer/order-tracking/${row_.orderNo}`
                                )
                              }
                              style={{
                                textTransform: "capitalize",
                                fontSize: "small",
                              }}
                              variant="outlined"
                            >
                              Track Order
                            </Button>
                          )}
                        </td>
                        {/* <td><button onClick={()=> alert(row_.vehicleNum)}>view</button></td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {isLoading && (
              <Button style={{ width: "100%", marginTop: "20px" }}>
                <CircularProgress />
              </Button>
            )}
          </TableWrapper>
        </VehiclesTableContainer>
      </div>
    </>
  );
};

export default DashboardCustomer;
