import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from "../../../../helper/api";
import axios from "../../../../api/axios";

const initialState = {
  data: [],
  isLoading: null,
  status: null,
};

const token = userObj?.token;

export const getDailyShipments = createAsyncThunk(
  "order/getDailyShipments",
  async ({id, start, end}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/Order/dailyshipment/${id}?StartDate=${start}&EndDate=${end}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.shipments;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response.data.title);
    }
  }
);

const getDailyShipmentsSlice = createSlice({
  name: "getDailyShipments",
  initialState,
  reducers: {},
  extraReducers: {
    [getDailyShipments.pending]: (state, action) => {
      state.status = "pending";
      state.isLoading = true;
    },
    [getDailyShipments.fulfilled]: (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.data = action.payload;
      // localStorage.setItem('user', JSON.stringify(action.payload))
    },

    [getDailyShipments.rejected]: (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    },
  },
});

export default getDailyShipmentsSlice.reducer;
