import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getAllOrders = createAsyncThunk(
    'partner/getAllOrders',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Order/all",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getAllOrdersSlice = createSlice({
    name: 'getAllOrders',
    initialState,
    reducers: {},
    extraReducers:{
        [getAllOrders.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getAllOrders.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getAllOrders.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default getAllOrdersSlice.reducer;