import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../helper/protectedRoutes";
import axios from "axios";
import baseUrl from '../../helper/api';

const initialState = {
    data:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getUser = createAsyncThunk(
    'account/user',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Account/user`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const getUserAPIKeys = createAsyncThunk(
    'account/userAPIKeys',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Account/userAPIKeys`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const generateAPIKey = createAsyncThunk(
    'account/generateAPIKey',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Account/generateAPIKey`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)
export const removeUserAPIKey = createAsyncThunk(
    'account/removeUserAPIKey',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.delete(`account/removeUserAPIKey/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


export const updatePartner = createAsyncThunk(
    'account/updatePartner',
    async (data, {rejectWithValue}) => {
        console.log(data)
        try{
            const response = await axios.put(`${baseUrl}account/updatePartner`, data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const updateCustomer = createAsyncThunk(
    'account/updateCustomer',
    async (data, {rejectWithValue}) => {
        console.log(data)
        try{
            const response = await axios.put(`${baseUrl}/account/updateCustomer`, data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


export const removeKeySlice = createSlice({
    name: 'removeUserAPIKey',
    initialState,
    reducers: {},
    extraReducers:{
        [removeUserAPIKey.pending]: (state, action) => {
            state.status='pending';
            state.isDeleting=true;
        },
        [removeUserAPIKey.fulfilled]: (state, action) => {
            state.status='success';
            state.isDeleting=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [removeUserAPIKey.rejected]: (state, action) => {
            state.status='failed';
            state.isDeleting=false;
        }
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers:{
        [getUser.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getUser.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getUser.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export const userAPIKeysSlice = createSlice({
    name: 'userAPIKeys',
    initialState,
    reducers: {},
    extraReducers:{
        [getUserAPIKeys.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getUserAPIKeys.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getUserAPIKeys.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export const userAPIKeySlice = createSlice({
    name: 'generateAPIKey',
    initialState,
    reducers: {},
    extraReducers:{
        [generateAPIKey.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [generateAPIKey.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [generateAPIKey.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export const updatePartnerSlice = createSlice({
    name: 'updatePartner',
    initialState,
    reducers: {},
    extraReducers:{
        [updatePartner.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [updatePartner.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [updatePartner.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export const updateCustomerSlice = createSlice({
    name: 'updateCustomer',
    initialState,
    reducers: {},
    extraReducers:{
        [updateCustomer.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [updateCustomer.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [updateCustomer.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})
export default userSlice.reducer;