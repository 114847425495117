import { AccountBalanceOutlined, Edit, InfoOutlined, Password } from "@mui/icons-material";
import { Alert, Button, CircularProgress, FormControl, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { changePassword } from "../../../app/features/changePasswordSlice";
import NavBar from "../../../components/navbar/navbar.component";
import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { ProfileButton, ProfileForm, ProfileLinkContents, ProfileLinks, ProfileLinksList, ProfileWrapper } from "./profile.styled";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const ChangePassword = () => {

  const dispatch = useDispatch();

  const [chPass, setChPass] = useState();
  const [validateError, setValidateError] = useState({
    display:'none',
    message:null,
})

  const { isLoading } = useSelector((state) => state.changePassword);

  const handleChangePassSubmit =(e)=>{
    e.preventDefault()
    dispatch(changePassword(chPass)).then((res)=>{
      const {error, payload} = res;
      {
        error?
        setValidateError({
          ...validateError,
          display:'block',
          message:payload
      })
        :
        localStorage.removeItem('user')
        console.log('SUCCESS')
      }
    })
    
  }
  const handleChange = (e) => {
    const {name, value} = e.target
    setChPass({
      ...chPass,
      [name]:value
    })
  }

  const navigate = useNavigate()


    return (
        <><NavBar/>
      <div className="main-content main-content-margin-top">
        <ProfileWrapper>
        <ProfileSideBar/>

          <ProfileLinkContents>
            <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
            <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  Change Password</p>
            <hr />
<br />

      <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>  
            <ProfileForm action="" onSubmit={(e)=>handleChangePassSubmit(e)}>
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Enter Old Password"
                  name="oldPassword"
                  onChange={(e)=>handleChange(e)}
                  id="fullWidth"
                />
              </FormControl>

              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Enter New Password"
                  name="newPassword"
                  onChange={(e)=>handleChange(e)}
                  id="fullWidth"
                />
              </FormControl>
   
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Confirm New Password"
                  name="confirmPassword" 
                  onChange={(e)=>handleChange(e)}
                  id="fullWidth"
                />
              </FormControl>

              {
                  isLoading ?
                  <Button style={{background:'transparent', width:'100%'}}><CircularProgress color="inherit" /> </Button>
                  :
                  <ProfileButton type="submit">Change Password</ProfileButton>
              }  
            
              
              
            </ProfileForm>
          </ProfileLinkContents>
        </ProfileWrapper>
      </div>
        </>

    )

}

export default ChangePassword;