import styled from "styled-components";
import { ChartWidgetContainer } from "../../../components/widgets/chart-widget/chart_widget.styled";

export const OrderSummaryContainer = styled(ChartWidgetContainer)`
    height: auto;
`

export const OrderOverview = styled.div`
    
`

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;

    @media screen and (max-width: 768px){
        svg{
            margin: 0 10px;
        }
    }
    
`
