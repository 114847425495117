import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';
import axios from "../../../../api/axios";

const initialState = {
    wallets:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getAllWallets = createAsyncThunk(
    'partner/getAllWallets',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get("/Wallets",
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getAllWalletsSlice = createSlice({
    name: 'getAllWallets',
    initialState,
    reducers: {},
    extraReducers:{
        [getAllWallets.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getAllWallets.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.wallets = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getAllWallets.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default getAllWalletsSlice.reducer;