import React from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from '../../app/features/resetPasswordSlice';
import { toast } from "react-toastify";

const ForgotPasswordSuccess = () => {
    const [ searchParams ] = useSearchParams();
    const email = searchParams.get("email");

    const init_payload = {
      email: email,
      password: '',
      confirmPassword: '',
      code: ''
    }
    const [payload, setPayload] = React.useState(init_payload)
    const [otp, setOtp] = React.useState('');
    const [viewType, setViewType] = React.useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigateToView = () => {
      setViewType(1)
    }

    const handleChange = (value) => {
      setOtp(value)
      console.log('OTP', otp)
    }

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setPayload({
        ...payload,
        [name]: value
      })
      console.log('payload', payload)
    }

    const handleComplete = async (value) => {
      const req = {
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        code: value
      }
      console.log('req', req)
      dispatch(resetPassword(req)).then((res) => {
        const { error, payload } = res;
        if (!error) {
            toast.success('Password reset successful')
            navigate('/login')
        } else {
            toast.error(payload)
        }
      })
    }


    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
              { viewType === 1 &&
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>
    <div style={{textAlign:'center'}}>
                    <hr />
                    <br />
                    <h2>Confirmation Code Sent</h2>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Please input the reset code sent to {email}
                    </p>
                    <InputContainer>
                    <MuiOtpInput
                    value={otp}
                    onChange={handleChange}
                    onComplete={handleComplete}
                    length={6}
                    />
                    </InputContainer>

                    <InputContainer>
                        <p><Link to='/login'> Back to login</Link> </p>
                        </InputContainer>
                    </div>
                </AuthFormContainer>
              }
              { viewType === 0 &&
                <AuthFormContainer>
                <Brand>
                <BrandImage src={RedSpeedLogo}/>
                </Brand>
<div style={{textAlign:'center'}}>
                <hr />
                <br />
                <h2>Reset Password</h2>

                <InputContainer>
                    <Input type="email" disabled value={email} />
                </InputContainer>
                <InputContainer>
                    <Input type="text" onChange={(e) => handleInputChange(e)} placeholder="New Password" name="password" />
                </InputContainer>
                <InputContainer>
                    <Input type="text" onChange={(e) => handleInputChange(e)} placeholder="Confirm Password" name="confirmPassword" />
                </InputContainer>

                <InputContainer>
                    <Button
                    onClick={handleNavigateToView}
                    >
                        Continue
                    </Button>
                </InputContainer>
                </div>
            </AuthFormContainer>
              }
            </ContentContainer>
        </Section>
        </>
    )
}

export default ForgotPasswordSuccess;