import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../../helper/protectedRoutes";
import baseUrl from '../../../../../helper/api';

const initialState = {
    vehicleModelData:[],
    modelIsLoading:false,
    status:null,
}

const token = userObj?.token

export const vehicleModels = createAsyncThunk(
    'partner/vehicleModels',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Settings/vehicleModels`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const vehicleModelsSlice = createSlice({
    name: 'vehicleModels',
    initialState,
    reducers: {},
    extraReducers:{
        [vehicleModels.pending]: (state, action) => {
            state.status='pending';
            state.modelIsLoading=true;
        },
        [vehicleModels.fulfilled]: (state, action) => {
            state.status='success';
            state.modelIsLoading=false;
            state.vehicleModelData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [vehicleModels.rejected]: (state, action) => {
            state.status='failed';
            state.modelIsLoading=false;
        }
    }
})

export default vehicleModelsSlice.reducer;