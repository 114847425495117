import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../../helper/api';

const initialState = {
    isLoading:false,
    status:null,
    error:null
}

export const generateSMSToken = createAsyncThunk(
    'customer/generateSMSToken',
    async (phone, {rejectWithValue}) => {
        try{
            
            const response = await axios.get(`${baseUrl}/Account/generateSMSToken?phone=${phone}`)
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

const generateSMSTokenSlice = createSlice({
    name:"customer",
    initialState,
    reducers:{},
    extraReducers:{
        [generateSMSToken.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [generateSMSToken.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
        },
        [generateSMSToken.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            state.error = action.error
        }
    }
})

export default generateSMSTokenSlice.reducer;