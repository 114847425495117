import styled from "styled-components";

export const VehiclesTableContainer = styled.div`
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    width:98%;
    margin: auto auto;
`

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @media screen and (max-width: 768px){
        svg{
            margin: 0 10px;
        }
    }
    
`

export const TableWrapper = styled.div`
    width: 100%;
    overflow-x: scroll;
    margin:25px 0;
    ::-webkit-scrollbar {
        width: 0;  
        background: transparent;  
    }
    
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
`

export const SearchInput = styled.input`
    height: 30px;
    min-width:220px;
    border-radius: 10px;
    border: thin solid grey;
    
    padding: 5px;
    font-size:1.2em;
    color: #aeaeae;
    transition: .35s ease-in-out;

    &:focus{
        min-width:250px;
        outline: none;
    }
`


export const Table = styled.table`
    border-collapse: collapse;
    margin: auto auto;
    font-size: 0.9em;
    min-width: 400px;

    // td:nth-child(1) {  
    //     width: auto !important;
    //   }

    thead tr{
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
    }

    th,td{
        padding: 8px 8px;
        width: 150px;
    }

    tbody tr{
        border-bottom: 1px solid #dddddd;
        
    }

    tbody tr:nth-of-type(even){
        background-color: #f3f3f3
    }
`

export const AddNew = styled.div`
    width:100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top:10px;
    button{
        font-weight:bold;
        font-size:small;
        text-transform:capitalize;
    }
    svg{
        color:grey !important;
    }
`