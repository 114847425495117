import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../helper/api';

const initialState = {
    isLoading:false,
    status:null,
    error:null
}

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (data, {rejectWithValue}) => {
        try{
            const response = await axios.post(`${baseUrl}/Account/forgotPassword`, data)
            return response?.data
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

const forgotPasswordSlice = createSlice({
    name:"forgotPassword",
    initialState,
    reducers:{},
    extraReducers:{
        [forgotPassword.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [forgotPassword.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload))
        },
        [forgotPassword.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            state.error = action.error
        }
    }
})

export default forgotPasswordSlice.reducer;