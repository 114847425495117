import styled from "styled-components";

export const ChartWidgetContainer = styled.div`
    width: 100%;
    background-color: #fff;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 1px 2px #ccc;
    box-shadow: 0 1px 2px #ccc;
    padding: 10px;
    margin: 8px;
    box-sizing: border-box;
    height: 420px;

    @media screen and (max-width: 768px){
        width: 95%
    }
`