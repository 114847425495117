import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    orderDetails:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getOrder = createAsyncThunk(
    'order/getOrder',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`/Order/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getOrderSlice = createSlice({
    name: 'getOrder',
    initialState,
    reducers: {},
    extraReducers:{
        [getOrder.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getOrder.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.orderDetails = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getOrder.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default getOrderSlice.reducer;