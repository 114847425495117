import { createSlice } from "@reduxjs/toolkit";


const logout = createSlice({
    name: 'logout',
    initialState:null,
    reducers: {
        logOut(state, action) {
            localStorage.removeItem('user');
        },
    }
})

export const {logOut} = logout.actions;
export default logout.reducer;