import * as React from "react";
import "./navbar.css";
import {
  Nav,
  NavLink,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Bars,
  SmallScreenNavDropDown,
} from "./navbar.styled";
import RedSpeedLogo from "../../assets/images/Redspeed-white.png";
import User from "../../assets/images/user.jpg";
import Avatar from "@mui/material/Avatar";
import SideBar from "../sidebar/sidebar.component";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useLocation, useNavigate } from "react-router-dom";

const markActive = (location, path) => {

  const pathname = location.pathname;

  const splittedPathnameWithQueryString = pathname.split("?");
  const pathnameWithoutQueryString = splittedPathnameWithQueryString[0];

  const splittedPathnameWithHash = pathnameWithoutQueryString.split("#");
  const pathnameWithoutQueryStringAndHash = splittedPathnameWithHash[0];

  const pathnameWithoutTrailingSlash =
    pathnameWithoutQueryStringAndHash.endsWith("/")
      ? pathnameWithoutQueryStringAndHash.slice(
          0,
          pathnameWithoutQueryStringAndHash.length - 1
        )
      : pathnameWithoutQueryStringAndHash;

  return pathnameWithoutTrailingSlash === path;
};

// const location = {
//   pathname: "/dashboard/partner/#trtr?gender=male&q=abike+tundee889qw5u4094",
// };

// const isActive = markActive(location, "/dashboard/partner");

// console.log({ isActive });

const NavBar = () => {
  // const [userRole, setRole] = React.useState()
  const ToggleSideBar = () => {
    document.getElementById("sidebar").classList.toggle("hide-side-bar");
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role[0].toLowerCase();
  const location = useLocation()
  console.log({location})

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate()

  // React.useEffect(()=>{

  // })

  // console.log(userRole)

  // let activeClassname = {
  //   fontWeight: 800,
  //   color: "red"
  // };
  // let activeStyle = {
  //   fontWeight: 800,
  //   color: "red",
  //   textDecoration: "underline"
  // }

  return (
    <>
      <Nav>
        <NavLink to="">
          <img src={RedSpeedLogo} height="90%" alt="" />
        </NavLink>
        <Bars onClick={ToggleSideBar} />
        <NavMenu>
          {userRole === "partner" ? (
            <NavLink to="/dashboard/partner"
              className={markActive(location,"/dashboard/partner")? 'activeClass' : null}>
              Dashboard
            </NavLink>
          ) : (
            <NavLink to="/dashboard/customer">
              Dashboard
            </NavLink>
          )}

          {userRole === "customer" && (
            <>
              <Button
                style={{ textTransform: "capitalize" }}
                variant="text"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Orders
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => navigate("/dashboard/customer/orders")}>Orders</MenuItem>
                <MenuItem onClick={() => navigate("/dashboard/customer/cashondelivery")}>COD Report</MenuItem>
              </Menu>
            </>
          )}

          {userRole === "customer" && (
            <NavLink to="/dashboard/customer/daily-shipments">
              Shipments
            </NavLink>
          )}

          {userRole === "customer" && (
            <NavLink to="/dashboard/customer/rcs">
              RCS
            </NavLink>
          )}

          {userRole === "partner" ? (
            <NavLink to="/dashboard/partner/vehicles"
            className={markActive(location,"/dashboard/partner/vehicles")? 'activeClass' : null}>
              Vehicles
            </NavLink>
          ) : (
            <NavLink to="/dashboard/customer/vehicles-types">
              Vehicles
            </NavLink>
          )}

          {userRole === "partner" && (
            <NavLink to="/dashboard/partner/drivers"
            className={markActive(location,"/dashboard/partner/drivers")? 'activeClass' : null}>
              Drivers  
            </NavLink>
          )}

          {userRole === "partner" && (
            <NavLink to="/dashboard/partner/finance"
            className={markActive(location,"/dashboard/partner/finance")? 'activeClass' : null}>
              Finance
            </NavLink>
          )}

          <NavLink to="/dashboard/edit-profile"
          className={markActive(location,"/dashboard/edit-profile")? 'activeClass' : null}>
            Profile
          </NavLink>
          {/* <NavLink to='' activeStyle>
      Sign Up
    </NavLink> */}
        </NavMenu>
        <NavBtn>
          <Avatar alt="Remy Sharp" src={User} />
        </NavBtn>
      </Nav>

      <SideBar />
    </>
  );
};
export default NavBar;

