import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "./orders.styled";
import { WidgetFlex } from "../../Partner-Pages/Dashboard/dashboard.styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import NavBar from "../../../components/navbar/navbar.component";
import { Heading } from "../../../components/Tags/tags.styled";
import { userObj } from "../../../helper/protectedRoutes";
import { trackOrder } from "../../../app/features/Customer/Order/trackingOrderSlice";
import "./track.customer.css";

const OrderTrackPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const { trackNo } = useParams();

  const [data, setData] = React.useState({});
  const [modalSign, setModalSign] = React.useState(false);
  const [orderStatus, setOrderStatus] = React.useState([]);
  const { isLoading } = useSelector((state) => state.trackingOrder);

  useEffect(() => {
    document.title = "Daily shipments - Customer";
    dispatch(trackOrder(trackNo)).then((res) => {
      const { payload } = res;
      setData(payload);
      const status = payload.pStatus;
      setOrderStatus(status);
      console.log("shipments", payload);
    });
  }, []);

  const handleOpenSign = () => {
    setModalSign(true);
  };
  const handleCloseSign = () => {
    setModalSign(false);
  };

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <Container>
          {!isLoading && (
            <>
              <Heading size="1.8em">Order - {data.waybillNumber}</Heading>
              <WidgetFlex>
                <Card
                  sx={{
                    marginBottom: "12px",
                    width: "50ch",
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Box sx={{ m: 1 }}>
                      <Heading size="1.2rem">Recipient Information</Heading>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        m: 1,
                      }}
                    >
                      <div className="">
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>Name</span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.recipient?.recipientName}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>Phone Number</span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.recipient?.phoneNumber}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>
                            Street Address
                          </span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.recipient?.streetAddress}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>Email</span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.recipient?.email}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>State</span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.recipient?.state}
                          </p>
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginBottom: "12px",
                    width: "50ch",
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Box sx={{ m: 1 }}>
                      <Heading size="1.2rem">Order Information</Heading>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        m: 1,
                      }}
                    >
                      <div className="">
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>Origin City</span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.origin?.name}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>
                            Delivery City
                          </span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.city?.name}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>
                            Delivery Town
                          </span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.town?.name}
                          </p>
                        </div>
                        <div className="" style={{ marginBottom: "5px" }}>
                          <span style={{ fontSize: "12px" }}>
                            Content Description
                          </span>
                          <p style={{ fontSize: "16px", fontWeight: "500" }}>
                            {data.contentDescription}
                          </p>
                        </div>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="" style={{ marginBottom: "5px" }}>
                            <span style={{ fontSize: "12px" }}>Quantity</span>
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>
                              {data.unit}
                            </p>
                          </div>
                          <div className="" style={{ marginBottom: "5px" }}>
                            <span style={{ fontSize: "12px" }}>Weight</span>
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>
                              {data.weight + "kg"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </WidgetFlex>
              {data?.pod && (
                <Card>
                  <Box sx={{ m: 1 }}>
                    <Heading size="1.2rem">Proof of Delivery</Heading>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      m: 1,
                    }}
                  >
                    <div className="">
                      <div className="" style={{ marginBottom: "5px" }}>
                        <span style={{ fontSize: "12px" }}>Date Delivered</span>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          {data.pod?.deliveredDate
                            ? new Date(data.pod?.deliveredDate).toDateString()
                            : "-"}
                        </p>
                      </div>
                      <div className="" style={{ marginBottom: "5px" }}>
                        <span style={{ fontSize: "12px" }}>Receiver Name</span>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          {data.pod?.receiverName}
                        </p>
                      </div>
                      <div className="" style={{ marginBottom: "5px" }}>
                        <span style={{ fontSize: "12px" }}>
                          Receiver's Signature
                        </span>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          <a href="#" onClick={handleOpenSign}>
                            View Signature
                          </a>
                        </p>
                      </div>
                    </div>
                  </Box>
                </Card>
              )}
              <Card
                sx={{
                  marginBottom: "12px",
                }}
                variant="outlined"
              >
                <CardContent>
                  <Box sx={{ m: 1 }}>
                    <Heading size="1.2rem">Order Status</Heading>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="status-section d-flex align-items-center"
                      style={{ marginTop: "16px" }}
                      data-content
                    >
                      {orderStatus?.map((s, key) => {
                        return (
                          <div
                            className={
                              s.value
                                ? "order-tracking completed"
                                : "order-tracking"
                            }
                          >
                            {/* <div className=""> */}
                            {s.value ? (
                              <span className="completed"></span>
                            ) : (
                              <span className="is-complete"></span>
                            )}
                            {/* </div> */}
                            <div className="text" style={{ marginTop: "16px" }}>
                              <h3
                                className="status-title mb-0"
                                style={{
                                  fontSize: ".943rem",
                                  fontWeight: "500",
                                }}
                              >
                                {s.name}
                              </h3>
                              {s.value && (
                                <span
                                  className="status-title mb-0"
                                  style={{ fontSize: ".743rem" }}
                                >
                                  {s.location}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </>
          )}
          {isLoading && (
            <Button style={{ width: "100%", marginTop: "20px" }}>
              <CircularProgress />
            </Button>
          )}
        </Container>
        <div>
          <Dialog open={modalSign} onClose={handleCloseSign}>
            <DialogTitle>Receiver's Signature</DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <img
                  src={"data:image/jpeg;base64, " + data.pod?.receiverSign}
                  alt="signature"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseSign}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default OrderTrackPage;
