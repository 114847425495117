import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from '../../api/axios';
import { userObj } from '../../helper/protectedRoutes';

const initialState = {
    isLoading:false,
    status:null,
    error:null
}

const token = userObj?.token

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (data, {rejectWithValue}) => {
        try{
            const response = await axios.post("/Account/changePassword", data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

const changePasswordSlice = createSlice({
    name:"changePassword",
    initialState,
    reducers:{},
    extraReducers:{
        [changePassword.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [changePassword.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload))
        },
        [changePassword.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            state.error = action.error
        }
    }
})

export default changePasswordSlice.reducer;