import React from "react";
import Chart from "react-apexcharts";

export default class DriverTotalEarnedChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Drivers Total Earned",
          data: [30000, 35000, 50000, 49000, 60000, 70000, 150000],
          
        },
      ],
    };
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={{
            chart: {
              id: "List of daily orders",
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
                borderRadius: 4,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#000000", "grey"],
            xaxis: {
              categories: [
                  ["John", "Doe"],
                  
                  ["Jake", "Williams"],
                  "Amber",
                  ["Peter", "Brown"],
                  ["Mary", "Evans"],
                  ["David", "Wilson"],
                  ["Lily", "Roberts"],
                ],
            },
          }
          }
          series={this.state.series}
          type="bar"
          height={270}
        />
      </div>
    );
  }
}
