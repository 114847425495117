import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    financeData:[],
    isLoadingFinance:null,
    status:null,
}

const token = userObj?.token

export const finance = createAsyncThunk(
    'partner/finance',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`Partner/orders/finance/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const financeSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {},
    extraReducers:{
        [finance.pending]: (state, action) => {
            state.status='pending';
            state.isLoadingFinance=true;
        },
        [finance.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoadingFinance=false;
            state.financeData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [finance.rejected]: (state, action) => {
            state.status='failed';
            state.isLoadingFinance=false;
        }
    }
})

export default financeSlice.reducer;