import styled from "styled-components";

export const ProfileLinks = styled.div`
width: 100%;
flex:0.85;
margin-bottom:10px;
@media screen and (min-width: 768px){
    margin-right:20px;
}

`

export const ProfileLinksList = styled.ul`
    background: #f7f9ff;
    border-radius: 10px;
     width:100%;
     list-style: none;
     color:#535f6b;
     cursor: pointer;
     box-shadow:0 0 5px #80808075;
     padding: 10px;


li{
    position: relative;
    line-height:30px;
    display:flex;
    align-items:center;
    padding:10px;
    margin:10px;
    border-radius: 10px;
}
a{
    color: #535f6b;
    text-decoration:none;
    font-size:15px;
    padding-left:5px;
    font-weight:500;
    display:block;
    width:100%;
    border-left:3px solid transparent;
    margin-top:3px;
}

li:hover{
    background: #dab5b3;
    color:#b23934 !important;
    border-radius: 10px;
}


`