import React, { useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { customerValidateEmail } from "../../app/features/Customer/validateEmailSlice";

const PartnerVerifyEmail = () => {
    const [ searchParams ] = useSearchParams();
    const email = searchParams.get("email");
    

    const [emailToken, setEmailToken] = useState({
        email,
        token:''
    })
    const [validateError, setValidateError] = useState({
        display:'none',
        message:null,
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const {isLoading, status} = useSelector((state) => state.customerValidateEmail)

    const handleSubmit = (event)=> {
        event.preventDefault()
        try{
            dispatch(customerValidateEmail(emailToken)).then((res)=>{
                
                let {payload, error} = res
                
                {
                    error ?
                    setValidateError({
                        ...validateError,
                        display:'block',
                        message:payload
                    })
                    :
                    navigate("/congratulation")
                }
                
            })
        } catch(err){
            return err
        }

    }

    const handleTokenInput = (e)=> {
        setEmailToken({
            ...emailToken,
            token:e.target.value
        })
    }

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Email Verification</h2>
                  
                    <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>
                    
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Type out the code sent to your email address {email}
                    </p>
                    <form action="" method="get" onSubmit={(e)=>handleSubmit(e)}>
                        <InputContainer>
                        
                        <Input type="text" placeholder="Enter code" name="" onChange={(e)=>handleTokenInput(e)}  />
                        </InputContainer>

                        <Remember>
                            <p>Resend Text (15s)</p>
                        </Remember>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Continue</Button>
                        }         
                        </InputContainer>

                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default PartnerVerifyEmail;