import React from "react";
import { PhotoCameraOutlined } from "@mui/icons-material";
import NavBar from "../../../components/navbar/navbar.component";
import {
  ProfileWrapper,
  ProfileLinkContents,
  Flex,
  Table,
  TableWrapper,
  EditWrapper
} from "./profile.styled";
import { CircularProgress, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { generateAPIKey, getUserAPIKeys, removeUserAPIKey } from "../../../app/features/user";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const UserKey = () => {
  const [data, setData] = useState();
  const userObj = JSON.parse(localStorage.getItem("user"));
  console.log("user :>>", userObj)
  const userRole = userObj?.role[0];

  const [readOnly, setReadOnly] = useState(true)
  //const { isLoading } = useSelector((state) => state.getUserAPIKeys);
  const editState = false

  let button;
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const selectForDelete = (row) => {
    dispatch(removeUserAPIKey(row.id)).then((res) => {
      const { error } = res;
        error ?? alert(error)
        alert("API Key Successfully deleted")
        dispatch(getUserAPIKeys()).then((res) => {
          const { payload, error } = res;
          !error && setData(payload)
        })
    });
  }

  const addKey = () => {
    dispatch(generateAPIKey()).then((res) => {
      const { error } = res;
        error != undefined ?? alert(error)
        alert("API Key Successfully created")
        dispatch(getUserAPIKeys()).then((res) => {
          const { payload, error } = res;
          !error && setData(payload)
        })
    });
  }

  useEffect(()=>{
    dispatch(getUserAPIKeys()).then((res)=> {
      const {payload, error} = res
      !error && setData(payload)
    })

  }, [])

  return (
    <>
      <NavBar />

      <div className="main-content main-content-margin-top">
        <ProfileWrapper>
          <ProfileSideBar />
          <ProfileLinkContents>
            <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
            <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  API Keys</p>
            <hr />

              <EditWrapper>
              <Button onClick={() => addKey()} variant="outlined">
              Generate Key
              </Button>
              
              </EditWrapper>

              <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Key</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              {
                <tbody>
                {data?.map((row_, Key) => {
                    return (
                      <tr>
                        <td>{row_.id}</td>
                        <td>{row_?.key}</td>
                        <td>{row_?.expiryDate ? new Date(row_.expiryDate).toDateString() : '-'}</td>
                        <td>{row_?.status == 0 ? 'Active' : 'Disabled'}</td>
                        
                        <td>
                          <Flex>
                            {" "}
                            <div onClick={()=> selectForDelete(row_)} className="red">
                              <DeleteIcon />
                            </div>
                          </Flex>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
}
            </Table>
            {/* {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>} */}
          </TableWrapper>
          </ProfileLinkContents>
        </ProfileWrapper>
      </div>
    </>
  );
};

export default UserKey;
