import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import Widget from "../../../components/widgets/widget.component";
import { QuickActionFlex,
    QuickActionItemContainer,
    WidgetFlex, } from "../Dashboard/dashboard.styled";
import ChartWidget from "../../../components/widgets/chart-widget/chart_widget.component";
import ListOfOrdersChart from "../../../partner-charts/order_list.chart";
import BalanceChart from "../../../partner-charts/balances.chart";
import DriverTotalEarnedChart from "../../../partner-charts/driver_total_earned.chart";
import DriverTripsChart from "../../../partner-charts/driver_trips.chart";
import { Avatar } from "@mui/material";
import CarLogo from "../../../assets/icons/car.svg";
import iconFinance from "../../../assets/icons/finance-icon.svg";
import iconTrackVehicle from "../../../assets/icons/track-vehicle-icon.svg";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { finance } from "../../../app/features/Partner/Finance/financeSlice";
import { userObj } from "../../../helper/protectedRoutes";
// import { Button, TextField, Dialog, DialogActions } from "@mui/material";
// import { ProfileButton, ProfileForm, ProfileLinkContents, ProfileLinks, ProfileLinksList, ProfileWrapper } from "../Profile/profile.styled";
import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    background: "rgb(248, 113, 113, 0.2) !important",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    padding: "5px",
  },
});

const Finance = () => {
  const dispatch = useDispatch()
  const id = userObj?.extra.id;

  const dollarUSLocale = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  const [open, setOpen] = React.useState(false);
  const [finance_data, setFinance] = React.useState([]);
  const [alertToggle, setAlertToggle] = React.useState(false);
  const [assignDriverProperty, setAssignDriverProperty] = React.useState({
    driver:'',
    vehicle:''
  });

  const handleAssignDriverChange = (event) => {
    const { name, value } = event.target;
    setAssignDriverProperty({
      ...assignDriverProperty,
      [name]: value,
    });
  };
  const classes = useStyles();

  const { isLoadingFinance } = useSelector((state) => state.finance);

  useEffect(()=>{
    document.title = "Finance - partner"
    dispatch(finance(id)).then((res)=>{
      const {payload, error} = res;
      {
        !error&&
        
        setFinance(payload)
      }
    })
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Assign Driver
  const AssignDriver = () => {
    handleAlertOpen();
    setOpen(false);
  }

  // Alert Settings
  const handleAlertOpen = () => {
    setAlertToggle(true);
  };

  const handleAlertClose = () => {
    setAlertToggle(false);
  };

  const navigate = useNavigate()

  return (
    <>
      <NavBar />

      {/*------------------- Alert --------------------*/}
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar>


          
      
      <div className="main-content main-content-margin-top">

                  <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
                  <i onClick={() => navigate(-1)}>
                  <ArrowBackIcon /></i></p>

        <WidgetFlex>
          <Link to="">
            <Widget
              headcolor="#10c469"
              currency="&#8358;"
              head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.totalIncome)}` : "0"}
              bottom="Total Income"
            />
          </Link>

          <Link to="">
            <Widget
              headcolor="#ff5b5b"
              currency="&#8358;"
              head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.totalEarned)}` : "0"}
              bottom="Total Earned"
            />
          </Link>

          <Link to="">
            <Widget headcolor="#188ae2" 
            currency="&#8358;"
            head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.paidAmount)}` : "0"}
            bottom="Paid Amount" />
          </Link>

          <Link to="">
            <Widget headcolor="#f9c851" 
            currency="&#8358;"
            head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.outstanding)}` : "0"} 
            bottom="Outstanding Balance" />
          </Link>
        </WidgetFlex>
       
     

        {/* Chart Section */}
        <WidgetFlex>
          <ChartWidget
            heading="Total Income"
            content={<ListOfOrdersChart />}
          />
          <ChartWidget
            heading="Total Earned"
            content={<ListOfOrdersChart />}
          />
        </WidgetFlex>
        <WidgetFlex>
          <ChartWidget
            heading="Paid Amount"
            content={<DriverTotalEarnedChart />}
          />
          <ChartWidget
            heading="Outstanding Balance"
            content={<DriverTripsChart />}
          />
        </WidgetFlex>
      </div>
    </>
  );
};

export default Finance;
