import React from "react";
import NavBar from "../../../components/navbar/navbar.component";
import {
  AddNew,
  Flex,
  SearchInput,
  Table,
  TableWrapper,
  VehiclesTableContainer,
} from "../Vehicle/vehicles.styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Alert_ from "../../../helper/alert/alert";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { InputContainer } from "../../Auth/auth.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userObj } from "../../../helper/protectedRoutes";
import { addRider } from "../../../app/features/Partner/rider/addRider";
import { getAllRiders } from "../../../app/features/Partner/rider/getAllRiders";
import { deleteRiders } from "../../../app/features/Partner/rider/deleteRiderSlice";
import { Co2Sharp } from "@mui/icons-material";
import { updateRider } from "../../../app/features/Partner/rider/updateRiderSlice";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const data_json = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    phone: "0903747394",
    assigned: true,
  },
  {
    id: 2,
    firstName: "Ichigo",
    lastName: "Kurosaki",
    phone: "083729445",
    assigned: false,
  },
  {
    id: 3,
    firstName: "Kaguya",
    lastName: "Otsutsuki",
    phone: "07024858445",
    assigned: true,
  },
  {
    id: 4,
    firstName: "Son",
    lastName: "Goku",
    phone: "0918385493",
    assigned: true,
  },
  {
    id: 5,
    firstName: "Madara",
    lastName: "Uchiha",
    phone: "0927373046",
    assigned: false,
  },
];

const initialValue = {
  PartnerId: "",
  FirstName: "",
  LastName: "",
  Address: "",
  Telephone: "",
  Email: "",
  DriverLicenseFile: "",
  LassraCardFile: "",
  PassportFile: "",
  Status: 0,
};

const PartnerVehiclePage = () => {
  const dispatch = useDispatch();
  const id = userObj?.extra.id

  const [data, setData] = React.useState([]);
  const [riderUploadData, setRiderUploadData] = React.useState({
    ...initialValue,
    ['PartnerId'] : id
  });
  const [openEditDriver, setOpenEditDriver] = React.useState(false);
  const [openDeleteRider, setOpenDeleteRider] = React.useState();
  const [riderId, setRiderId] = React.useState();
  const [openAddRider, setOpenAddRider] = React.useState(false);
  const [openAddRiderDocs, setOpenAddRiderDocs] = React.useState(false);
  const [openViewDriver, setOpenViewDriver] = React.useState(false);
  const [driverDetails, setdriverDetails] = React.useState([]);
  const [alertToggle, setAlertToggle] = React.useState({
    isVisible: false,
    color: "success",
    message: "",
  });
  const [query, setQuery] = React.useState("");
  
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Rider - RedSpeed";
    dispatch(getAllRiders(id)).then((res) => {
      console.log('riders :>>', res)
      const { payload, error } = res;
      !error && setData(payload);
    });
  }, []);

  // Delete rider
  const handleCloseDeleteRider = () => {
    setOpenDeleteRider(false);
  };

  const handleOpenDeleteRider = (rider_id) => {
    setRiderId(rider_id);
    setOpenDeleteRider(true);
  };

  const handleSubmitDeleteRider = () => {
    dispatch(deleteRiders(riderId)).then((res) => {
      const { payload, error } = res;
        error ? alert(error) : handleCloseDeleteRider();
        alert("Successfully deleted");
        dispatch(getAllRiders(userObj?.extra.id)).then((res) => {
          const { payload, error } = res;
            !error && setData(payload);
            // window.location.reload();
        });
    });
    setOpenDeleteRider(false);
  };

  const { isUpdatingRider } = useSelector((state) => state.updateRider);
  const { isAddingRider } = useSelector((state) => state.addRider);
  const { isDeletingRider } = useSelector((state) => state.deleteRider);
  const { isLoading } = useSelector((state) => state.getAllRiders);

  const AddRiderDocs = (e) => {
    const { name, files } = e.target;
    setRiderUploadData({
      ...riderUploadData,
      [name]: files[0],
    });
  };

  const handleAddRiderInput = (e) => {
    const { name, value } = e.target;
    setRiderUploadData({
      ...riderUploadData,
      [name]: value,
    });
  };

  // Search Function
  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  // Alert Functions
  const handleAlertOpen = (color_param, message_param) => {
    setAlertToggle({
      ...alertToggle,
      isVisible: true,
      color: color_param,
      message: message_param,
    });
  };

  const handleAlertClose = () => {
    setAlertToggle({
      ...alertToggle,
      isVisible: false,
    });
  };

  // Modal Functions
  const handleOpenViewDriver = (row_param) => {
    setdriverDetails(row_param);
    setOpenViewDriver(true);
  };

  const handleCloseViewDriver = () => {
    setOpenViewDriver(false);
  };

  // Add Rider Document Modal
  const handleAddRiderDocs = () => {};
  const handleOpenAddRiderDocs = () => {
    setOpenAddRiderDocs(true);
  };

  const handleCloseAddRiderDocs = () => {
    setOpenAddRiderDocs(false);
  };

  // Add Rider Modal
  const handleOpenAddRider = () => {
    setOpenAddRider(true);
  };

  const handleCloseAddRider = () => {
    setOpenAddRider(false);
  };

  const handleNextToDocUpload = () => {
    setOpenAddRider(false);
    setOpenAddRiderDocs(true);
  };

  const handleSubmitAddRider = () => {
    setOpenAddRider(false);
    dispatch(addRider(riderUploadData)).then((res) => {
      const { payload, error } = res;
      {
        error ? alert(payload) : alert("Added Successfully");
        handleCloseAddRiderDocs();
        window.location.reload();
      }
    });
  };

  // Edit Vehicle  Modal
  const handleOpenEditDriver = (row_param) => {
    // let copiedParam = JSON.parse(JSON.stringify(row_param));
    // delete copiedParam['id']
    setdriverDetails(row_param);
    setOpenEditDriver(true);
    
  };

  const handleEditRider = (e) => {
    const { name, value } = e.target;
    setdriverDetails({
      ...driverDetails,
      [name]: value,
    });
    console.log(driverDetails)
  };

  const handleSubmitEditDriver = () => {
    dispatch(updateRider(driverDetails)).then((res) => {
      const { payload, error } = res;
      {
        error ? console.log(res) : window.location.reload();
        setOpenEditDriver(false);
        alert("Edited Successfully");
      }
    });
    // handleAlertOpen("warning", "Successully edited rider");
  };

  const handleCloseEditDriver = () => {
    setOpenEditDriver(false);
  };

  function setStatus(status) {
    switch (status) {
      case 0:
        return <p className="text-danger">Unverified</p>
      case 1:
        return <p className="text-success">Approved</p>
      case 2:
        return <p className="text-warning">Unapproved</p>
      default:
        return <p className="text-danger">Unverified</p>
    }
  }

  return (
    <>
      <NavBar />
      <Alert_
        status={alertToggle.isVisible}
        onClose={handleAlertClose}
        color={alertToggle.color}
      />

      {/* ------------------- Alert --------------------
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar> */}

      <div className="main-content main-content-margin-top">
        {/* Vehicle Modal */}
        <div name="Add Rider Modal Parent Node">
          <Dialog open={openAddRider} onClose={handleCloseAddRider}>
            <DialogTitle>Add Rider</DialogTitle>

            <DialogContent>
              <input
                hidden
                type="text"
                name="PartnerId"
                value={userObj?.extra.id}
              />
              <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="First Name"
                    name="FirstName"
                    id="fullWidth"
                    defaultValue={riderUploadData.FirstName}
                    onChange={(e) => handleAddRiderInput(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Last Name"
                    name="LastName"
                    id="fullWidth"
                    value={riderUploadData.LastName}
                    onChange={(e) => handleAddRiderInput(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Address"
                    id="fullWidth"
                    name="Address"
                    onChange={(e) => handleAddRiderInput(e)}
                    defaultValue={riderUploadData?.Address}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Telephone"
                    id="fullWidth"
                    name="Telephone"
                    onChange={(e) => handleAddRiderInput(e)}
                    defaultValue={riderUploadData?.Telephone}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Email"
                    id="fullWidth"
                    name="Email"
                    onChange={(e) => handleAddRiderInput(e)}
                    defaultValue={riderUploadData?.Email}
                  />
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseAddRider}
              >
                Close
              </Button>

              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleNextToDocUpload}
              >
                Next
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Delete parent node">
          <Dialog
            open={openDeleteRider}
            onClose={() => handleCloseDeleteRider()}
          >
            <form action="" method="post">
              <DialogTitle>Delete Rider</DialogTitle>

              <DialogContent style={{}}>
                <div>
                  <p>Are you sure you want to delete this rider?</p>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCloseDeleteRider()}
                >
                  Close
                </Button>

                {isDeletingRider ? (
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="error"
                    onClick={() => handleSubmitDeleteRider()}
                  >
                    Delete
                  </Button>
                )}
              </DialogActions>
            </form>
          </Dialog>
        </div>

        <div name="Add Rider's Docs parent node">
          <Dialog
            open={openAddRiderDocs}
            onClose={() => handleCloseAddRiderDocs()}
          >
            <form action="" method="post">
              <DialogTitle>Upload Rider's Documents</DialogTitle>

              <DialogContent style={{}}>
                <div>
                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Driver License</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      {/* onChange={(e)=> handleImageInput(e)} */}
                      <input
                        accept="image/*"
                        type="file"
                        name="DriverLicenseFile"
                        onChange={(e) => AddRiderDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Lassra Card</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="LassraCardFile"
                        onChange={(e) => AddRiderDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>

                  <InputContainer style={{ marginTop: "20px" }}>
                    <p> Passport</p>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="PassportFile"
                        onChange={(e) => AddRiderDocs(e)}
                      />
                    </IconButton>
                  </InputContainer>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="outlined"
                  color="error"
                  onClick={() => handleCloseAddRiderDocs()}
                >
                  Close
                </Button>

                {isAddingRider ? (
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                ) : (
                  <Button
                    style={{ textTransform: "capitalize", fontWeight: "700" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitAddRider()}
                  >
                    Submit
                  </Button>
                )}
              </DialogActions>
            </form>
          </Dialog>
        </div>

        <div name="View Vehicle Modal Parent Node">
          <Dialog open={openViewDriver} onClose={handleCloseViewDriver} fullWidth={true} maxWidth="sm">
            <DialogTitle>Driver Details</DialogTitle>
            <DialogContent>
              <div>
                <p className="mb-3 fs-6">
                  <b>Name:</b> {driverDetails.firstName + " " + driverDetails.lastName}
                </p>
                <p className="mb-3 fs-6">
                  <b>Telephone:</b> {driverDetails.telephone}
                </p>
                <p className="mb-3 fs-6">
                  <b>Email:</b> {driverDetails.email}
                </p>
                <p className="mb-3 fs-6">
                  <b>Address:</b> {driverDetails.address}
                </p>
                <p className="mb-3 fs-6">
                  <b>Vehicle:</b> {
                  driverDetails.vehicleRegNo ? driverDetails.vehicleRegNo : 'No assigned vehicle'
                  }
                </p>
                <p className="mb-3 fs-6">
                  <b>Status</b> {setStatus(driverDetails.status)}
                </p>
                

                {/* <p>Vehicle Number: {driverDetails.firstName}</p>
                <p>Plate Number: {driverDetails.plateNum}</p> */}
              </div>
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewDriver}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleCloseViewDriver}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Edit Rider Modal Parent Node">
          <Dialog open={openEditDriver} onClose={handleCloseEditDriver}>
            <DialogTitle>Edit Driver Details</DialogTitle>
            <DialogContent>
              <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="First Name"
                    id="fullWidth"
                    name="firstName"
                    defaultValue={driverDetails?.firstName}
                    onChange={(e) => handleEditRider(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Last Name"
                    id="fullWidth"
                    name="lastName"
                    defaultValue={driverDetails?.lastName}
                    onChange={(e) => handleEditRider(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Phone Number"
                    id="fullWidth"
                    name="telephone"
                    defaultValue={driverDetails?.telephone}
                    onChange={(e) => handleEditRider(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    name="email"
                    label="Email"
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                    defaultValue={driverDetails?.email}
                    onChange={(e) => handleEditRider(e)}
                  />
                </Box>

                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    name="address"
                    label="Address"
                    id="fullWidth"
                    defaultValue={driverDetails?.address}
                    onChange={(e) => handleEditRider(e)}
                  />
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseEditDriver}
              >
                Close
              </Button>

              {isUpdatingRider ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitEditDriver}
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        {/* Vehicle modal ends */}

        <VehiclesTableContainer>
          <p style={{ fontSize: "1.8em", marginLeft: "10px" }}>
          <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  Riders</p>

          <AddNew>
            <Button
              onClick={() => handleOpenAddRider()}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add Rider
            </Button>
          </AddNew>
          <Stack direction="row">
            <p
              style={{
                fontSize: "1.3em",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Search
            </p>
            <SearchInput type="text" onChange={search} />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {data
                    .filter(
                      (vehicle) =>
                        vehicle.firstName
                          .toLowerCase()
                          .includes(query.toLowerCase()) ||
                        vehicle.lastName
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map((row_, Key) => {
                      return (
                        <tr>
                          <td>{Key + 1}</td>
                          <td>{row_.firstName}</td>
                          <td>{row_.lastName}</td>
                          <td>{row_.telephone}</td>
                          <td>
                            {row_.email}
                          </td>
                          {row_.vehicleRegNo != null
                           ? (
                            <td className="green">Assigned</td>
                          ) : (
                            <td className="yellow">Unassigned</td>
                          )}
                          <td>
                            <Flex>
                              {" "}
                              <div
                                onClick={() => handleOpenEditDriver(row_)}
                                className="green"
                              >
                                <EditIcon />
                              </div>
                              <div
                                onClick={() => handleOpenDeleteRider(row_?.id)}
                                className="red"
                              >
                                <DeleteIcon />
                              </div>
                              <div
                                onClick={() => handleOpenViewDriver(row_)}
                                className="blue"
                              >
                                <VisibilityIcon />
                              </div>
                            </Flex>{" "}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {isLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </VehiclesTableContainer>
      </div>
    </>
  );
};

export default PartnerVehiclePage;
