import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios"

const initialState = {
  dataa: [],
  isLoading: null,
  status: null
}

const token = userObj?.token

export const getAllOrders = createAsyncThunk(
  'partner/getAllOrders',
  async (id, {rejectWithValue}) => {
    try {
      const response = await axios.get(`Partner/orders/new`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response?.data
    } catch (err) {
        return rejectWithValue(err.response.data.title)
    }
  }
)

const getAllOrdersSlice = createSlice({
  name: 'getAllOrders',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllOrders.pending]: (state) => {
      state.status = 'pending';
      state.isLoading = true
    },

    [getAllOrders.fulfilled]: (state, action) => {
      state.status = 'success';
      state.isLoading = false;
      state.data = action.payload
    },
    
    [getAllOrders.rejected]: (state) => {
      state.status = 'failed';
      state.isLoading = false
    }
  }
})

export default getAllOrdersSlice.reducer;