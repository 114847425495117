import { AccountBalanceOutlined, Edit, InfoOutlined, Password } from "@mui/icons-material";
import { Button, FormControl, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { ProfileButton, ProfileForm, ProfileLinkContents, ProfileLinks, ProfileLinksList, ProfileWrapper } from "./profile.styled";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const EditBankInfo = () => {
  
const navigate = useNavigate()
  
  
  return (
        <><NavBar/>
      <div className="main-content main-content-margin-top">
        <ProfileWrapper>
        <ProfileSideBar/>

          <ProfileLinkContents>
            <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
            <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  Bank Account Information</p>
            
            <hr />
<br />
        
            <ProfileForm action="">
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Bank Name"
                  defaultValue='Jaiz Bank'
                  id="fullWidth"
                />
              </FormControl>

              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Account Name"
                  defaultValue='John Doe'
                  id="fullWidth"
                />
              </FormControl>

              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Account Number"
                  defaultValue='20099028383'
                  id="fullWidth"
                />
              </FormControl>

            
              
              <ProfileButton type="submit">Save changes</ProfileButton>
            </ProfileForm>
          </ProfileLinkContents>
        </ProfileWrapper>
      </div>
        </>


// <div className="main-content main-content-margin-top">
//         <ProfileWrapper>
//         <ProfileSideBar/>

//           <ProfileLinkContents>
//             <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
//             <i onClick={() => navigate(-1)}>
//             <ArrowBackIcon /></i>  Bank Account Information</p>
            
//             <hr />
// <br />
//               <ProfileButton type="submit">Save changes</ProfileButton>
//             </ProfileForm>
//           </ProfileLinkContents>
//         </ProfileWrapper>
//       </div>
    )

}

export default EditBankInfo;