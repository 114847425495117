import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isPaying:false,
    status:null,
}

const token = userObj?.token

export const addCard = createAsyncThunk(
    'wallets/addWalletPayment',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.post("/Wallets/AddCustomerCard",data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const addCardSlice = createSlice({
    name: 'addCard',
    initialState,
    reducers: {},
    extraReducers:{
        [addCard.pending]: (state, action) => {
            state.status='pending';
            state.isPaying=true;
        },
        [addCard.fulfilled]: (state, action) => {
            state.status='success';
            state.isPaying=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [addCard.rejected]: (state, action) => {
            state.status='failed';
            state.isPaying=false;
        }
    }
})

export default addCardSlice.reducer;