import React from "react";
import { PhotoCameraOutlined } from "@mui/icons-material";
import NavBar from "../../../components/navbar/navbar.component";
import { useDispatch, useSelector } from "react-redux";
import {
  ProfileWrapper,
  ProfileLinkContents,
  ProfilePhotoContainer,
  ProfilePhotoWrapper,
  ProfilePhotoImg,
  ProfileForm,
  UploadButton,
  ProfileButton,
  EditWrapper
} from "./profile.styled";
import profilePhoto from "../../../assets/images/user.jpg";
import { FormControl, TextField, Button } from "@mui/material";
import { updateCustomer, updatePartner } from "../../../app/features/user";
import ProfileSideBar from "../../../components/ProfileSideBar/profilesidebar.component";
import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


const EditProfile = () => {
  const dispatch = useDispatch();
  const userObj = JSON.parse(localStorage.getItem("user"));
  console.log("user :>>", userObj)
  const [partner, setPartner] = useState(userObj.extra);
  const [customer, setCustomer] = useState(userObj.extra);
  const userRole = userObj?.role[0];

  const [readOnly, setReadOnly] = useState(true)

  const editState = false

  let button;

  if (readOnly === false) {
    button = <ProfileButton>Submit</ProfileButton>
  } else {}
  
  const navigate = useNavigate()

  const changePartnerValue = (key, e) => {
    var _d = partner
    _d[key] = e.target.value
    console.log(_d)
    setPartner(_d)
  }
  const changeCustomerValue = (key, e) => {
    var _d = customer
    _d[key] = e.target.value
    console.log(_d)
    setCustomer(_d)
  }
  const handleUpdatePartner = () => {
    const body = partner
    dispatch(updatePartner(body)).then((res) => {
      const { payload, error } = res;
      console.log("partner updated :>>", payload);
      if (!error) {
        userObj.extra = payload
        localStorage.setItem("user", JSON.stringify(userObj))
        toast.success("Profile updated successfully !");
        setReadOnly((o) => !o)
      } else {
        toast.error(error.message);
        console.log(error);
      }
    });
  };

  const handleUpdateCustomer = () => {
    const body = customer
    console.log(body)
    dispatch(updateCustomer(body)).then((res) => {
      const { payload, error } = res;
      console.log("customer updated :>>", payload);
      if (!error) {
        userObj.extra = payload
        localStorage.setItem("user", JSON.stringify(userObj))
        toast.success("Profile updated successfully !");
        setReadOnly((o) => !o)
      } else {
        toast.error(error.message);
        console.log(error);
      }
    });
  };

  return (
    <>
      <NavBar />

      <div className="main-content main-content-margin-top">
        <ProfileWrapper>
          <ProfileSideBar />
          <ProfileLinkContents>
            <p style={{ fontSize: "1.5em", margin: "8px 0" }}>
            <i onClick={() => navigate(-1)}>
            <ArrowBackIcon /></i>  Profile</p>
            <hr />

              <EditWrapper>
              <Button onClick={() => setReadOnly((o) => !o)} variant="outlined">
              <Edit style={{ color: "#b23934" }} />{" "}
              </Button>
              
              </EditWrapper>

            <ProfilePhotoContainer>
              <ProfilePhotoWrapper>
                <ProfilePhotoImg src={profilePhoto} />
                <UploadButton>
                  <PhotoCameraOutlined />
                </UploadButton>
              </ProfilePhotoWrapper>
              <div>
                <p>{userObj.extra.companyName}</p>
              </div>
            </ProfilePhotoContainer>
            {userRole.toLowerCase() === "partner" && (
              <ProfileForm action="">
                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    defaultValue={userObj.extra.companyName}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                    disabled
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="CAC Number"
                    defaultValue={userObj.extra.rcNumber}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                    disabled
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Name"
                    defaultValue={userObj.extra.contactName}
                    id="fullWidth"
                    onChange={(v) => changePartnerValue('contactName', v)}
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Address"
                    defaultValue={userObj.extra.address}
                    id="fullWidth"
                    onChange={(v) => changePartnerValue('address', v)}
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Email"
                    defaultValue={userObj.user.email}
                    id="fullWidth"
                    InputProps={{
                      readOnly: true
                    }}
                    disabled
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Phone Number"
                    defaultValue={userObj.extra.telephone}
                    id="fullWidth"
                    onChange={(v) => changePartnerValue('telephone', v)}
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>
                {
                  readOnly === false
                  ? <ProfileButton onClick={handleUpdatePartner}>Submit</ProfileButton>
                  : null
                }
              </ProfileForm>
            )}
            {userRole.toLowerCase() === "customer" && (
              <ProfileForm action="">
                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Full name"
                    defaultValue={userObj.user.fullName}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                    disabled
                  />
                </FormControl>


                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Company Address"
                    defaultValue={userObj.extra.address}
                    id="fullWidth"
                    onChange={(v) => changeCustomerValue('address', v)}
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Email"
                    defaultValue={userObj.user.email}
                    id="fullWidth"
                    InputProps={{
                      readOnly: readOnly
                    }}
                    disabled
                  />
                </FormControl>

                <FormControl style={{ width: "100%", margin: "20px 0" }}>
                  <TextField
                    fullWidth
                    label="Contact Phone Number"
                    defaultValue={userObj.extra.phoneNumber}
                    id="fullWidth"
                    onChange={(v) => changeCustomerValue('phoneNumber', v)}
                    InputProps={{
                      readOnly: readOnly
                    }}
                  />
                </FormControl>
                {
                  readOnly === false
                  ? <ProfileButton onClick={handleUpdateCustomer}>Submit</ProfileButton>
                  : null
                }
              </ProfileForm>
            )}
          </ProfileLinkContents>
        </ProfileWrapper>
      </div>
    </>
  );
};

export default EditProfile;
