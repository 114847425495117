import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isSuggestingAddr:false,
    status:null,
}

const token = userObj?.token
//const key = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${destination}&types=address&key=AIzaSyCDRJE8BYBlmHVuqTZl0xyvoV0pKtj_00o&components=country:ng&types=bank|hotel|hospital|school|shopping_mall`

export const suggestAddr = createAsyncThunk(
    'customer/suggestAddr',
    async (destination, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`Order/SuggestAddr?input=${destination}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)

export const suggestDestAddr = createAsyncThunk(
    'customer/suggestAddr',
    async (destination, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`Order/SuggestAddr?input=${destination}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const suggestAddrSlice = createSlice({
    name: 'suggestAddr',
    initialState,
    reducers: {},
    extraReducers:{
        [suggestAddr.pending]: (state, action) => {
            state.status='pending';
            state.isSuggestingAddr=true;
        },
        [suggestAddr.fulfilled]: (state, action) => {
            state.status='success';
            state.isSuggestingAddr=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [suggestAddr.rejected]: (state, action) => {
            state.status='failed';
            state.isSuggestingAddr=false;
        }
    }
})

export default suggestAddrSlice.reducer;