import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Flex } from "../../../components/Table/table.styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import PlaceIcon from "@mui/icons-material/Place";
import NavBar from "../../../components/navbar/navbar.component";
import { Container } from "./orders.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import { Close } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast } from "react-toastify";
import { userObj } from "../../../helper/protectedRoutes";
import { getCities } from "../../../app/features/Customer/getCitiesSlice";
import { getTowns } from "../../../app/features/Customer/getTownsSlice";
import { suggestAddr } from "../../../app/features/Customer/Order/suggestAddressSlice";
import { getPlaceId } from "../../../app/features/Customer/Order/placeIdSlice";
import { calculatePrice } from "../../../app/features/Customer/Order/calculatePaymentSlice";
import { addOrder } from "../../../app/features/Customer/Order/addOrderSlice";
import { customerCards } from "../../../app/features/Customer/wallet/customercardsSlice";
import { addPayment } from "../../../app/features/Customer/wallet/addWalletPayment";
import { addPaymentError } from "../../../app/features/Customer/wallet/addWalletPaymentErrorSlice";
import { PaystackButton } from "react-paystack";

const PackageSchedulePage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const navigate = useNavigate();
  const init_packagePayload = {
    name: "",
    phoneNumber: "",
    email: "",
    recipientAddress: "",
    postalCode: "",
    city: null,
    townId: null,
    originId: null,
    originTownId: null,
    pickup: "",
    pickupLocModel: {
      longitude: 0,
      latitude: 0,
    },
    unit: 0,
    unitWeight: 0,
    weight: 0,
    description: "",
    declaredValue: 0,
    insured: false,
    cashonDelivery: 0,
  };

  const [cities, setCities] = React.useState([]);
  const [originCities, setOriginCities] = React.useState([]);
  const [city, setCity] = React.useState(null);
  const [originId, setOriginId] = React.useState(null);
  const [towns, setTowns] = React.useState([]);
  const [originTowns, setOriginTowns] = React.useState([]);
  const [pickup, setPickup] = React.useState([]);
  const [pickupInput, setPickupInput] = React.useState();
  const [selectedPickup, setSelectedPickup] = React.useState(null);
  const [destInput, setDestInput] = React.useState();
  const [destination, setDestination] = React.useState([]);
  const [selectedDest, setSelectedDest] = React.useState(null);
  const [cashOption, setCashOption] = React.useState("no");
  const [priceData, setPriceData] = React.useState({});
  const [card, setCard] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [payRef, setReference] = React.useState(null);
  const [formData, setFormData] = React.useState();
  const [paymentError, setPaymentError] = React.useState(null);
  const [openSummary, setOpenSummary] = React.useState(false);
  const [openMethod, setOpenMethod] = React.useState(false);
  const [openCard, setOpenCard] = React.useState(false);
  const [cardPay, setCardPay] = React.useState(false);
  const [walletPay, setWalletPay] = React.useState(false);
  const [cashPay, setCashPay] = React.useState(false);
  const [isManual, setManual] = React.useState(0);
  const [rIsManual, setRManual] = React.useState(1);
  const [usePickupManual, setPickupManual] = React.useState(false);
  const [calculatePricePayload, setCalculatePricePayload] =
    React.useState(init_packagePayload);

  useEffect(() => {
    document.title = "Schedule Package Delivery - Customer";
    dispatch(getCities()).then((res) => {
      const { payload } = res;
      setCities(payload);
      setOriginCities(payload);
      console.log("cities", payload);
    });
    setCalculatePricePayload({
      "pickup": user.address
    })
  }, []);

  const { isSuggestingAddr } = useSelector((state) => state.suggestAddress);
  const { cards, isLoadingCards } = useSelector((state) => state.customercards);
  const { isAddingOrder } = useSelector((state) => state.addOrder);
  const isPaying = useSelector((state) => state.addPayment);

  const handleCityChange = (e) => {
    const { name, value } = e.target;
    console.log("City >>", name, value);
    setCalculatePricePayload({
      ...calculatePricePayload,
      [name]: value,
    });
    const city = cities.find((c) => c.id === value);
    setCity(city);
    console.log("cityID", city);
    dispatch(getTowns(value)).then((res) => {
      const { payload } = res;
      const towns = [...payload].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setTowns(towns);
      console.log("Towms", payload);
    });
  };
  const handleOriginChange = (e) => {
    const { name, value } = e.target;
    console.log("Origin >>", name, value);
    setCalculatePricePayload({
      ...calculatePricePayload,
      [name]: value,
    });
    setOriginId(value);
    console.log("originID", originId);
    dispatch(getTowns(value)).then((res) => {
      const { payload } = res;
      const towns = [...payload].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setOriginTowns(towns);
      console.log("Towms", payload);
    });
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setCalculatePricePayload({
      ...calculatePricePayload,
      [name]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCalculatePricePayload({
      ...calculatePricePayload,
      [name]: value,
    });
  };

  const handleSuggestPickupAddress = (e) => {
    const { value } = e.target;
    setPickupInput(value?.length);
    setSelectedPickup(value);
    setCalculatePricePayload({
      ...calculatePricePayload,
      pickup: value
    });
    if (value?.length > 5) {
      dispatch(suggestAddr(value)).then((res) => {
        const { error, payload } = res;
        if (!error) {
          setPickup(payload);
          console.log("pickups", pickup);
        }
      });
    }
  };
  const handleSuggestAddress = (e) => {
    const { value } = e.target;
    setDestInput(value?.length);
    setPickup([]);
    setSelectedDest(value)
    setCalculatePricePayload({
      ...calculatePricePayload,
      recipientAddress: value,
    });
    if (value?.length > 5) {
      dispatch(suggestAddr(value)).then((res) => {
        const { error, payload } = res;
        if (!error) {
          setDestination(payload);
        }
      });
    }
  };

  const handlePickupSelect = (p) => {
    dispatch(getPlaceId(p?.place_id)).then((res) => {
      const { payload, error } = res;
      if (!error) {
        setSelectedPickup(payload?.formatted_address);
        setCalculatePricePayload({
          ...calculatePricePayload,
          pickup: payload?.formatted_address,
          pickupLocModel: {
            longitude: payload?.geometry.location["lng"],
            latitude: payload?.geometry.location["lat"],
          },
        });
      }
    });

    setPickup([]);
  };
  const handleDestSelect = (d) => {
    dispatch(getPlaceId(d?.place_id)).then((res) => {
      const { payload, error } = res;
      if (!error) {
        setSelectedDest(payload?.formatted_address);
        setCalculatePricePayload({
          ...calculatePricePayload,
          recipientAddress: payload?.formatted_address,
        });
      }
    });

    setDestination([]);
  };
  const allowManualPickup = (v) => {
    setPickupManual(true);
    setSelectedPickup(v)
  }
  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    const unit = value;
    if (unit > 0 && calculatePricePayload.unitWeight > 0) {
      const totalWeight = calculatePricePayload.unitWeight * unit;
      console.log("totalWeight >>", totalWeight);
      setCalculatePricePayload({
        ...calculatePricePayload,
        unit: value,
        weight: totalWeight,
      });
    } else {
      setCalculatePricePayload({
        ...calculatePricePayload,
        [name]: value,
      });
    }
  };
  const handleWeightChange = (e) => {
    const { name, value } = e.target;
    const weight = value;
    if (weight > 0 && calculatePricePayload.unit > 0) {
      const totalWeight = calculatePricePayload.unit * weight;
      console.log("totalWeight >>", totalWeight);
      setCalculatePricePayload({
        ...calculatePricePayload,
        unitWeight: value,
        weight: totalWeight,
      });
    } else {
      setCalculatePricePayload({
        ...calculatePricePayload,
        [name]: value,
      });
    }
  };

  const handleSummaryOpen = () => {
    setOpenSummary(true);
  };
  const handleSummaryClose = () => {
    setOpenSummary(false);
  };

  const handleMethodOpen = () => {
    handleFormData();
    handleSummaryClose();
    setOpenMethod(true);
  };
  const handleMethodClose = () => {
    setOpenMethod(false);
    setOpenSummary(true);
  };

  const handleWalletOpen = () => {
    if (user.wallet.currentBalance > priceData.orderPrice?.maxValue || user.customerAccount) {
      setWalletPay(true);
      var form = formData;
      form.append("Shipment.ShipmentPayment.PaymentMethod", 'Wallet')
      setFormData(form)
      setOpenMethod(false);
    } else {
      toast.error("Oops! You do not have enough wallet balance for this order");
    }
  };
  const handleWalletClose = () => {
    setWalletPay(false);
    setOpenMethod(true);
  };

  const handleOpenCardPay = () => {
    dispatch(customerCards());
    setOpenMethod(false);
    setOpenCard(true);
  };
  const submitPayment = (reference) => {
    setReference(reference);
    if (payRef) {
      const body = {
        walletId: user?.wallet?.id,
        amount: parseInt(priceData?.orderPrice?.maxValue * 100),
        reference: payRef,
        paymentDate: new Date(),
        customerCard: {
          customerId: user.id,
          cardNumber: "Temporary Card - ****",
          cardName: "authorization.account_name",
        },
      };
      dispatch(addPayment(body)).then((res) => {
        const { payload, error } = res;
        setPaymentError(error);
        console.log("payment :>>", payload);
        if (!error) {
          var form = formData;
          form.append("Shipment.ShipmentPayment.PaymentMethod", 'Wallet')
          dispatch(addOrder(form)).then((res) => {
            const { error } = res;
            if (!error) {
              toast.success("Package delivery schedule created successfully !");
              setCashPay(false)
              setOpenCard(false)
              setWalletPay(false)
              setOpenSummary(false)
              navigate("/dashboard/customer/orders");
            }
          });
        } else {
          toast.error(paymentError.message);
          console.log(error);
        }
      });
    }
  };
  const publicKey = "pk_live_a08339a8f51fd52f144e39bded503c3059aa66f9";
  const handlePaystackPayClick = () => {
    let payConfig = {
      email: user.email,
      amount: amount,
      metadata: {
        name: user.firstName + user.lastName,
        phone: user.phoneNumber,
      },
      publicKey,
      onSuccess: ({ reference }) => {
        if (reference) {
          submitPayment(reference);
        } else {
          handlePaymentError();
        }
      },

      onClose: () => {
        toast.error("Payment gateway unexpectedly closed");
      },
      onError: () => {
        handlePaymentError();
      }
    };
    console.log("setAmount", amount);
    console.log("payConfig", payConfig);
    let el = document.querySelector(".paystack_botton_");
    return el.click();
  };
  const handleCloseCardPay = () => {
    setOpenCard(false);
    setOpenMethod(true);
  };

  const handlePaymentError = () => {
    const body = {
      walletId: user.wallet.id,
      amount: parseFloat(priceData?.orderPrice?.maxValue),
      paymentDate: new Date(),
      createdAt: new Date(),
      createdBy: 'Web'
    }
    dispatch(addPaymentError(body))
  }

  const handleCardOpen = () => {
    setCardPay(true);
    setOpenCard(false);
  };
  const handleCardClose = () => {
    setCardPay(false);
    setOpenCard(true);
  };

  const handleCashOpen = () => {
    setOpenMethod(false);
    var form = formData;
    form.append("Shipment.ShipmentPayment.PaymentMethod", 'Cash')
    setFormData(form)
    setCashPay(true);
  };
  const handleCashClose = () => {
    setCashPay(false);
    setOpenMethod(true);
  };

  const handleManualOption = (value) => {
    setManual(value);
  };

  const handleRManualOption = (value) => {
    setRManual(value);
  };

  const useCardPayment = () => {
    const body = {
      walletId: user?.wallet?.id,
      amount: priceData.orderPrice?.maxValue,
      paymentDate: new Date(),
      customerCardId: card,
      status: 0,
    };
    dispatch(addPayment(body)).then((res) => {
      const { payload, error } = res;
      setPaymentError(error);
      console.log("payment :>>", payload);
      if (!error) {
        formData.append("PaymentType", 0)
        dispatch(addOrder(formData)).then((res) => {
          const { error } = res;
          if (!error) {
            toast.success("Package delivery schedule created successfully !");
            navigate("/dashboard/customer/orders");
            setCashPay(false)
            setOpenCard(false)
            setWalletPay(false)
            setOpenSummary(false)
          }
        });
      } else {
        toast.error(paymentError.message);
        console.log(error);
      }
    });
  };

  const handlePriceCalculate = () => {
    if(calculatePricePayload.description == ''){
      alert("Content Description cannot be empty");
      return
    }
    if(calculatePricePayload.insured == undefined){
      alert("Insurance cannot be empty");
      return
    }
    const form = new FormData();
    form.append("CustomerId", user.id);
    form.append("VehicleTypeId", 8);
    form.append("VehicleSizeId", 9);
    form.append("Pickup", calculatePricePayload.pickup);
    form.append(
      "PickupLocModel.Longitude",
      !calculatePricePayload.pickupLocModel ? 0.00 : calculatePricePayload.pickupLocModel?.longitude
    );
    form.append(
      "PickupLocModel.Latitude",
      !calculatePricePayload.pickupLocModel ? 0.00 : calculatePricePayload.pickupLocModel?.latitude
    );
    form.append("Shipment.SenderAccountNo", user.wallet.id);
    form.append("Shipment.SenderName", user.firstName + " " + user.lastName);
    form.append("Shipment.SenderAddress", calculatePricePayload.pickup);
    form.append("Type", 1);
    form.append("Shipment.SBUId", 5);
    form.append("Shipment.OriginId", calculatePricePayload.originId);
    form.append("Shipment.CityId", calculatePricePayload.city);
    form.append("Shipment.TownId", calculatePricePayload.townId);
    form.append(
      "Shipment.ContentDescription",
      calculatePricePayload.description
    );
    form.append("Shipment.Weight", calculatePricePayload.weight);
    form.append("Shipment.Unit", calculatePricePayload.unit);
    form.append("Shipment.Recipient.Email", calculatePricePayload.email);
    form.append("Shipment.Insured", calculatePricePayload.insured);
    form.append("Shipment.OriginTownId", calculatePricePayload.originTownId);
    form.append("Shipment.Recipient.RecipientName", calculatePricePayload.name);
    form.append(
      "Shipment.Recipient.PhoneNumber",
      calculatePricePayload.phoneNumber
    );
    form.append(
      "Shipment.Recipient.StreetAddress",
      calculatePricePayload.recipientAddress
    );
    form.append("Shipment.Recipient.City", city.name);
    form.append("Shipment.Recipient.PostalCode", calculatePricePayload.postalCode);
    
    if (calculatePricePayload.declaredValue > 100000 && !calculatePricePayload.insured) {
      const userConfirmation = window.confirm("RedStar Express will not be liable in the event of damage; with the sum refundable only ₦100,000. Do you wish to proceed");
      if (userConfirmation) {
        console.log("form", form)
        dispatch(calculatePrice(form)).then((res) => {
          const { payload } = res;
          setPriceData(payload);
          console.log("priceData", priceData, payload);
          setAmount(parseFloat(payload?.priceData?.orderPrice?.maxValue * 100));
          handleSummaryOpen();
        });
      } else {
        return;
      }
    } else {
      console.log("form", form)
      dispatch(calculatePrice(form)).then((res) => {
        const { payload, error } = res;
        if (!error) {
          setPriceData(payload);
          console.log("priceData", priceData, payload);
          setAmount(parseFloat(payload?.priceData?.orderPrice?.maxValue * 100));
          handleSummaryOpen();
        } else {
          toast.error(payload)
        }
      });
    }
  };

  const handleFormData = () => {
    const form = new FormData();
    form.append("CustomerId", user.id);
    form.append("VehicleTypeId", 8);
    form.append("VehicleSizeId", 9);
    form.append("Pickup", calculatePricePayload.pickup);
    form.append(
      "PickupLocModel.Longitude",
      !calculatePricePayload.pickupLocModel ? 0.00 : calculatePricePayload.pickupLocModel?.longitude
    );
    form.append(
      "PickupLocModel.Latitude",
      !calculatePricePayload.pickupLocModel ? 0.00 : calculatePricePayload.pickupLocModel?.latitude
    );
    form.append("OrderPrice.MinValue", priceData.orderPrice?.minValue);
    form.append("OrderPrice.MaxValue", priceData.orderPrice?.maxValue);
    form.append("OrderPrice.ActualValue", priceData.orderPrice?.actualValue);
    form.append("Shipment.SenderAccountNo", user.wallet.id);
    form.append("Shipment.SenderName", user.firstName + " " + user.lastName);
    form.append("Shipment.SenderAddress", calculatePricePayload.pickup);
    form.append("Type", 1);
    form.append("Shipment.SBUId", 5);
    form.append("LocationType", 0);
    form.append("Shipment.OriginId", calculatePricePayload.originId);
    form.append("Shipment.CityId", calculatePricePayload.city);
    form.append("Shipment.TownId", calculatePricePayload.townId);
    form.append(
      "Shipment.ContentDescription",
      calculatePricePayload.description
    );
    form.append("Shipment.Weight", calculatePricePayload.weight);
    form.append("Shipment.Unit", calculatePricePayload.unit);
    form.append("Shipment.Recipient.Email", calculatePricePayload.email);
    form.append("Shipment.Insured", calculatePricePayload.insured);
    form.append("Shipment.OriginTownId", calculatePricePayload.originTownId);
    form.append("Shipment.Recipient.RecipientName", calculatePricePayload.name);
    form.append(
      "Shipment.Recipient.PhoneNumber",
      calculatePricePayload.phoneNumber
    );
    form.append(
      "Shipment.Recipient.StreetAddress",
      calculatePricePayload.recipientAddress
    );
    form.append("Shipment.Recipient.City", city.name);
    form.append("Shipment.Recipient.PostalCode", calculatePricePayload.postalCode ? calculatePricePayload.postalCode : '');
    form.append(
      "Shipment.ShipmentPayment.InsuranceValue",
      priceData.shipment?.shipmentPayment?.insuranceValue
    );
    form.append(
      "Shipment.shipmentPayment.OnforwardingCharge",
      priceData.shipment?.shipmentPayment?.onforwardingCharge
    );
    form.append(
      "Shipment.ShipmentPayment.ServicePrice",
      priceData.shipment?.shipmentPayment?.servicePrice
    );
    form.append(
      "Shipment.shipmentPayment.TotalAmount",
      priceData.shipment?.shipmentPayment?.totalAmount
    );
    form.append(
      "Shipment.ShipmentPayment.CashonDelivery",
      calculatePricePayload.cashonDelivery ? calculatePricePayload.cashonDelivery : 0
    );
    form.append(
      "Shipment.ShipmentPayment.DeclaredValue",
      calculatePricePayload.declaredValue ? calculatePricePayload.declaredValue : 0
    );
    form.append(
      "Shipment.ShipmentPayment.VAT",
      priceData.shipment?.shipmentPayment?.vat
    );
    form.append("Shipment.ShipmentPayment.PaidAmount", priceData?.orderPrice?.maxValue);
    form.append(
      "Shipment.CommercialDetails",
      priceData.shipment?.commercialDetails
    );

    setFormData(form);
    console.log("Raw formData", form);
    console.log("formData", formData);
  };

  const handleWalletAddOrder = () => {
    formData.append("PaymentType", 0)
    dispatch(addOrder(formData)).then((res) => {
      const { error } = res;
      setPaymentError(error);
      if (!error) {
        toast.success("Package delivery schedule created successfully !");
        setCashPay(false)
        setOpenCard(false)
        setWalletPay(false)
        setOpenSummary(false)
        navigate("/dashboard/customer/orders");
      } else {
        toast.error(paymentError.title);
      }
    });
  };

  const handleCashAddOrder = () => {
    formData.append("PaymentType", 1)
    dispatch(addOrder(formData)).then((res) => {
      const { error } = res;
      setPaymentError(error);
      if (!error) {
        toast.success("Package delivery schedule created successfully !");
        setCashPay(false)
        setOpenCard(false)
        setWalletPay(false)
        setOpenSummary(false)
        navigate("/dashboard/customer/orders");
      } else {
        toast.error(paymentError.title);
      }
    });
  };

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <Container>
          <Heading size="1.8rem">Schedule a Package</Heading>
          <Card
            sx={{
              marginBottom: "12px",
            }}
            variant="outlined"
          >
            <CardContent>
              <Box sx={{ m: 1 }}>
                <Heading size="1.2rem">Pickup Information</Heading>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                    <TextField
                      fullWidth
                      sx={{ m: 1 }}
                      label="Pickup Address"
                      id="outlined-size-small"
                      value={selectedPickup}
                      size="small"
                      onChange={(e) => handleSuggestPickupAddress(e)}
                    />
                  {pickupInput > 5 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (pickup.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {pickup.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handlePickupSelect(row)}
                                >
                                  <PlaceIcon style={{ marginRight: "10px" }} />
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Origin City
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="originId"
                      value={calculatePricePayload.originId}
                      label="Origin City"
                      onChange={(e) => handleOriginChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {originCities?.map((c, key) => (
                        <MenuItem value={c.id} key={key}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Origin Town
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="originTownId"
                      value={calculatePricePayload.originTownId}
                      label="Origin Town"
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {originTowns?.map((t, key) => (
                        <MenuItem value={t.id} key={key}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginBottom: "12px",
            }}
            variant="outlined"
          >
            <CardContent>
              <Box sx={{ m: 1 }}>
                <Heading size="1.2rem">Recipient Information</Heading>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Name"
                    id="outlined-size-small"
                    name="name"
                    value={calculatePricePayload.name}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Phone Number"
                    id="outlined-size-small"
                    name="phoneNumber"
                    value={calculatePricePayload.phoneNumber}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                        fullWidth
                        sx={{ m: 1 }}
                        label="Delivery Address"
                        id="outlined-size-small"
                        value={selectedDest}
                        size="small"
                        onChange={(e) => handleSuggestAddress(e)}
                      />
                  <TextField
                    sx={{ m: 1, width: "25ch" }}
                    label="Landmark"
                    id="outlined-size-small"
                    value={calculatePricePayload.postalCode}
                    name="postaclCode"
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  {destInput > 5 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (destination.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {destination.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleDestSelect(row)}
                                >
                                  <PlaceIcon style={{ marginRight: "10px" }} />
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Email"
                    id="outlined-size-small"
                    name="email"
                    value={calculatePricePayload.email}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <FormControl sx={{ m: 1, width: "25ch" }} size="small">
                    <InputLabel id="demo-select-small-label">City</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="city"
                      value={calculatePricePayload.city}
                      label="City"
                      onChange={(e) => handleCityChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {cities?.map((c, key) => (
                        <MenuItem value={c.id} key={key}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "25ch" }} size="small">
                    <InputLabel id="demo-select-small-label">Town</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="townId"
                      value={calculatePricePayload.townId}
                      label="Town"
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      {towns?.map((t, key) => (
                        <MenuItem value={t.id} key={key}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              marginBottom: "12px",
            }}
            variant="outlined"
          >
            <CardContent>
              <Box sx={{ m: 1 }}>
                <Heading size="1.2rem">Package Information</Heading>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Quantity"
                    id="outlined-size-small"
                    name="unit"
                    value={calculatePricePayload.unit}
                    size="small"
                    onChange={(e) => handleUnitChange(e)}
                  />
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Weight"
                    id="outlined-size-small"
                    name="unitWeight"
                    value={calculatePricePayload.unitWeight}
                    size="small"
                    onChange={(e) => handleWeightChange(e)}
                  />
                  <TextField
                    fullWidth
                    sx={{ m: 1 }}
                    label="Item Description"
                    id="outlined-size-small"
                    name="description"
                    value={calculatePricePayload.description}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Declared Value (Naira)"
                    id="outlined-size-small"
                    name="declaredValue"
                    value={calculatePricePayload.declaredValue}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <FormControl sx={{ m: 1, width: "50ch" }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Insurance?
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      name="insured"
                      value={calculatePricePayload.insured}
                      label="Insurance?"
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <MenuItem value={null}>
                        <em>-- Select --</em>
                      </MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Total Quantity"
                    id="outlined-size-small"
                    name="unit"
                    value={calculatePricePayload.unit}
                    size="small"
                    disabled
                    onChange={(e) => handleInputChange(e)}
                  />
                  <TextField
                    sx={{ m: 1, width: "50ch" }}
                    label="Total Weight"
                    name="weight"
                    value={calculatePricePayload.weight}
                    id="outlined-size-small"
                    size="small"
                    disabled
                    onChange={(e) => handleInputChange(e)}
                  />
                  <Box>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Would you like the rider to collect any fee upon delivery?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={cashOption}
                      row
                      onChange={(e) => setCashOption(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <Box
                      style={{ marginTop: "20px" }}
                      sx={{
                        width: 500,
                        maxWidth: "100%",
                      }}
                    >
                      {cashOption === "yes" ? (
                        <TextField
                          fullWidth
                          label="Amount"
                          name="cashonDelivery"
                          size="small"
                          value={calculatePricePayload.cashonDelivery}
                          id="fullWidth"
                          onChange={(e) => handleInputChange(e)}
                          // defaultValue={vehicleDetails?.vehicleNum}
                        />
                      ) : (
                        <> </>
                      )}
                    </Box>
                  </Box>
                </div>
              </Box>
            </CardContent>
          </Card>
          <Box
            sx={{
              marginTop: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={() => handlePriceCalculate()}
            >
              Continue
            </Button>
          </Box>
        </Container>

        <div name="Payment summary">
          <Dialog
            maxWidth={"xs"}
            open={openSummary}
            onClose={handleSummaryClose}
          >
            <Box
              style={{ marginTop: "10px", marginBottom: "10px" }}
              sx={{
                maxWidth: "auto",
                display: "flex",
              }}
            >
              <Button style={{}} onClick={handleSummaryClose} color="error">
                <KeyboardBackspaceIcon />
              </Button>
              <DialogTitle style={{ paddingLeft: "0" }}>
                Payment Information
              </DialogTitle>
            </Box>
            <Box
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                borderTop: "1px solid red",
                borderBottom: "1px solid red",
              }}
              sx={{
                borderTop: "1px solid error",
              }}
            >
              <div style={{ textAlign: "center", padding: "8px" }}>
                <p className="mb-1" style={{ fontSize: "12px" }}>
                  Amount
                </p>
                <p
                  className="mb-1"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  {
                    "₦" +
                    priceData?.orderPrice?.maxValue
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </p>
                <span style={{ fontSize: "10px" }}>
                  {new Date().toDateString()}
                </span>
              </div>
            </Box>
            <DialogContent>
              <Box>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Onforwarding Fee</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {"₦ " +
                      priceData?.shipment?.shipmentPayment?.onforwardingCharge
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Insurance Charge</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {"₦ " +
                      priceData?.shipment?.shipmentPayment?.insuranceValue
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Service Charge</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {"₦ " +
                      priceData?.shipment?.shipmentPayment?.servicePrice
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Discount</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {"₦ " +
                      priceData?.shipment?.shipmentPayment?.discount
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>VAT</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {"₦ " +
                      priceData?.shipment?.shipmentPayment?.vat
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleMethodOpen}
              >
                Make payment
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Payment method Node">
          <Dialog open={openMethod} onClose={handleMethodClose}>
            <Box
              style={{ marginTop: "10px", marginBottom: "-40px" }}
              sx={{
                maxWidth: "auto",
              }}
            >
              <Button
                style={{ float: "right" }}
                onClick={handleMethodClose}
                color="error"
              >
                <Close />
              </Button>
            </Box>
            <DialogTitle style={{ textAlign: "center" }}>
              Select Payment Option
            </DialogTitle>
            <DialogContent>
              <Box
                style={{ marginTop: "10px" }}
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                {/* <Flex style={{justifyContent:'space-evenly'}}> */}
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => handleWalletOpen()}
                >
                  Wallet
                </Button>

                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => handleOpenCardPay()}
                >
                  Card
                </Button>

                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => handleCashOpen()}
                >
                  Pay on Delivery
                </Button>

                {/* </Flex> */}
              </Box>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>

        <div name="Select Card">
          <Dialog open={openCard} onClose={handleCloseCardPay}>
            <DialogTitle>Select prefered card below</DialogTitle>

            {isLoadingCards ? (
              <Button
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  border: "none",
                }}
                variant="outlined"
                color="primary"
              >
                <CircularProgress />
              </Button>
            ) : (
              <DialogContent>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  {cards.length > 0 ? (
                    <FormControl style={{ width: "100%", margin: "20px 0" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Payment option
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        //   value={assignDriverProperty.driver}
                        name="driver"
                        label="Select driver"
                        onChange={(e) => setCard(e.target.value)}
                        //   onChange={handleAssignDriverChange}
                      >
                        {cards.map((card, key) => {
                          return (
                            <MenuItem key={key} value={card.id}>
                              {"**** **** **** " +
                                card.cardNumber.slice(-4) +
                                " -- " +
                                card.bank}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <p>No cards currently associated with this account</p>
                  )}
                </Box>

                <Button
                  variant="outlined"
                  onClick={() => handlePaystackPayClick()}
                >
                  Add new card
                </Button>
                <div style={{ display: "none" }}>
                  <PaystackButton
                    className="paystack_botton_"
                    id="paystack_botton_"
                  />
                </div>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseCardPay}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                disabled={card === null}
                color="primary"
                onClick={handleCardOpen}
              >
                Pay now
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Card Pay">
          <Dialog open={cardPay} onClose={handleCardClose}>
            <DialogTitle>Order Summary</DialogTitle>
            <DialogContent>
              <Box>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Recipient</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.recipientName}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>City</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.city}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Package</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.contentDescription}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Payment Type</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    Card
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Amount</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {
                    "₦" +
                    priceData?.orderPrice?.maxValue
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCardClose}
              >
                No
              </Button>
              {isAddingOrder || isPaying ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                // </Button>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={useCardPayment}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Wallet Pay">
          <Dialog open={walletPay} onClose={handleWalletClose}>
            <DialogTitle>Order Summary</DialogTitle>
            <DialogContent>
              <Box>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Recipient</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.recipientName}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>City</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.city}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Package</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.contentDescription}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Payment Type</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    Wallet
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Amount</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {
                    "₦" +
                    priceData?.orderPrice?.maxValue
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleWalletClose}
              >
                No
              </Button>
              {isAddingOrder ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={handleWalletAddOrder}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>

        <div name="Cash Pay">
          <Dialog open={cashPay} onClose={handleCashClose}>
            <DialogTitle>Order Summary</DialogTitle>
            <DialogContent>
              <Box>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Recipient</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.recipientName}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>City</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.recipient.city}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Package</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {priceData?.shipment?.contentDescription}
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Payment Type</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    Cash
                  </p>
                </div>
                <div className="">
                  <span style={{ fontSize: "12px" }}>Amount</span>
                  <p
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    className=""
                  >
                    {
                    "₦" +
                    priceData?.orderPrice?.maxValue
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCashClose}
              >
                No
              </Button>
              {isAddingOrder ? (
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    border: "none",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <CircularProgress />
                </Button>
              ) : (
                // </Button>
                <Button
                  style={{ textTransform: "capitalize", fontWeight: "700" }}
                  variant="contained"
                  color="primary"
                  onClick={handleCashAddOrder}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default PackageSchedulePage;
