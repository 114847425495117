import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    isCancelling:false,
    status:null,
}

const token = userObj?.token

export const cancelOrder = createAsyncThunk(
    'order/cancelOrder',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`/Order/cancelOrder/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const cancelOrderSlice = createSlice({
    name: 'cancelOrder',
    initialState,
    reducers: {},
    extraReducers:{
        [cancelOrder.pending]: (state, action) => {
            state.status='pending';
            state.isCancelling=true;
        },
        [cancelOrder.fulfilled]: (state, action) => {
            state.status='success';
            state.isCancelling=false;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [cancelOrder.rejected]: (state, action) => {
            state.status='failed';
            state.isCancelling=false;
        }
    }
})

export default cancelOrderSlice.reducer;