import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    orderDetails:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const trackOrder = createAsyncThunk(
    'order/trackOrder',
    async (no, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`/Order/tracking/${no}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const trackOrderSlice = createSlice({
    name: 'trackOrder',
    initialState,
    reducers: {},
    extraReducers:{
        [trackOrder.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [trackOrder.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.orderDetails = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [trackOrder.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default trackOrderSlice.reducer;