import { Outlet, Navigate } from "react-router-dom";

export const userObj = JSON.parse(localStorage.getItem('user'));
const PrivateRoutes = () => {
    // let isAuthenticated = {'token':false}
    return(
        userObj?.token ? <Outlet/> : <Navigate to='/login'/>
    )
}

export default PrivateRoutes;