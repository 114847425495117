import styled from "styled-components";

const PTag = styled.p`
  /* Adapt the colors based on primary prop */
  color: ${props => props.primary ? props.primary : "black"};

`;

export const Heading = styled.p`
  /* Adapt the colors based on primary prop */
  font-size: ${props => props.size ? props.size : "1.2em"};
  font-weight:${props => props.bold && "bold"};
`;
