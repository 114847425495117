import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
  data: [],
  isAdding: false,
  status: null,
};

const token = userObj?.token;

export const generateRcs = createAsyncThunk(
  "pickups/generateRcs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/Order/generateRCS`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data;
    } catch (err) {
      console.log(err.response);
      return rejectWithValue(err.response.data[0]);
    }
  }
);

const generateRcsSlice = createSlice({
  name: "generateRcs",
  initialState,
  reducers: {},
  extraReducers: {
    [generateRcs.pending]: (state, action) => {
      state.status = "pending";
      state.isAdding = true;
    },
    [generateRcs.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAdding = false;
      state.data = action.payload;
      // localStorage.setItem('user', JSON.stringify(action.payload))
    },

    [generateRcs.rejected]: (state, action) => {
      state.status = "failed";
      state.isAdding = false;
    },
  },
});

export default generateRcsSlice.reducer;
