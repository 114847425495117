import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
  data: [],
  isLoading: null,
  status: null
}

const token = userObj?.token

export const getOrdersFinance = createAsyncThunk(
  'partner/getOrdersFinance',
  async (id, {rejectWithValue}) => {
    try {
      const response = await axios.get(`Partner/orders/finance/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response?.data
    } catch (err) {
        return rejectWithValue(err.response.data.title)
    }
  }
)

const getOrdersFinanceSlice = createSlice({
  name: 'getOrdersFinance',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrdersFinance.pending]: (state) => {
      state.status = 'pending';
      state.isLoading = true
    },
    
    [getOrdersFinance.fulfilled]: (state, action) => {
      state.status = 'success';
      state.isLoading = false;
      state.data = action.payload;
    },

    [getOrdersFinance.rejected]: (state) => {
      state.status = 'failed';
      state.isLoading = false
    }
  }
})

export default getOrdersFinanceSlice.reducer;