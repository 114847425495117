import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';

const initialState = {
    data:[],
    isAdding:null,
    status:null,
    error:null
}

const token = userObj?.token


// headers: {
//     Authorization: 'Bearer ' + varToken
//   }

export const addVehicle = createAsyncThunk(
    'partner/addVehicle',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.post(`${baseUrl}/Partner/addvehicle`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const addVehicleSlice = createSlice({
    name: 'addVehicle',
    initialState,
    reducers: {},
    extraReducers:{
        [addVehicle.pending]: (state, action) => {
            state.status='pending';
            state.isAdding=true;
        },
        [addVehicle.fulfilled]: (state, action) => {
            state.status='success';
            state.isAdding=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [addVehicle.rejected]: (state, action) => {
            state.status='failed';
            state.isAdding=false;
            state.error = action.error
        }
    }
})

export default addVehicleSlice.reducer;