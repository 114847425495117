import React, { useState } from "react";
import "./auth.css";
import AuthBg from "../../assets/images/auth-bg.jpg";
import RedSpeedLogo from "../../assets/images/Redspeed-black.png";
import {
  Remember,
  Section,
  ImgContainer,
  BgImage,
  ContentContainer,
  AuthFormContainer,
  InputContainer,
  Input,
  Label,
  CheckBox,
  Button,
  Brand,
  BrandImage,
} from "./auth.styled";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useDispatch, useSelector } from "react-redux";
import { registerPartner } from "../../app/features/Partner/registerSlice";
import { CircularProgress } from "@mui/material";

const initialValue = {
  "cacDocumentFile": null,
  "taxFormFile":null,
  "tinCertificateFile":null
}

const DocumentUpload = () => {
  const [docFiles, setDocFiles] = useState(initialValue)
  const [registerError, setRegisterError] = useState({
    display:'none',
    message:''
})
  const [data, setData] = useState()
  const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
  const bankInfo = JSON.parse(localStorage.getItem('bankInfo'));
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleImageInput = (e) => {
    const {name, files} = e.target
    setDocFiles({
      ...docFiles,
      [name]: files[0]
    })
    console.log(docFiles)
  }

  const { isLoading, status} = useSelector((state) => state.partnerRegister)
  
  const handleSubmit = (e)=> {
    e.preventDefault()
    const data = {
      ...personalInfo,
      ...bankInfo,
      ...docFiles,
    }
    
    try{
      dispatch(registerPartner(data)).then((res)=>{
          let {payload, error} = res
          {
              if(error){
                localStorage.setItem('error-display', JSON.stringify('block'))
                localStorage.setItem('error-message', JSON.stringify(payload))
                console.log('payload', payload)
                console.log('error', error)
                // navigate('/register/partner')
              }else(
                navigate(`/register/partner/verify-email?email=${personalInfo?.email}`)
              )

          }
         
      })
    } catch(err){
        return err
    }
    
  }

  return (
    <>
      <Section>
        <ImgContainer>
          <BgImage src={AuthBg} alt="" />
        </ImgContainer>
        <ContentContainer>
          <AuthFormContainer>
            <Brand>
              <BrandImage src={RedSpeedLogo} />
            </Brand>
            
              <hr />
              <br />
              <h2>Document Information</h2>
              <p className="margin-top-minus-5 margin-bottom-20">
                Upload the pictures of the following documents
              </p>
<form onSubmit={(e)=> handleSubmit(e)}>
              <InputContainer>
                <p>Customer Acquisition Cost (CAC) Form</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input accept="image/*" type="file" name="cacDocumentFile" onChange={(e)=> handleImageInput(e)} />
                </IconButton>
              </InputContainer>

              <InputContainer>
                <p>Tax Form</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input accept="image/*" type="file" name="taxFormFile" onChange={(e)=> handleImageInput(e)} />
                </IconButton>
              </InputContainer>

              <InputContainer>
                <p>TIN Certificate</p>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input accept="image/*" type="file" name="tinCertificateFile" onChange={(e)=> handleImageInput(e)} />
                </IconButton>
              </InputContainer>

              <InputContainer>
              {
                  isLoading ?
                  <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                  :
                  <Button type='submit'>Submit</Button>
              }         
              </InputContainer>

              
                        </form>

            
          </AuthFormContainer>
        </ContentContainer>
      </Section>
    </>
  );
};

export default DocumentUpload;
