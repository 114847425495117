import React from "react";
import NavBar from "../../../components/navbar/navbar.component";
import { AddNew } from "../../Partner-Pages/Vehicle/vehicles.styled";
import { Table,
    Flex,
    TableWrapper,
    TableContainer, SearchInput, } from "../../../components/Table/table.styled";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Alert_ from "../../../helper/alert/alert";
import { CircularProgress, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Heading } from "../../../components/Tags/tags.styled";
import { Close, SearchOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vehicleTypes } from "../../../app/features/Partner/vehicles/vehicleExternals/vehicleTypes";
import { suggestAddr } from "../../../app/features/Customer/Order/suggestAddressSlice";
import { userObj } from "../../../helper/protectedRoutes";
import { openOrder } from "../../../app/features/createOrderModal/createordermodalSlice";
import CreateOrderModal from "../../../components/createOrder/createOrderModal.component";


const VehicleTypesCustomer = () => {
  const dispatch = useDispatch();

  const id = userObj?.extra.id;
  const { isSuggestingAddr } = useSelector((state) => state.suggestAddress);
  const { vehicleSizeIsLoading, vehicleSizeData } = useSelector((state) => state.vehicleSizes);

  const [data, setData] = React.useState([]);
  const [createOrderData, setCreateOrderData] = React.useState({
    CustomerId : id,
    VehicleTypeId: null,
    VehicleSizeId: null
  });
  const [openEditVehicle, setOpenEditVehicle] = React.useState(false);
  const [openDeleteVehicle, setOpenDeleteVehicle] = React.useState(false);
  const [openViewVehicle, setOpenViewVehicle] = React.useState(false);
  const [vehicleDetails, setVehicleDetails] = React.useState([]);
  const [openOrderLocationDetails, setOrderLocationDetails] = React.useState(false);
  const [openOrderVehicleDetails, setOrderVehicleDetails] = React.useState(false);
  const [openOrderItemDetails, setOrderItemDetails] = React.useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = React.useState(false);
  const [openWalletPay, setOpenWalletPay] = React.useState(false);
  const [openCardPay, setOpenCardPay] = React.useState(false);
  const [selectedPickup, setSelectedPickup] = React.useState([]);
  const [pickup, setpickup] = React.useState([]);
  const [destination, setDestination] = React.useState([]);
  const [selectedDestination, setSelectedDestination] = React.useState([]);
  const [dropoffinputlength, setdropoffinputlength] = React.useState();
  const [pickupinputlength, setpickupinputlength] = React.useState();
  const [selectedVehicleDetails, setSelectedVehicleDetails] = React.useState({
    vtype_id:'',
    vtype_name:'',
  });
  // const [userCards, set] = React.useState(true);
  const [alertToggle, setAlertToggle] = React.useState({
    isVisible: false,
    color: "success",
    message: "",
  });
  const [query, setQuery] = React.useState("");

  const { vTypeIsLoading } = useSelector((state) => state.vehicleTypes);

  useEffect(()=>{
    document.title = 'Vehicle Types'
    dispatch(vehicleTypes()).then((res)=>{
      const {payload, error} = res
      {
        error ?
        console.log(payload)
        :
        setData(payload)
      }
    })
  }, [])

  // Search Function
  const search = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  // Alert Functions
  const handleAlertOpen = (color_param, message_param) => {
    setAlertToggle({
      ...alertToggle,
      isVisible: true,
      color: color_param,
      message: message_param,
    });
  };

  const handleAlertClose = () => {
    setAlertToggle({
      ...alertToggle,
      isVisible: false,
    });
  };

  // Set Order Location Modal
  const handleOpenOrderLocation = (v_typeID, vType_Name) => {
    
    setSelectedVehicleDetails({
      vtype_id:v_typeID,
      vtype_name:vType_Name,
    })
    console.log(selectedVehicleDetails)
    dispatch(openOrder())
    // const selectedVSize = vehicleSizeData?.filter(vtype_data => vtype_data?.typeId === v_typeID)
    // console.log(selectedVSize[0]?.id)
    // setSelectedVehicleDetails({
    //   vtype:vType_name,
    //   vsize:selectedVSize[0]?.name
    // })
    // setCreateOrderData({
    //   ...createOrderData,
    //   ['VehicleTypeId']: v_typeID,
    //   ['VehicleSizeId']:selectedVSize[0]?.id
    // })
    // setOrderLocationDetails(true);
  };

  const handleCloseOrderLocation = () => {
    setOrderLocationDetails(false);
  };

   // Set Order Vehicle Modal
   const handleOpenOrderVehicle = () => {
    handleCloseOrderLocation()
    setOrderVehicleDetails(true);
  };

  const handleCloseOrderVehicle = () => {
    handleCloseOrderLocation()
    setOrderVehicleDetails(false);
  };

   // Set Order Location Modal
   const handleOpenOrderItem = () => {
    handleCloseOrderVehicle()
    setOrderItemDetails(true);
  };

  const handleCloseOrderItem = () => {
    handleCloseOrderVehicle()
    setOrderItemDetails(false);
  };

    // Set Payment method Modal
    const handleOpenPaymentOptions = () => {
      handleCloseOrderItem()
      setOpenPaymentMethod(true);
    };
  
    const handleClosePaymentOptions = () => {
      handleCloseOrderItem()
      setOpenPaymentMethod(false);
    };

     // Set Payment method Modal
     const handleOpenWalletPay = () => {
      handleClosePaymentOptions()
      setOpenWalletPay(true);
    };

    const handleCloseWalletPay = () => {
      handleClosePaymentOptions()
      setOpenWalletPay(false);
    };
  
    const handleOpenCardPay = () => {
      handleClosePaymentOptions()
      setOpenCardPay(true);
    };

    const handleCloseCardPay = () => {
      handleClosePaymentOptions()
      setOpenCardPay(false);
    };


    const handlePickupSelect = (pickup_) => {
      setSelectedPickup(pickup_);
      setpickup([]);
    };
  
    const handleSuggestPickupAddress = (e) => {
      const { name, value } = e.target;
      setpickupinputlength(value?.length);
      if (value?.length > 2) {
        dispatch(suggestAddr(value)).then((res) => {
          const { error, payload } = res;
          {
            if (!error) {
              setpickup(payload);
            }
          }
        });
      }
    };
  
    const handleDestinationSelect = (destination_) => {
      setSelectedDestination(destination_);
      setDestination([]);
    };
  
    const handleSuggestDestination = (e) => {
      const { name, value } = e.target;
      setdropoffinputlength(value?.length);
      if (value?.length > 2) {
        dispatch(suggestAddr(value)).then((res) => {
          const { error, payload } = res;
          {
            if (!error) {
              setDestination(payload);
            }
          }
        });
      }
    };
  
 

  // Modal Functions
  const handleOpenViewVehicle = () => {
    setVehicleDetails();
    setOpenViewVehicle(true);
  };

  const handleCloseViewVehicle = () => {
    setOpenViewVehicle(false);
  };

  // Edit Vehicle  Modal
  const handleOpenEditVehicle = () => {
    setOpenEditVehicle(true);
  };

  const handleSubmitEditVehicle = () => {
    handleAlertOpen("warning", "Successully edited vehicle");
    setOpenEditVehicle(false);
  };

  const handleCloseEditVehicle = () => {
    setOpenEditVehicle(false);
  };

  return (
    <>
      <NavBar />
      <Alert_
        status={alertToggle.isVisible}
        onClose={handleAlertClose}
        color={alertToggle.color}
      />

      <CreateOrderModal vtype_name={selectedVehicleDetails.vtype_name} vtype_id={selectedVehicleDetails.vtype_id}/>

      {/* ------------------- Alert --------------------
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar> */}

      <div className="main-content main-content-margin-top">
        {/* Book ride Modal */}
        <div name="Location Node">
          <Dialog open={openOrderLocationDetails} onClose={handleCloseOrderLocation}>
            <DialogTitle>Book ride</DialogTitle>
            <DialogContent>
            <div>
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Pickup location"
                    id="fullWidth"
                    onChange={(e) => handleSuggestPickupAddress(e)}
                    value={selectedPickup?.description}
                  />
                  {pickupinputlength > 2 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (pickup.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {pickup.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handlePickupSelect(row)}
                                >
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Destination"
                    id="fullWidth"
                    onChange={(e) => handleSuggestDestination(e)}
                    value={selectedDestination?.description}
                  />
                  {dropoffinputlength > 2 && (
                    <nav aria-label="secondary mailbox folders">
                      {isSuggestingAddr & (destination.length > 0) ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </Flex>
                      ) : (
                        <List>
                          {destination.map((row) => {
                            return (
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleDestinationSelect(row)}
                                >
                                  <ListItemText
                                    primary={`${row?.description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    </nav>
                  )}
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseOrderLocation}
              >
                Close
              </Button>

              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleOpenOrderVehicle}
              >
                Next
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Vehicle Type Node">
          <Dialog open={openOrderVehicleDetails} onClose={handleCloseOrderVehicle}>
            <DialogTitle>Vehicle Details</DialogTitle>
            <DialogContent>
            <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Vehicle type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper fullWidth"
                //   value={assignDriverProperty.driver}
                  name='driver'
                  label="Select driver"
                //   onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    data?.map((vtypes, Key)=> <MenuItem key={Key} value={vtypes?.id}>{vtypes?.name}</MenuItem>)
                  }
                </Select>
              </FormControl>

              <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Truck Capacity
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                //   value={assignDriverProperty.driver}
                  name='driver'
                  label="Select driver"
                //   onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'John Doe'}>Truck</MenuItem>
                  <MenuItem value={'Hidora Kai'}>Van</MenuItem>
                  <MenuItem value={'Hidora Kai'}>Bike</MenuItem>
                  <MenuItem value={'Machala'}>Pick-up Truck</MenuItem>
                </Select>
              </FormControl>

              <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Name of Recepient"
                    id="fullWidth"
                    // defaultValue={vehicleDetails?.vehicleNum}
                  />
                </Box>

                <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Phone number of recepient"
                    id="fullWidth"
                    // defaultValue={vehicleDetails?.vehicleNum}
                  />
                </Box>
              
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseOrderVehicle}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleOpenOrderItem}
              >
                Next
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Item Node">
          <Dialog open={openOrderItemDetails} onClose={handleCloseOrderItem}>
            <DialogTitle>Item Details</DialogTitle>
            <DialogContent>
            <Box
                  style={{ marginTop: "20px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Item description"
                    id="fullWidth"
                    // defaultValue={vehicleDetails?.vehicleNum}
                  />
                </Box>

                <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Item weight
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                //   value={assignDriverProperty.driver}
                  name='driver'
                  label="Select driver"
                //   onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={''}>{'<50 ft'}</MenuItem>
                  <MenuItem value={''}>100 ft</MenuItem>
                  <MenuItem value={''}>200 ft</MenuItem>
                  <MenuItem value={''}>{'>500 ft'}</MenuItem>
                </Select>
              </FormControl>

                <Box
                  
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Estimated value"
                    id="fullWidth"
                    // defaultValue={vehicleDetails?.vehicleNum}
                  />
                </Box>

            
            </DialogContent>
            <DialogActions>
              {/* <Button style={{textTransform:'capitalize'}} color="error" onClick={handleCloseViewVehicle}>Cancel</Button> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseOrderItem}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                onClick={handleOpenPaymentOptions}
              >
                Proceed to payment
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Payment method Node">
          <Dialog open={openPaymentMethod} onClose={handleClosePaymentOptions}>
          <Box
                  style={{ marginTop: "10px", marginBottom: '-40px' }}
                  sx={{
                    maxWidth: "auto",
                  }}
                >
            <Button style={{float:'right'}} onClick={handleClosePaymentOptions} color='error'><Close/></Button>
            </Box>
            <DialogTitle style={{textAlign:'center'}}>Select Payment Option</DialogTitle>
            <DialogContent>
        
              {/* <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Payment option
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                //   value={assignDriverProperty.driver}
                  name='driver'
                  label="Select driver"
                //   onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'John Doe'}>Wallet</MenuItem>
                  <MenuItem value={'Hidora Kai'}>Card</MenuItem>
                </Select>
              </FormControl> */}
              <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
              {/* <Flex style={{justifyContent:'space-evenly'}}> */}
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                sx={{width:'100%'}}
                
                onClick={handleOpenWalletPay}
              >
                Wallet 
              </Button>

              <Button
                style={{ textTransform: "capitalize", fontWeight: "700", marginTop: "10px" }}
                variant="contained"
                color="primary"
                
                sx={{width:'100%'}}
                onClick={handleOpenCardPay}
              >
                Card 
              </Button>
              
              {/* </Flex> */}
              </Box>
            </DialogContent>
            <DialogActions>
            
            </DialogActions>
          </Dialog>
        </div>

        <div name="Select Card">
          <Dialog open={openCardPay} onClose={handleCloseCardPay}>
            <DialogTitle>Select prefered card below</DialogTitle>
            <DialogContent>
        
              
                
                <Box
                  style={{ marginTop: "10px" }}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <FormControl style={{width:'100%', margin:'20px 0'}}>
                  <InputLabel id="demo-simple-select-helper-label">
                  Payment option
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                //   value={assignDriverProperty.driver}
                  name='driver'
                  label="Select driver"
                //   onChange={handleAssignDriverChange}
                >
                  <MenuItem value={'John Doe'}>5399 **** 4812 -- GT-BANK</MenuItem>
                  <MenuItem value={'Hidora Kai'}>4201 **** 4812 -- GT-BANK</MenuItem>
                </Select>
                </FormControl>
                </Box>


              <Button variant="outlined">Add new card</Button>
              

                
            </DialogContent>
            <DialogActions>
              
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseCardPay}
              >
                Close
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                
              >
                Pay now 
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div name="Wallet Pay">
          <Dialog open={openWalletPay} onClose={handleCloseWalletPay}>
            <DialogTitle>Estimated fee</DialogTitle>
            <DialogContent>
              
              <p>Your estimated fee for this trip is 30,000 - 50,000.</p>            
              <p>Confirm Payment with your wallet balance</p>
            </DialogContent>
            <DialogActions>
              
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="outlined"
                color="error"
                onClick={handleCloseWalletPay}
              >
                No
              </Button>
              <Button
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                variant="contained"
                color="primary"
                
              >
                Confirm 
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        
        
        {/* Book Ride modal ends */}

        <TableContainer>
            <Heading size='1.5em'>Vehicle Types</Heading>
      
          <Stack style={{marginTop:'40px'}} direction='row'>
          <p style={{ fontSize: "1.3em",marginLeft: "5px",  marginRight: "10px" }}>Search</p>
          <SearchInput type='text' onChange={search}  />
          </Stack>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Type</th>              
                </tr>
              </thead>
              {
                !vTypeIsLoading &&
            <tbody>
                            {data
                              ?.filter(
                                (vehicle) =>
                                vehicle.name.toLowerCase().includes(query.toLowerCase())
                              )
                              ?.map((row_, Key) => {
                                return (
                                  <tr>
                                    <td>{Key+1}</td>
                                    <td>{row_.name}</td>
                                    
                                    
                                    <td style={{textAlign:'center'}}>
                                    <Button style={{textTransform:'capitalize', fontSize:'small'}} onClick={()=> handleOpenOrderLocation(row_?.id, row_?.name)} variant="outlined">Create new order</Button>
                                    </td>
                                  
                                  </tr>
                                );
                              })}
                          </tbody>
              }
              
            </Table>
            {vTypeIsLoading && <Button style={{width:'100%', marginTop:'20px'}}><CircularProgress/></Button>}
          </TableWrapper>
        </TableContainer>
      </div>
    </>
  );
};

export default VehicleTypesCustomer;
