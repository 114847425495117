import React, { useEffect, useState } from "react";
import './auth.css'
import AuthBg from '../../assets/images/auth-bg.jpg';
import RedSpeedLogo from '../../assets/images/Redspeed-black.png';
import { Remember, Section, ImgContainer, BgImage, ContentContainer, AuthFormContainer, InputContainer, Input, Label, CheckBox, Button, Brand, BrandImage  } from "./auth.styled";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerValidatePhone } from "../../app/features/Customer/validateSMSTokenSlice";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { customerLogin } from "../../app/features/Customer/loginSlice";
import { Button as MUIButton } from "@mui/material";
import { generateSMSToken } from "../../app/features/Customer/generateSMSToken";

const VerifyPhone = () => {
    const [ searchParams ] = useSearchParams();
    const phone = searchParams.get("phone");

    const [resendTokenLoading, setResendTokenLoading] = useState()
    const [loginDetails, setLoginDetails] = useState()
    const [countDown, setCountDown] = useState();
    const [phoneToken, setPhoneToken] = useState({
        phone,
        token:''
    })
    const [validateError, setValidateError] = useState({
        display:'none',
        message:null,
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isLoading, status} = useSelector((state) => state.customerValidatePhone)
   
    const {email,password} = JSON.parse(localStorage.getItem('register-data')) || null;

    const handleSubmit = (event)=> {
        event.preventDefault()
        try{
            dispatch(customerValidatePhone(phoneToken)).then((res)=>{
                
                let {payload, error} = res
                
                {
                    error ?
                    setValidateError({
                        ...validateError,
                        display:'block',
                        message:payload
                    })
                    :
                    setLoginDetails({
                        email,
                        password
                    })
                    dispatch(customerLogin(loginDetails)).then((res)=> {
                        let {payload, error} = res
                        {
                            error ?
                            console.log(error)
                            :
                            navigate("/dashboard/customer")
                        }
                        
                    })
                    
                }
                
            })
        } catch(err){
            return err
        }

    }

    const handleResendToken=()=>{
        dispatch(generateSMSToken(phone))
    }

    const handleTokenInput = (e)=> {
        setPhoneToken({
            ...phoneToken,
            token:e.target.value
        })
    }

    return (
        <>
        <Section>
            <ImgContainer>
            <BgImage src={AuthBg} alt="" />
            </ImgContainer>
            <ContentContainer>
                <AuthFormContainer>
                    <Brand>
                    <BrandImage src={RedSpeedLogo}/>
                    </Brand>

                    <hr />
                    <br />
                    <h2>Enter OTP</h2>
                    <div style={{marginBottom:'20px', display:`${validateError.display}`}}>
                    <Alert severity="error">{validateError.message}</Alert>
                    </div>
                    <p className="margin-top-minus-5 margin-bottom-20">
                    Type out the four digit pin sent to your phone number  {phone?.slice(0,4)}******{phone?.slice(7)}
                    </p>
                    <form action="" method="post" onSubmit={(e)=>handleSubmit(e)}>
                        <InputContainer>
                        
                        <Input type="text" placeholder="Enter code" name="" onChange={(e)=> handleTokenInput(e)} />
                        </InputContainer>

                        <Remember>
                            <MUIButton onClick={()=> handleResendToken()} >Resend code</MUIButton>
                            <p>Resend Code (15s)</p>
                        </Remember>

                        <InputContainer>
                        {
                            isLoading ?
                            <Button style={{background:'transparent'}}><CircularProgress color="inherit" /> </Button>
                            :
                            <Button type='submit'>Submit</Button>
                        }         
                        </InputContainer>

                        
                        
                    </form>
                </AuthFormContainer>
            </ContentContainer>
        </Section>
        </>
    )
}

export default VerifyPhone;