import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import baseUrl from '../../../../helper/api';

const initialState = {
    data:[],
    isLoading: false,
    status:null,
}

const token = userObj?.token

export const getAllVehicles = createAsyncThunk(
    'partner/getAllVehicles',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Partner/vehicles/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getAllVehiclesSlice = createSlice({
    name: 'getAllvehicle',
    initialState,
    reducers: {},
    extraReducers:{
        [getAllVehicles.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getAllVehicles.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getAllVehicles.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default getAllVehiclesSlice.reducer;