import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isUpdatingRider:null,
    status:null,
    error:null
}

const token = userObj?.token


export const updateRider = createAsyncThunk(
    'partner/updateRider',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.put(`Partner/updaterider`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            // console.log(err.response.data)
            return rejectWithValue(err.response.data)
        }
        
    }
)


const updateRiderSlice = createSlice({
    name: 'updateRider',
    initialState,
    reducers: {},
    extraReducers:{
        [updateRider.pending]: (state, action) => {
            state.status='pending';
            state.isUpdatingRider=true;
        },
        [updateRider.fulfilled]: (state, action) => {
            state.status='success';
            state.isUpdatingRider=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [updateRider.rejected]: (state, action) => {
            state.status='failed';
            state.isUpdatingRider=false;
            state.error = action.error
        }
    }
})

export default updateRiderSlice.reducer;