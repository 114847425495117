import styled from "styled-components";

export const SideBarContainer = styled.nav`
background: #f7f9ff;
    width:220px;
    position: fixed;
    top:0;
    left:0;
    z-index:15;
    height: 100vh;
    transition: ease-in-out 550ms;
    box-shadow:0 0 5px grey;

    ul{
        background: #f7f9ff;
         height:100%;
         width:100%;
         list-style: none;
         color:#535f6b;
         cursor: pointer;
    }
    li{
        position: relative;
        line-height:30px;
        // border-bottom:1px solid rgba(255,255,255,0.1);
        display:flex;
        align-items:center;
        padding:10px;
        margin:10px;
    }
    a{
        color: #535f6b;
        text-decoration:none;
        font-size:15px;
        padding-left:5px;
        font-weight:500;
        display:block;
        width:100%;
        border-left:3px solid transparent;
        
    }

    li:hover{
        background: #dab5b3;
        color:#b23934 !important;
        border-radius: 10px;
    }
    ul ul{
        position: static;
        
    }
    ul ul li a{
        font-size: 16px;
        color: #e6e6e6;
        padding-left:40px;
    }
`

export const DropDownArrowSpan = styled.span`
position: absolute;
top: 8px;
right: 20px;
// transform: translateY(-50%) ;
transition: transform 0.4s;

`


export const BrandLogo = styled.div`
    width: 170px;
    height: 60px;
    padding: 5px;

    img{
        height: 80%;
        margin-top:4px;
    }
`

export const CloseSideBarDiv = styled.div`
    width:60px;
    height: 60px;
    
`


export const SideBarText = styled.div`
    color: white;
    font-size: 25px;
    font-weight: 600;
    line-height:65px;
    text-align: center;
    letter-spacing:1px;
    
    display: flex;
    
`

