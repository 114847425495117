import { createSlice } from "@reduxjs/toolkit";

export const createPackageScheduleSlice = createSlice({
    name: 'packageSchedule',
    initialState: {
        isCreateScheduleOpened: false,
    }, 
    reducers: {
        openSchedule: (state) => {
            state.isCreateScheduleOpened = true
        },
        closeSchedule: (state) => {
            state.isCreateScheduleOpened = false
        },
    },
})

export const {openSchedule, closeSchedule} = createPackageScheduleSlice.actions;
export default createPackageScheduleSlice.reducer