import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import baseUrl from '../../../helper/api';

const initialState = {
    data:[],
    status:null,
    error:null
}

// headers: {
//     Authorization: 'Bearer ' + varToken
//   }

export const registerPartner = createAsyncThunk(
    'partner/register',
    async (data, {rejectWithValue}) => {
        
        try{
            const response = await axios.post(`${baseUrl}/Account/registerPartner`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            )
            return response?.data
        } catch (err) {
            
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const registerPartnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {},
    extraReducers:{
        [registerPartner.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [registerPartner.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [registerPartner.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
            state.error = action.error
        }
    }
})

export default registerPartnerSlice.reducer;