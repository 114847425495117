import React from "react";

const AddBulkVehicle = () => {
    return(
        <>
        <h1>BULK VEHICLE</h1>
        </>
    )
}

export default AddBulkVehicle;