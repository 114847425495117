import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data:[],
    isLoading:null,
    status:null,
}

const token = userObj?.token

export const getCodReport = createAsyncThunk(
    'order/getCodReport',
    async ({ name, start, end}, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`/Order/CODReport?firstName=${name}&StartDate=${start}&EndDate=${end}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const getCodReportSlice = createSlice({
    name: 'getCodReport',
    initialState,
    reducers: {},
    extraReducers:{
        [getCodReport.pending]: (state, action) => {
            state.status='pending';
            state.isLoading=true;
        },
        [getCodReport.fulfilled]: (state, action) => {
            state.status='success';
            state.isLoading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [getCodReport.rejected]: (state, action) => {
            state.status='failed';
            state.isLoading=false;
        }
    }
})

export default getCodReportSlice.reducer;