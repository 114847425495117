import React from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import Widget from "../../../components/widgets/widget.component";
import {
  QuickActionFlex,
  QuickActionItemContainer,
  WidgetFlex,
} from "./dashboard.styled";
import ChartWidget from "../../../components/widgets/chart-widget/chart_widget.component";
import ListOfOrdersChart from "../../../partner-charts/order_list.chart";
import BalanceChart from "../../../partner-charts/balances.chart";
import DriverTotalEarnedChart from "../../../partner-charts/driver_total_earned.chart";
import DriverTripsChart from "../../../partner-charts/driver_trips.chart";
import { Avatar, CircularProgress } from "@mui/material";
import CarLogo from "../../../assets/icons/car.svg";
import iconFinance from "../../../assets/icons/finance-icon.svg";
import iconTrackVehicle from "../../../assets/icons/track-vehicle-icon.svg";
import IconAddDriver from "../../../assets/icons/add_driver.svg";
import iconAssignDriver from "../../../assets/icons/assign_driver.svg";
import iconViewOrder from "../../../assets/icons/view_order.svg";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Heading } from "../../../components/Tags/tags.styled";
import './dashboard.css'
import { useEffect } from "react";
import { userObj } from "../../../helper/protectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../../../app/features/Partner/vehicles/getAllVehicles";
import { getAllRiders } from "../../../app/features/Partner/rider/getAllRiders";
import { assignRider } from "../../../app/features/Partner/rider/assignRider";
import { finance } from "../../../app/features/Partner/Finance/financeSlice";
// import { Button, TextField, Dialog, DialogActions } from "@mui/material";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    background: "transparent !important",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    padding: "5px",
  },
});

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const id = userObj?.extra.id;

  const dollarUSLocale = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  const [openAssignDriver, setOpenAssignDriver] = React.useState(false);
  const [openAddVehicle, setOpenAddVehicle] = React.useState(false);
  const [alertToggle, setAlertToggle] = React.useState(false);
  const [riders, setRiders] = React.useState([]);
  const [approvedRiders, setApprovedRiders] = React.useState([])
  const [approvedVehicles, setApprovedVehicles] = React.useState([])
  const [vehicles, setVehicles] = React.useState([]);
  const [finance_data, setFinance] = React.useState([]);
  const [assignRiderProperty, setAssignRiderProperty] = React.useState({
    riderId:'',
    vehicleId:''
  });

  const { isLoadingFinance } = useSelector((state) => state.finance);

  useEffect(()=>{
    document.title = "Dashboard - partner"
    dispatch(getAllVehicles(id)).then((res)=> {
      const {payload, error} = res
      !error && setVehicles(payload)
    })
    dispatch(getAllVehicles(id)).then((res)=> {
      const {payload, error} = res
      const vc = payload.filter((v) => {
        return v.status === 1
      })
      !error && setApprovedVehicles(vc)  
    })
    dispatch(finance(id)).then((res)=>{
      const {payload, error} = res;
      {
        !error&&
        console.log(payload)
        setFinance(payload)
      }
    })
    dispatch(getAllRiders(id)).then((res)=> {
      const {error, payload} = res
      !error && setRiders(payload)
    })
    dispatch(getAllRiders(id)).then((res)=> {
      const {error, payload} = res
      const rd = payload.filter((r) => {
        return r.status === 1
      })
      !error && setApprovedRiders(rd)
    })
  }, [])

  const { isAssigningRider } = useSelector((state) => state.assignRider);

  const handleAssignDriverChange = (event) => {
    const { name, value } = event.target;
    setAssignRiderProperty({
      ...assignRiderProperty,
      [name]: value,
    });
    
  };

  const classes = useStyles();

  const handleAddVehiclekOpen = () => {
    setOpenAddVehicle(true);
  };

  const handleAddVehicleClose = () => {
    setOpenAddVehicle(false);
  };

  const handleClickOpen = () => {
    setOpenAssignDriver(true);
  };

  const handleClose = () => {
    setOpenAssignDriver(false);
  };

  // Alert Settings
  const handleAlertOpen = () => {
    setAlertToggle(true);
  };

  // Assign Driver
  const AssignDriver = (e) => {
    e.preventDefault()
    dispatch(assignRider(assignRiderProperty)).then((res)=>{
      const {payload, error} = res
      {
        error ?console.log(payload)
        :
        handleAlertOpen();
        setOpenAssignDriver(false);
      }
    })
    
  }


  const handleAlertClose = () => {
    setAlertToggle(false);
  };

  return (
    <>
      <NavBar />

      {/*------------------- Alert --------------------*/}
      <Snackbar open={alertToggle} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar>

      {/* Quick Action Modal */}
      <div name="Assign Driver Modal Parent Node">
        <Dialog open={openAssignDriver} onClose={handleClose}>
          <form action="" method="post" onSubmit={(e)=>AssignDriver(e)}>
          <DialogTitle>Assign Driver</DialogTitle>
          <DialogContent>
            <div>
              <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select driver
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignRiderProperty.riderId}
                  name='riderId'
                  label="Select rider"
                  onChange={handleAssignDriverChange}
                >
                  {
                    approvedRiders?.map((rider, Key)=> <MenuItem key={Key} value={rider?.id}>{`${rider?.firstName} ${rider?.lastName}`}</MenuItem>)
                  }
                </Select>
                <FormHelperText>select a driver to add</FormHelperText>
              </FormControl>
              
            </div>
            <div>
            <FormControl style={{width:'100%'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Vehicle
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignRiderProperty.vehicleId}
                  name='vehicleId'
                  label="Select Vehicle"
                  onChange={handleAssignDriverChange}
                >
                 {
                  approvedVehicles?.map((row_, Key)=> <MenuItem key={Key} value={row_?.id}>{`${row_?.vehicleBrand.name} ---- ${row_?.regNo}`}</MenuItem>)
                 }
                </Select>
                <FormHelperText>choose one vehicle from the list of your vehicle list</FormHelperText>
              </FormControl>
              
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{textTransform:'capitalize'}} color="error" onClick={handleClose}>Cancel</Button>
            {isAssigningRider ? (
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "700",
                      border: "none",
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    <CircularProgress />
                  </Button>
                ) : (
            <Button type="submit" style={{textTransform:'capitalize', fontWeight:'700'}} variant="contained" color="primary" >Assign</Button>
                )}
            
          </DialogActions>
          </form>
        </Dialog>
      </div>

      <div name="Add Vehicle Modal Parent Node">
        <Dialog open={openAddVehicle} onClose={handleAddVehicleClose}>
          <DialogTitle>Add Vehicle</DialogTitle>
          <DialogContent>
            
          <FormControl style={{width:'100%', margin:'20px 0'}}>
                <InputLabel id="demo-simple-select-helper-label">
                  Vehicle Make
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name='vehicle'
                  label="Select Vehicle"
                  // onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Lamborghini'}>Lamborghini</MenuItem>
                  <MenuItem value={'Napep'}>Napep</MenuItem>
                  <MenuItem value={'Truck'}>Mercedez</MenuItem>
                </Select>
                
              </FormControl>
              
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="Vehicle Number"
                  
                  id="fullWidth"
                />
              </FormControl>

              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <TextField
                  fullWidth
                  label="License Number"
                  id="fullWidth"
                />
              </FormControl>
          </DialogContent>
          <DialogActions>
            <Button style={{textTransform:'capitalize'}} color="error" onClick={handleAddVehicleClose}>Cancel</Button>
            <Button style={{textTransform:'capitalize', fontWeight:'700'}} variant="contained" color="primary" onClick={''}>Add Vehicle</Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Quick Acion Modal Ends */}

      <div className="main-content main-content-margin-top">
        <WidgetFlex>
          <Link to="">
            <Widget
              headcolor="#10c469"
              currency="&#8358;"
              head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.totalIncome)}` : "0"}
              
              bottom="Total Income"
            />
          </Link>

          <Link to="">
            <Widget
              headcolor="#ff5b5b"
              currency="&#8358;"
              head={finance_data?.partner ? `${dollarUSLocale.format(finance_data?.outstanding)}` : "0"}
              
              bottom="Outstanding Balance"
            />
          </Link>

          <Link to="">
            <Widget headcolor="#188ae2" 
            head={`${riders.length}`}
            bottom="Number of Riders" />
          </Link>

          <Link to="">
            <Widget headcolor="#f9c851" 
            head={`${vehicles.length}`} bottom="Number of Vehicles" />
          </Link>
        </WidgetFlex>
        
        {/* <p style={{ fontSize: "1.8em", marginLeft: "10px" }}>Quick Actions</p> */}
        {/* Quick Action Section */}
        <QuickActionFlex>
        
          <QuickActionItemContainer className="quick-action-border-right" onClick={()=>navigate('/dashboard/partner/vehicles')}>
            <Avatar className={classes.root}>
              <img src={CarLogo} alt="" />
            </Avatar>
            <p>Add Vehicle</p>
          </QuickActionItemContainer>

          <QuickActionItemContainer className="quick-action-border-right" onClick={()=>navigate('/dashboard/partner/drivers')}>
            <Avatar className={classes.root}>
              <img src={IconAddDriver} alt="" />
            </Avatar>
            <p>Add Driver</p>
          </QuickActionItemContainer>

          <QuickActionItemContainer className="quick-action-border-right remove-driver-border" onClick={() => handleClickOpen()}>
            <Avatar className={classes.root}>
              <img  src={iconAssignDriver} alt="" />
            </Avatar>
            <p>Assign Driver</p>
          </QuickActionItemContainer>

          <QuickActionItemContainer className="quick-action-border-right" onClick={()=>navigate('/dashboard/partner/finance')}>
            <Avatar className={classes.root}>
              <img src={iconFinance} alt="" />
            </Avatar>
            <p>Finance</p>
          </QuickActionItemContainer>

          <QuickActionItemContainer className="quick-action-border-right" onClick={()=>navigate('/dashboard/partner/orders')}>
            <Avatar className={classes.root}>
              <img src={iconViewOrder} alt="" />
            </Avatar>
            <p>View Order</p>
          </QuickActionItemContainer>

          <QuickActionItemContainer>
            <Avatar className={classes.root}>
              <img src={iconTrackVehicle} alt="" />
            </Avatar>
            <p>Track Vehicle</p>
          </QuickActionItemContainer>
        </QuickActionFlex>

        {/* Chart Section */}
        <WidgetFlex>
          <ChartWidget
            heading="List of orders"
            content={<ListOfOrdersChart />}
          />
          <ChartWidget heading="Finance" content={<BalanceChart />} />
        </WidgetFlex>
        <WidgetFlex>
          <ChartWidget
            heading="Drivers Total Earned"
            content={<DriverTotalEarnedChart />}
          />
          <ChartWidget
            heading="Number of Drivers Trip"
            content={<DriverTripsChart />}
          />
        </WidgetFlex>
      </div>
    </>
  );
};

export default Dashboard;
