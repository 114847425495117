import {
  vehicleConstants
} from "../../constants"

const initialState = {
  data:[],
  isDeleting:null,
  status:null,
}

export const vehicleReducer = (state = initialState, action) => {
  switch(action.type) {
    case vehicleConstants.DELETE_VEHICLE_REQUEST:
      return (state = {
        ...state,
        status: 'pending',
        isDeleting: true
      })
    case vehicleConstants.DELETE_VEHICLE_SUCCESS:
      return (state = {
        ...state,
        status: 'success',
        isDeleting: false
      })
    case vehicleConstants.DELETE_VEHICLE_FAILURE:
      return (state = {
        ...state,
        status: 'failed',
        isDeleting: false
      })
    default:
      return {...state}
  }
}