import { Button, CircularProgress, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import NavBar from "../../../components/navbar/navbar.component";
import {
  Table,
  TableContainer,
  TableWrapper,
} from "../../../components/Table/table.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import { VehiclesTableContainer } from "../../Partner-Pages/Vehicle/vehicles.styled";
import { walletByCustomer } from "../../../app/features/Customer/wallet/walletPaymentsByCust";
import { walletPayments } from "../../../app/features/Customer/wallet/walletpaymentsSlice";
import { useDispatch, useSelector } from "react-redux";
import convertDate from "../../../helper/dateCoverter";
import { getWallet } from "../../../app/features/Customer/wallet/getWalletSlice";

const CustomerPayments = () => {
  const userObj = JSON.parse(localStorage.getItem("user"));
  const currentUser = userObj?.extra;

  const formatCurrency = (value) => {
    return value.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });
  };

  // const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0])
  const [startDate, setStartDate] = useState("2024-02-09");
  const [endDate, setEndDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      new Date().getDate().toString().padStart(2, "0")
  );

  const o = {
    startDate: startDate,
    endDate: endDate,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Redspeed - Wallet Payments";
    dispatch(walletByCustomer(o));
    dispatch(walletPayments());
    dispatch(getWallet());
  }, []);
  const { isLoading, data } = useSelector((state) => state.walletByCustomer);
  const { wallet } = useSelector((state) => state.getWallet);

  const handleSearch = () => {
    const o = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(walletByCustomer(o));
  };

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <TableContainer>
          <Heading size="1.8em">Customer Wallet Payments</Heading>

          <VehiclesTableContainer>
            <div className="d-flex flex-row-reverse">
              <div className="col-7">
                <div className="row">
                  <div className="col-5">
                    <FormControl fullWidth>
                      <TextField
                        id="date"
                        type="date"
                        defaultValue={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-5">
                    <FormControl fullWidth>
                      <TextField
                        id="date"
                        type="date"
                        defaultValue={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-2" style={{ display: "flex", flexDirection: "" }}>
                    <Button style={{ alignItems: "center"}} onClick={() => handleSearch()} size="large" color="primary" variant="outlined">
                      <SearchIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Bank name</th>
                    <th>Description</th>
                    <th>Transaction Type</th>
                    <th>Amount</th>
                    <th>Balance</th>
                  </tr>
                </thead>

                {!isLoading && (
                  <tbody>
                    {data?.map((row_, Key) => {
                      return (
                        <tr>
                          <td style={{ textWrap: "nowrap" }}>
                            {convertDate(row_?.paymentDate)}
                          </td>
                          <td style={{ textWrap: "nowrap" }}>
                            {row_.customerCard
                              ? row_.customerCard?.bank
                              : "Wallet"}
                          </td>
                          {/* <td>{(row_.estimatedDistance)/1000}km</td> */}
                          <td style={{ textWrap: "nowrap" }}>
                            {row_.reference ? row_.reference : "Top-up"}
                          </td>
                          <td
                            style={{ textWrap: "nowrap", textAlign: "center" }}
                          >
                            {row_.mode}
                          </td>
                          <td style={{ textWrap: "nowrap" }}>
                            {row_.mode === "CREDIT" ? (
                              <span style={{ color: "green" }}>
                                +{formatCurrency(row_.amount)}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                -{formatCurrency(row_.amount)}
                              </span>
                            )}
                          </td>
                          <td style={{ textWrap: "nowrap" }}>
                              <span>
                                +{formatCurrency(row_.balance)}
                              </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </Table>
              {isLoading && (
                <Button style={{ width: "100%", marginTop: "20px" }}>
                  <CircularProgress />
                </Button>
              )}
            </TableWrapper>
          </VehiclesTableContainer>
        </TableContainer>
      </div>
    </>
  );
};

export default CustomerPayments;
