import React, { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
} from "@mui/material";
import { AddNew } from "../../Partner-Pages/Vehicle/vehicles.styled";
import {
  Table,
  TableWrapper,
  TableContainer,
} from "../../../components/Table/table.styled";
import Button from "@mui/material/Button";
import NavBar from "../../../components/navbar/navbar.component";
import { Heading } from "../../../components/Tags/tags.styled";
import { toast } from "react-toastify";
import { userObj } from "../../../helper/protectedRoutes";
import { getRcsDetail } from "../../../app/features/Customer/Dss/getRcsDetailSlice";
import { confirmRcs } from "../../../app/features/Customer/Dss/confirmRcsSlice";

const RCSDetailPage = () => {
  const dispatch = useDispatch();
  const user = userObj?.extra;
  console.log("user >>", user);

  const navigate = useNavigate();
  const { id } = useParams();

  const [shipment, setShipment] = React.useState({});
  const { isLoading } = useSelector((state) => state.getRcsDetails);
  const { isConfirming } = useSelector((state) => state.confirmRcs);

  useEffect(() => {
    document.title = "Daily shipments - Customer";
    dispatch(getRcsDetail(id)).then(
      (res) => {
        const { payload } = res;
        setShipment(payload);
        console.log("shipments", payload);
      }
    );
  }, []);

  const handleConfirm = () => {
    const userAcknowledgement = window.confirm('Are you sure you want to confirm that all shipments have been picked by the courier?');
    if (userAcknowledgement) {
      dispatch(confirmRcs(shipment.id)).then((res) => {
        const { error, payload } = res;
        if (!error) {
          toast.success("Handover confirmed!");
          navigate("/dashboard/customer/rcs")
        } else {
          toast.error(payload)
          console.log(payload);
        }
      })
    };
  };

  return (
    <>
      <NavBar />
      <div className="main-content main-content-margin-top">
        <TableContainer>
          <Heading size="1.8em">RCS - {shipment.no}</Heading>
          <AddNew>
            {!isConfirming ?
            (
              <Button
            variant="outlined"
            disabled={!shipment.dischargedConfirmedByDriver}
            onClick={handleConfirm}
          >
            Confirm Handover
          </Button>
            ) :  (
              <Button style={{ marginTop: "20px" }}>
                <CircularProgress />
              </Button>)
            }
          </AddNew>
          <TableWrapper>
          <Table>
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Content</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {!isLoading && (
                    <tbody>
                      {shipment.rcSaleItems
                        // ?.filter((s) =>
                        //   s.waybillNumber.toLowerCase().includes(query.toLowerCase())
                        // )
                        ?.map((row_, Key) => {
                          return (
                            <tr>
                              <td>{row_.shipment?.waybillNumber}</td>

                              <td>{row_.shipment?.origin?.name}</td>
                              <td>{row_.shipment?.city?.name}</td>
                              <td>{row_.shipment?.contentDescription}</td>
                              <td>{row_.shipment?.weight + "kg"}</td>
                              <td>{row_.shipment?.unit}</td>

                              <td style={{ width: "180px" }}>
                                {row_?.status === 0 && (
                                  <span style={{ color: "#f9c851" }}>
                                    pending
                                  </span>
                                )}
                                {row_?.status === 1 && (
                                  <span style={{ color: "#188ae2" }}>
                                    accepted
                                  </span>
                                )}
                                {row_?.status === 2 && (
                                  <span style={{ color: "#ff6666" }}>
                                    awaiting pickup
                                  </span>
                                )}
                                {row_?.status === 3 && (
                                  <span style={{ color: "#666699" }}>
                                    arrived
                                  </span>
                                )}
                                {row_?.status === 4 && (
                                  <span style={{ color: "#f9c851" }}>
                                    awaiting rider code
                                  </span>
                                )}
                                {row_?.status === 5 && (
                                  <span style={{ color: "#bf4080" }}>
                                    In Transit
                                  </span>
                                )}
                                {row_?.status === 10 && (
                                  <span style={{ color: "#10c469" }}>
                                    Completed
                                  </span>
                                )}
                                {row_?.status === 11 && (
                                  <span style={{ color: "#ff5b5b" }}>
                                    cancelled
                                  </span>
                                )}
                              </td>
                              <td>
                                <Link to={`/dashboard/customer/order-tracking/${row_.shipment?.waybillNumber}`}>View</Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
            {isLoading && (
              <Button style={{ width: "100%", marginTop: "20px" }}>
                <CircularProgress />
              </Button>
            )}
          </TableWrapper>
        </TableContainer>
      </div>
    </>
  );
};

export default RCSDetailPage;
