import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { userObj } from "../../../../../helper/protectedRoutes";
import baseUrl from '../../../../../helper/api';

const initialState = {
    vehicleBrandData:[],
    brandIsLoading:false,
    status:null,
}

const token = userObj?.token

export const vehicleBrands = createAsyncThunk(
    'partner/vehicleBrands',
    async (_, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`${baseUrl}/Settings/vehicleBrands`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
        
    }
)


const vehicleBrandsSlice = createSlice({
    name: 'vehicleBrands',
    initialState,
    reducers: {},
    extraReducers:{
        [vehicleBrands.pending]: (state, action) => {
            state.status='pending';
            state.brandIsLoading=true;
        },
        [vehicleBrands.fulfilled]: (state, action) => {
            state.status='success';
            state.brandIsLoading=false;
            state.vehicleBrandData = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [vehicleBrands.rejected]: (state, action) => {
            state.status='failed';
            state.brandIsLoading=false;
        }
    }
})

export default vehicleBrandsSlice.reducer;