import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';
import { userObj } from "../../../../helper/protectedRoutes";
import axios from "../../../../api/axios";

const initialState = {
    data: null,
    downloading:null,
    status:null,
}

const token = userObj?.token

export const bulkOrderDownload = createAsyncThunk(
    'order/bulkOrderDownload',
    async (id, {rejectWithValue}) => {
        
        try{
            const response = await axios.get(`/Order/DownloadBulkScheduleTemplate`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            return response?.data
        } catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response.data.title)
        }
        
    }
)


const bulkOrderDownloadSlice = createSlice({
    name: 'bulkOrderDownload',
    initialState,
    reducers: {},
    extraReducers:{
        [bulkOrderDownload.pending]: (state, action) => {
            state.status='pending';
            state.downloading=true;
        },
        [bulkOrderDownload.fulfilled]: (state, action) => {
            state.status='success';
            state.downloading=false;
            state.data = action.payload;
            // localStorage.setItem('user', JSON.stringify(action.payload))
        },
    
        [bulkOrderDownload.rejected]: (state, action) => {
            state.status='failed';
            state.downloading=false;
        }
    }
})

export default bulkOrderDownloadSlice.reducer;