import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import NavBar from "../../../components/navbar/navbar.component";
import Widget from "../../../components/widgets/widget.component";
import { WidgetFlex } from "../../Partner-Pages/Dashboard/dashboard.styled";
import { Avatar, CircularProgress, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Flex } from "./track-order.styled";
import { ChartWidgetContainer } from "../../../components/widgets/chart-widget/chart_widget.styled";
import { Input, InputContainer, Button as Btn } from "../../Auth/auth.styled";
import { Heading } from "../../../components/Tags/tags.styled";
import { OrderOverview, OrderSummaryContainer } from "./track-order.styled";
import './track-order.css'
import GoogMap from "../../../helper/googlemap/map.google";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../app/features/Customer/Order/getOrderSlice";
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import { Button, TextField, Dialog, DialogActions } from "@mui/material";

const data_json = [
  {
    id: 1,
    orderNum: "7HJ73HD9JFH83",
    deliveryFee: "N40,000.00",
    pickUpTime: "04:00pm",
    pickUpDate: "12/03/1982",
    assigned: true,
  },
  {
    id: 2,
    orderNum: "7HJ73HD9JFH83",
    deliveryFee: "N40,000.00",
    pickUpTime: "04:00pm",
    pickUpDate: "12/03/1982",
    assigned: false,
  },
  {
    id: 3,
    orderNum: "7HJ73HD9JFH83",
    deliveryFee: "N40,000.00",
    pickUpTime: "04:00pm",
    pickUpDate: "12/03/1982",
    assigned: true,
  },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  root: {
    background: "transparent !important",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    padding: "5px",
  },
});

const TrackOrder = () => {

  
  const {orderid} = useParams();
  const dispatch = useDispatch()
  const [data, setData] = React.useState(data_json);
  const [openAssignDriver, setOpenAssignDriver] = React.useState(false);
  const [openTrackOrderModal, setOpenTrackOrderModal] = React.useState(false);
  const [alertToggle, setAlertToggle] = React.useState(false);
  const [assignDriverProperty, setAssignDriverProperty] = React.useState({
    driver: "",
    vehicle: "",
  });

  useEffect(()=>{
    document.title = "Track order - Redspeed"
    dispatch(getOrder(orderid))
  }, [])

  const {orderDetails, isLoading} = useSelector((state)=> state.getOrder)
  console.log(orderDetails)
  const handleAssignDriverChange = (event) => {
    const { name, value } = event.target;
    setAssignDriverProperty({
      ...assignDriverProperty,
      [name]: value,
    });
  };
  const classes = useStyles();

  const handleTrackOrderModalOpen = () => {
    setOpenTrackOrderModal(true);
  };

  function trackClassName() {
    if (orderDetails?.status === 10) {
      return "order-tracking completed";
    } else {
      return "order-tracking"
    }
  }

  const handleTrackOrderModalClose = () => {
    setOpenTrackOrderModal(false);
  };

  const handleClickOpen = () => {
    setOpenAssignDriver(true);
  };

  const handleClose = () => {
    setOpenAssignDriver(false);
  };

  // Assign Driver
  const AssignDriver = () => {
    handleAlertOpen();
    setOpenAssignDriver(false);
  };

  // Alert Settings
  const handleAlertOpen = () => {
    setAlertToggle(true);
  };

  const handleAlertClose = () => {
    setAlertToggle(false);
  };

  function deliveryStatus() {
    if (orderDetails?.status === 0) {
      return "This order hasn't been accepted by a rider"
    } else if (orderDetails?.status === 1) {
      return new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString()
    } else if (orderDetails?.status === 2) {
      return new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString()
    } else if (orderDetails?.status === 5) {
      return new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString()
    } else if (orderDetails?.status === 10) {
      return new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString()
    } else if (orderDetails?.status === 11) {
      return "This order has been cancelled"
    }
  }

  // function orderStatus(status) {
  //   switch (status) {
  //     case 0:
  //         return {}
  //     case 1:
  //         return (<span className="badge bg-info px-3">Accepted</span>);
  //     case 2:
  //         return (
  //             <span className="badge bg-warning px-3">Awaiting Pickup</span>
  //         );
  //     case 5:
  //         return (<span className="badge bg-light px-3">Started</span>);
  //     case 10:
  //         return {{ new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString() }}
  //     case 11:
  //         return (<span className="badge bg-danger px-3">Cancelled</span>);
  //     default:
  //         return (<span className="badge bg-secondary px-3">Pending</span>);
  //   }
  // }

  return (
    <>
      <NavBar />

      {/*------------------- Alert --------------------*/}
      <Snackbar
        open={alertToggle}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully Assigned Driver to Vehicle
        </Alert>
      </Snackbar>

      {/* Quick Action Modal */}
      <div name="Assign Driver Modal Parent Node">
        <Dialog open={openAssignDriver} onClose={handleClose}>
          <DialogTitle>Assign Driver</DialogTitle>
          <DialogContent>
            <div>
              <FormControl style={{ width: "100%", margin: "20px 0" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select driver
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.driver}
                  name="driver"
                  label="Select driver"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"John Doe"}>John Doe</MenuItem>
                  <MenuItem value={"Hidora Kai"}>Hidora Kai</MenuItem>
                  <MenuItem value={"Machala"}>Machala</MenuItem>
                </Select>
                <FormHelperText>select a driver to add</FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select Vehicle
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={assignDriverProperty.vehicle}
                  name="vehicle"
                  label="Select Vehicle"
                  onChange={handleAssignDriverChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Lamborghini"}>Lamborghini</MenuItem>
                  <MenuItem value={"Napep"}>Napep</MenuItem>
                  <MenuItem value={"Truck"}>Mercedez Truck</MenuItem>
                </Select>
                <FormHelperText>
                  choose one vehicle from the list of your vehicle list
                </FormHelperText>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize" }}
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={() => AssignDriver()}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div name="Add Vehicle Modal Parent Node">
        <Dialog open={openTrackOrderModal} onClose={handleTrackOrderModalClose}>
          <DialogTitle>Track Order</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "capitalize", fontWeight: "700" }}
              variant="contained"
              color="primary"
              onClick={handleTrackOrderModalClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Quick Acion Modal Ends */}

      <div className="main-content main-content-margin-top">
        {/* Chart Section */}
        <Dialog open={isLoading}>
        <DialogContent style={{background:'transparent'}}>
          <CircularProgress/>
        </DialogContent>
            
        </Dialog>
        <OrderSummaryContainer>
          <div>
            <Flex>
              <Heading>Order progress</Heading>
              <Heading style={{fontSize:'small'}}>
                <span>
                  { orderDetails?.status === 10 ? "Delivery Date" : "Estimated Delivery Date" }
                </span> <br />
                <span style={{color:'blue'}}>
                  {/* { orderDetails?.status === 10 && orderDetails?.orderRider
                    ? new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString()
                    : "This order hasn't been accepted by a rider"
                  } */}
                  {deliveryStatus()}
                </span>
              </Heading>
            </Flex>
            <br/>
          </div>
          <OrderOverview>
            <Stack direction={'row'}>
              { 
                orderDetails?.pickupDate ?
                <div className="order-tracking completed">
                  <span className="is-complete"></span>
                  <p>Pickup<br/>
                    <span>
                      { new Date(orderDetails?.pickupDate).toDateString()}
                    </span>
                  </p>
                </div> :
                <div className="order-tracking">
                  <span className="is-complete"></span>
                  <p>Pickup<br/>
                    <span>
                      "N/A"
                    </span>
                  </p>
                </div>
              }
                <div className={trackClassName()}>
                  <span className="is-complete"></span>
                  <p>Current Location<br/>
                    <span>
                      
                    </span>
                  </p>
                </div>
              {
                orderDetails?.status === 10 && orderDetails.orderRider ?
                <div className="order-tracking completed">
                  <span className="is-complete"></span>
                  <p>Delivered<br/>
                    <span>
                      { new Date(orderDetails?.orderRider.estimatedDeliveryTime).toDateString() }
                    </span>
                  </p>
                </div> :
                <div className="order-tracking">
                  <span className="is-complete"></span>
                  <p>Delivered<br/>
                    <span>
                      "N/A"
                    </span>
                  </p>
                </div>
              }
            </Stack>

                                
                                <Heading className="margin-top-20">Order ID <span style={{color:'blue'}}> {orderDetails?.orderNo}</span></Heading>
                                <Flex>
                               
                                <div className="margin-bottom-10 margin-top-20 equal-flex-item">
                                <Heading bold>Pick-up location</Heading>
                                <p>{orderDetails?.pickup}</p>
                                </div>
                                <div className="margin-bottom-10 margin-top-20 equal-flex-item">
                                <Heading bold>Delivery fee</Heading>
                                <p>
                                  { orderDetails?.status === 10
                                    ? '₦' + orderDetails?.orderPrice?.actualValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                    : '₦' + orderDetails?.orderPrice?.minValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " - ₦" + orderDetails?.orderPrice?.maxValue?.toFixed(2)?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
                                </p>
                                </div>
                                </Flex>

                                {/* <Flex>
                                <div className="margin-bottom-10 margin-top-20 equal-flex-item">
                                <Heading bold>Recepient phone number</Heading>
                                <p>080120000000</p>
                                </div>

                                
                                </Flex> */}
                                <Flex>
                                {
                                  orderDetails?.destinationPoints?.map((destination, Key) => {
                                    return(
                                      <>
                                      
                                <div key={Key} className="margin-bottom-10 margin-top-20 equal-flex-item">
                                <Heading bold>Dropoff location {Key+1}</Heading>
                                <p>{destination?.destination}</p>
                                </div>

                                <div className="margin-bottom-10 margin-top-20 equal-flex-item">
                                <Heading bold>Item description</Heading>
                                <p style={{textTransform:'capitalize'}}>{destination?.description}</p>
                                </div>
                                
                                      </>
                                    )
                                  })
                                }
                                </Flex>
                                
                            <div>
            
            <br/>
          </div>
					

            </OrderOverview>
         
        </OrderSummaryContainer>
      </div>
    </>
  );
};

export default TrackOrder;
