import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Alert_ = (props) => {


    return (
        <>

        <Snackbar
            open={props.status}
            autoHideDuration={6000}
            onClose={props.status}
        >
            <Alert
            onClose={props.status}
            severity={props.color}
            sx={{ width: "100%" }}
            >
            {props.message}
            </Alert>
        </Snackbar>
        </>
    );
};

export default Alert_;
